import styled from "styled-components";
import { Container } from "../globalStyles";

export const CommercialFirstsectionContainer = styled(Container)`
display: flex;
flex-direction: row;
margin-top: 6.313rem;
margin-bottom: 3.063rem;
overflow: hidden;


@media screen and (max-width: 960px) {
    margin-top: 1.875rem;
    margin-bottom: 0rem;
}
${Container}
`;

export const CommercialFirstLeftContainer = styled.div`
width: 40%;
display: flex;
flex-direction: column;

@media only screen and (min-width: 140px) and (max-width: 1700px) {
margin-right: 10rem;
}
`;


export const CommercialFirstRightContainer = styled.div`
width: 60%;
display: flex;
flex-direction: column;
`;

export const ImgLeftCommercialScetion = styled.img`
border-radius: 3.125rem;
width: 39.125rem;
height: 50rem;

@media screen and (max-width: 960px) {
    width: 7.938rem;
    height: 11.25rem;
    border-radius: 1.25rem;
}

`

export const CommercialFirstSectionHeadText = styled.h4`
font-style: normal;
width: 41.375rem;
font-weight: 900;
font-size: 4.625rem;
line-height: 5.625rem;
margin-bottom: 1.563rem;
color: #000000;

@media screen and (max-width: 960px) {
    width: 7.5rem;
    border-radius: 1.25rem;
    font-size: 1.438rem;
    font-weight: 600;
    line-height: 1.563rem;
    width: 18.75rem;
    margin-bottom: .625rem;
}


`;

export const CommercialFirstsectionDesc = styled.p`
width: 42.688rem;
font-style: normal;
font-weight: 400;
font-size: 1.438rem;
line-height: 140%;
color: #000000;
margin-bottom: 5.188rem;

@media screen and (max-width: 960px) {
    font-size: .438rem;
    line-height: .938rem;
    margin-bottom: 1.875rem;
}

@media only screen and (min-width: 1400px) and (max-width: 1700px) {
    width: 42rem;
    font-size: 1.4rem;
}
`;

export const CommercialRightImage = styled.img`
width: 20.375rem;
height: 14.125rem;
border-radius: 3.125rem;

@media screen and (max-width: 960px) {
    display: none;
}
`;