import React , { useRef } from 'react';

const ScrollToTop = () => {

    const gotoTop = useRef(null)

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            left:0,
            behavior: 'smooth',
        });
    }
    
    window.addEventListener('scroll' , e =>{

        const scrollHeight = window.pageYOffset;
    
        if(scrollHeight > 300){
            gotoTop.current.classList.add('show-top')
        }else{
            gotoTop.current.classList.remove('show-top')
        }
    
    })


  return (
    <div ref={gotoTop} onClick={handleClick} className="goto-top scroll-link">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssvgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512"  xmlSpace="preserve" className=""><g><path d="M445.771 238.813 264.438 4.146C262.419 1.534 259.215.003 256.01 0c-3.211-.003-6.424 1.529-8.447 4.146L66.229 238.813a10.656 10.656 0 0 0-1.135 11.219 10.653 10.653 0 0 0 9.573 5.969h96v245.333a10.66 10.66 0 0 0 10.667 10.667h149.333a10.66 10.66 0 0 0 10.667-10.667V256h96c4.073 0 7.781-2.313 9.573-5.969 1.791-3.646 1.353-8-1.136-11.218z" fill="#ffffff" data-original="#000000"></path></g></svg>
    </div>
  );
}

export default ScrollToTop;
