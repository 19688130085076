import styled , { createGlobalStyle } from 'styled-components';
<style>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Lato:wght@700&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans&family=Varela+Round&display=swap');
</style>



const GlobalStyle = createGlobalStyle`

:root{
  --primaryColor: #f1f1f1;
  --black: #222;
  --black2: #555;
  --black3: #252525;
  --black4: #000;
  --black5: #212529;
  --orange: #eb0028;
  --white: #fff;
  --grey: #959595;
  --grey2: #666;
  --grey3: #ccc;
  --secondaryColor: #2b1f4d;
  --yellow: #B7A857;
  --green: #59b210;
  --blue: rgb(56, 10, 223);
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none !important;
}

body{
    width: 100%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}


.goto-top{
    position: fixed;
    right: 2%;
    bottom: 10%;
    padding: .8rem .7rem;
    border-radius: .8rem;
    background-color: var(--orange);
    visibility: hidden;
    cursor: pointer;
    transition: .3s;
    animation: bounce 2s ease-in-out infinite;
}

.active__tabs{
    border-bottom: 0.125rem solid #000 !important;
    padding: 0.813rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.938rem;
    cursor: pointer;
    margin-right: 5rem;
    background-color: #fff;
    color: #000000;
}

.otp__container{
    gap: 2;
    display: flex;
    flex: 1;
    margin-right: 0.313rem;
    align-items: center;
    justify-content: center;
    margin-left: 0.313rem;
    border-radius: 0.625rem;
    border: 0.063rem solid #DBDBDB !important;

}

.otp__container input{
    height: 3.125rem;
    width: 100%;
    outline: none;
}

.slider-btns{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1.26rem;
}

.slider-btn{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4.605rem;
    width: 100%;
    //margin-left: 1.8rem;
}

.slider-btns button{
    position: static !important;
    transform: translate(0 , 0);
    width: 1.125rem;
    height: 1.083rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #d9d9d9;
    margin-right: 1.508rem;
}

.slider-btn button{
    position: static !important;
    transform: translate(0 , 0);
    width: 1.368rem;
    height: 1.333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    margin-right: 1.508rem;
}

.slider-btns .glider-next img{
    transform: rotate(-180deg);
}

.slider-btns .glider-next{
    margin-top: 0.063rem;
}

.slider-btn .glider-next{
    margin-top: 0.075rem;
}

.slider-btn .glider-next img{
    transform: rotate(-180deg)
}

.brand__grid__conatiner{
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(20rem , 23.75rem));
    grid-column-gap: 3.75rem;
    grid-row-gap: 3.125rem;
    margin-bottom: 9.375rem;
    width: 100%;
    overflow: hidden;
}


.tab{
    padding: 0.813rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.938rem;
    cursor: pointer;
    margin-right: 5rem;
    background-color: #fff;
    color: #000000;
}


@media only screen and (max-width : 768px){
    .tab{
       overflow-x: auto;
    }
}

.show-top{
    visibility: visible;
    z-index: 1999;
}

@keyframes bounce{
    0%{
        transform: scale(.5);
    }

    50%{
        transform: scale(1.5);
    }

    0%{
        transform: scale(1);
    }
    
}

.goto-top svg{
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--black4);
}

.goto-top:hover{
    background-color: var(--black4);
}


@media only screen and (max-width : 768px){
    .goto-top{
        right: 5%;
        bottom: 5%;
    }
}

@media only screen and (max-width : 568px){

    .goto-top svg{
        width: .8rem;
        height: .8rem;
        fill: var(--black4);
    }

    .goto-top{
        right: 4%;
        bottom: 10%;
        padding: .5rem .4rem;
        border-radius: .5rem;
    }

}

#root{
    height: 100%;
}

.splide__pagination{
    display: none;
}

.splide__slide{
    border: none !important;
}

.react__wathsapp{
    font-style: normal;
    font-weight: 900;
    font-size: 1.375rem;
    line-height: 140%;
    font-family: 'Montserrat' !important;
    color: #000000;
    background: #fff;
    cursor: pointer;
}

.react__product__whatsapp{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.938rem;
    background-color: transparent !important;
    line-height: 1.125rem;
    text-transform: uppercase;
    color: #FFFFFF;
}

.brand__container{
    //cursor: pointer;
    //width: 320px;
    width: 100%;
    height: 16.25rem;
    box-sizing: border-box;
    background: #FFFFFF;
    border: .063rem solid #F1F1F1 !important;
    border-radius: 1.25rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    //margin: 0px 20px;
    flex-direction: column;
}

.glider-contain::-webkit-scrollbar{
    width: 0;
    height: 0;
}


.glider-contain{
    width: 100%;
    overflow: hidden;
}


.glider{
    width: 100%;
    overflow: hidden !important;
}

.gliders{
    width: 100%;
    overflow: hidden !important;
    margin-top: 3.125rem;
}

.react__wathsapps{
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    height: 100%;
    color: #000000;
    cursor: pointer;
    background-color: #fff !important;
}

@media only screen and (max-width: 960px){
    .react__wathsapp{
        font-size: .5rem;
        font-weight: 700;
        line-height:1.25rem;
        margin-bottom: 1.188rem;
    }

    .react__wathsapps{
        font-size: .5rem;
        font-weight: 700;
        line-height: 1.25rem;
    }
}
`;

export const breakpoints = {
    largeDesktop: '1200px',
    desktop: '992px',
    tablet: '768px',
    phone: '576px',
    smallPhone: '425px'
};

export const Container = styled.div`
z-index: 1;
width: 100%;
max-width: 112.5rem;
margin-right: auto;
margin-left:auto;
padding-left:5rem;
padding-right:5rem;

@media screen and (max-width: 991px) {
    padding-left:1.875rem;
    padding-right:1.875rem;
}
`;


export default GlobalStyle;