import React from 'react';
import { WishAddCartContainer, WishBottomDivider, WishDeleteBtnProduct, WishListContainer, WishListGlobalContainer, WishListLeftContainer, WishListRightContainer, WishProductImg, WishproductName, WishProductNameContainer, WishProductPrice, WishProgressContainer } from './ModalWishListAdd.element';
import { useDispatch , useSelector } from 'react-redux';
import { addToCart } from '../store/shopping-cart/cartSlice';
import { removeProduct } from '../store/wish-list/wishListSlice';
import { toast } from 'react-hot-toast';
import CircularIndeterminate from './CircularIndeterminate';

const ModalWishListAdd = ({product , db , firebase}) => {

  const close = require('../images/close.png')

  const [loading , setLoading] = React.useState(false);

  const dispatch = useDispatch()

  const cartItems = useSelector(state => state.cart.cartItems);

  const deleteSellerInWishList = async element => {await db.collection('Togo').doc('Products').collection('ProductList').doc(product.uid).update({[fieldName]: firebase.firestore.FieldValue.arrayRemove(element)})};
  
  const user = JSON.parse(localStorage.getItem('user'));

  const handleDeleteProductInWhishList = () => {

    setLoading(true)

    deleteSellerInWishList(user.uid)
                .then(() => {
                  dispatch(removeProduct(product.uid));
                  setLoading(false)
                  toast.success('Produit Retiré de votre favoris');
                })
                .catch(error => {
                  console.log(error);
                  setLoading(false)
                  toast.error("Une erreur c'est produite");
                })
    
  }

  const fieldName = 'userWishList';

  const insideCart = cartItems.some(p => p.uid === product.uid);

  const currencyFormatter = new Intl.NumberFormat('fr-FR');

  const handleAddToCart = () => {
        
      if(insideCart){
        toast.success('Déja dans le panier')
      }else{
        toast.success('Produit ajouté au panier!')
      }


      const {uid , name , productDesc , minPrice , maxPrice , img} = product;

      const image = img[0];

      const totalPrice = maxPrice;

      dispatch(addToCart({uid , name , productDesc , minPrice , maxPrice , image , totalPrice}));

      handleDeleteProductInWhishList();

  }

  return (
    <WishListGlobalContainer>
        <WishListContainer>
          <WishListLeftContainer>
                {!loading ? <WishDeleteBtnProduct onClick={handleDeleteProductInWhishList} src={close}/> : <WishProgressContainer><CircularIndeterminate size={25}/></WishProgressContainer>}
                <WishProductImg src={product.img[0]}/>
                <WishProductNameContainer>
                    <WishproductName>{product.name}</WishproductName>
                </WishProductNameContainer>
          </WishListLeftContainer>
          <WishListRightContainer>
              <WishProductPrice>{currencyFormatter.format(product.minPrice)} - {currencyFormatter.format(product.maxPrice)}</WishProductPrice>
              <WishAddCartContainer onClick={handleAddToCart}>Ajouter au panier</WishAddCartContainer>
          </WishListRightContainer>
        </WishListContainer>
        <WishBottomDivider/>
    </WishListGlobalContainer>
  );
}

export default ModalWishListAdd;
