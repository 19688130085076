import styled from "styled-components";
import { Container } from "../globalStyles";

export const TabContainer = styled(Container)`
width: 100%;
display: flex;
flex-direction: column;
overflow: hidden;

${Container}
`;

export const TabHeadTitle = styled.div`
width: 100%;
border-bottom: 1px solid #D9D9D9 !important;
display: flex;
flex-direction: row;
margin-bottom: 80px;

&::-webkit-scrollbar{
    width: 0;
}

@media screen and (max-width: 960px) {
    overflow-x: auto;
}
`;

export const BrandGridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit , minmax(320px , 1fr));
grid-column-gap: 30px;
grid-row-gap: 40px;
margin-bottom: 150px;
`;

export const TabTitleBtn = styled.button`
padding: 13px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
cursor: pointer;
margin-right: 80px;
background-color: #fff;
color: #000000;
`;

export const TabSearchBar = styled.div`
box-sizing: border-box;
height: 50px;
border: 1px solid #DBDBDB !important;
display: flex;
flex-direction: row;
width: 100%;
margin-bottom: 46px;
`;

export const TabSearchIcon = styled.img`
width: 20px;
height: 20px;
margin-left: 45px;
align-self: center;
`;

export const TabSearchInput = styled.input`
width: 100%;
height: 100%;
margin-left: 19px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
outline: none;
font-size: 14px;
line-height: 17px;
color: #000000;
`;