import React from 'react';
import CCMRightOrLeftSection from '../CCMRightOrLeftSection';
import ReactWhatsapp from 'react-whatsapp';
import { navRef } from '../NavbarFeed';
import { Helmet } from 'react-helmet';
import { CCMContainerWrapper, CCMHeadContainer, CCMHeadContentContainer, CCMHeadDesc, CCMHeadText, FirstCCMSection  , FirstSectionHeadtext , FirstSectionDesc, CCMImgOverLaySection, CCMImg, CCMTextBottomHead, CCMBox  , CCMButtonHelpFaqContainer , CCMImgHelpCenter, CCMContainer} from './CCM.element';
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const CCM = () => {

    const one  = require('../../images/one.jpeg');
    const two  = require('../../images/two.jpeg');
    const three  = require('../../images/three.png');
    const four  = require('../../images/four.png');
    const five  = require('../../images/five.jpeg');
    const ccm1  = require('../../images/ccm1.jpeg');
    const ccm2  = require('../../images/ccm2.jpeg');
    const ccm3  = require('../../images/ccm3.png');
    const ccm4  = require('../../images/ccm4.jpeg');
    const ccm5  = require('../../images/ccm5.jpeg');
    const ccm6  = require('../../images/ccm6.jpeg');
    const ccm7  = require('../../images/ccm7.png');
    const ccm8  = require('../../images/ccm8.jpeg');
    const img = require('../../images/help.png');

    const dispatch = useDispatch();

    //const itemClick = useSelector(state => state.navbarState.navBarItemClick)

    React.useEffect(() => {
        navRef.current.style.background = '#9F2EA1';
        window.scrollTo(0, 0);
        dispatch(changeNavBarItemClicked('ccm'));

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const ImgScetions = (window.innerWidth <= 960) ? (

        <CCMImgOverLaySection>
            
            <CCMBox>
                <CCMImg src={one} alt='logo' />
            </CCMBox>

            <CCMBox>
                <CCMImg src={two} alt='logo' />
            </CCMBox>

            <CCMBox>
                <CCMImg src={five} alt='logo' />
            </CCMBox>
            
        </CCMImgOverLaySection>

    ) : (
        <CCMImgOverLaySection>
            
            <CCMBox>
                <CCMImg src={one} alt='logo' />
            </CCMBox>

            <CCMBox>
                <CCMImg src={two} alt='logo' />
            </CCMBox>

            <CCMBox>
                <CCMImg src={three} alt='logo' />
            </CCMBox>

            <CCMBox>
                <CCMImg src={four} alt='logo' />
            </CCMBox>

            <CCMBox>
                <CCMImg src={five} alt='logo' />
            </CCMBox>
            
        </CCMImgOverLaySection>
    );

    /*const changeBackground = () => {

        if(window.innerWidth >= 960){
          if(window.scrollY >= 500){
    
            if(itemClick === 'ccm'){

                console.log(itemClick)
              navRef.current.style.background = "#fff";
            }
            
          }else{
    
            if(itemClick === 'ccm'){
                console.log(itemClick)
              navRef.current.style.background = "#9F2EA1";
            }
    
          }
        }
    
      }*/
    
        //window.addEventListener('scroll' , changeBackground)

        window.addEventListener('reseize' , ImgScetions)

  return (
    <>

        <Helmet>
            <meta charSet='utf-8'/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <title>Momo - Fonctionnement</title>
            <meta name='keywords' content='Comment fonctionne momo, Profitez d’une plus grande flexibilité pour vous offrir ce que vous aimez., Fractionner votre payement, Profitez d’une plus grande flexibilité pour vous offrir ce que vous aimez., 6 versement égaux , Choisissez les produits qui vous intéressent'/>
            <meta property="og:image" itemProp="image" content='../../images/logo.png'/>
            <meta property="og:site_name" content='Momo'/>
            <meta property="og:url" content='www.gotmomo.co/ccm/'/>
            <meta property="og:type" content='product'/>
            <meta property="og:title" content='Comment Momo fonctionne'/>
            <meta property="og:description" content='Profitez d’une plus grande flexibilité pour vous offrir ce que vous aimez.'/>
            <meta name='description' content='Profitez d’une plus grande flexibilité pour vous offrir ce que vous aimez.'/>
        </Helmet>

        <CCMContainer>

        <CCMHeadContainer>
            <CCMHeadContentContainer>
                <CCMHeadText>Comment Momo fonctionne </CCMHeadText>
                <CCMHeadDesc>Profitez d’une plus grande flexibilité pour vous offrir ce que vous aimez.</CCMHeadDesc>
            </CCMHeadContentContainer>
        </CCMHeadContainer>

        <CCMContainerWrapper>
            <FirstCCMSection>
                <FirstSectionHeadtext>Fractionner votre payement en 3 versement égaux</FirstSectionHeadtext>
                <FirstSectionDesc>Nous avons établi des partenariats avec les plus grandes marques pour vous offrir la possibilité de différer le paiement de vos achats jusqu'à 3 versements faciles à gérer.</FirstSectionDesc>
            </FirstCCMSection>
        </CCMContainerWrapper>

            {ImgScetions}

        <CCMRightOrLeftSection position={false} text1='Étape 1' text2='Parcourez votre Boutique préférée' text3='Parcourez votre magasin habituel pour trouver les produits qui vous intéressent.' image={ccm1}/>
a
        <CCMRightOrLeftSection position={true} text1='Étape 2' text2='Sélectionnez les produits qui vous intéressent' text3='Sélectionnez les produits qui vous intéressent en magasin' image={ccm2}/>

        <CCMRightOrLeftSection position={false} text1='Étape 3' text2="Vérifiez l'éligibilité des produits au financement" text3='Vérifiez si les produits sélectionnés peuvent faire l’objet d’un financement .' image={ccm3}/>

        <CCMRightOrLeftSection position={true} text1='Étape 4' text2='Découvrez les conditions de financement disponibles' text3='Choisissez les modalités de paiement qui vous conviennent.' image={ccm4}/>

        <CCMRightOrLeftSection position={false} text1='Étape 5' text2='Validez les conditions de financement' text3='Acceptez les termes et conditions.' image={ccm5}/>

        <CCMRightOrLeftSection position={true} text1='Étape 6' text2='Achetez les produits sélectionnés dans la boutique' text3='Finalisez votre achat.' image={ccm6}/>

        <CCMRightOrLeftSection position={false} text1='Étape 7' text2='Rentrez chez vous avec les produits et profitez-en' text3='Recevez ou emportez vos produits.' image={ccm7}/>

        <CCMRightOrLeftSection position={true} text1='Étape 8' text2='Effectuez les paiements à chaque échéance' text3='Payez selon le calendrier convenu.' image={ccm8}/>
       
        <CCMContainerWrapper>
        <CCMTextBottomHead>
        Avez-vous des questions ?
      </CCMTextBottomHead>

      <CCMButtonHelpFaqContainer>

      <ReactWhatsapp number='+22899853545' message=''    className='react__wathsapps'>
      Contactez notre centre d’aide
      </ReactWhatsapp>
      
      <CCMImgHelpCenter src={img} alt='logo'/>

      </CCMButtonHelpFaqContainer>
        </CCMContainerWrapper>

        </CCMContainer>

    </>
  );
}

export default CCM;
