import React from 'react';
import { CommercialFirstLeftContainer, CommercialFirstRightContainer, CommercialFirstsectionContainer, CommercialRightImage, ImgLeftCommercialScetion  , CommercialFirstsectionDesc  , CommercialFirstSectionHeadText} from './CommercialFirstSection.element';

const CommercialFirstSection = () => {

  const plm1 = require('../images/plm1.png');
  const plm2 = require('../images/plm2.jpeg');

  return (
    <CommercialFirstsectionContainer>

        <CommercialFirstLeftContainer>
            <ImgLeftCommercialScetion src={plm1} alt='logo' />
        </CommercialFirstLeftContainer>

        <CommercialFirstRightContainer>

            <CommercialFirstSectionHeadText>
             Propulsez votre entreprise avec<br/>momo
            </CommercialFirstSectionHeadText>

            <CommercialFirstsectionDesc>
            Momo offre à vos clients une façon plus intelligente de<br/> payer, sans frais cachés ni surprises.<br/><br/> 
Avec Momo, vous pouvez proposer des plans de paiement<br/>flexibles pour élargir votre clientèle et augmenter la valeur<br/>moyenne des commandes
            </CommercialFirstsectionDesc>

            <CommercialRightImage src={plm2} alt='logo'/>

        </CommercialFirstRightContainer>

    </CommercialFirstsectionContainer>
  );
}

export default CommercialFirstSection;
