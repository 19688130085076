import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    navBarItemClick: 'home',
}

export const navbarSlice = createSlice({
    name: 'navbarState',
    initialState: initialState,
    reducers: {

      changeNavBarItemClicked(state, action){ 
        state.navBarItemClick = action.payload;
      },

    },
  });
  
  export const { changeNavBarItemClicked } = navbarSlice.actions;
  export default navbarSlice;