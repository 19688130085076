import React  from 'react';
import { Box } from '@mui/system';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {NavbarFeed , HomePage, Catalogue, Connexion, Commercial , FAQ, FooterFeed, PrivatePolicy, TermesOfUse, CCM, ProductScreen, ProductDetail, Cart, WishList, CommercialRegister, Search } from './components';
import ScrollToTop from './components/ScrollToTop';
import GlobalStyle from './globalStyles';
import { navRef } from './components/NavbarFeed';
import { Helmet } from 'react-helmet';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import { FirestoreProvider } from 'react-firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAb3RkSfmakDtZlv40wW28lp_uKCVIp-VE",
  authDomain: "momo-b0763.firebaseapp.com",
  databaseURL: "https://momo-b0763-default-rtdb.firebaseio.com",
  projectId: "momo-b0763",
  storageBucket: "momo-b0763.appspot.com",
  messagingSenderId: "345544627527",
  appId: "1:345544627527:web:1f03e692f1dd8ebb486862",
  measurementId: "G-JLCSCV0KJ4"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

const App = () => {
  
  window.addEventListener('popstate', () => {

    const rev = window.location.pathname.substring(10);
    const idPath = '/produits/'+rev;
    

    if(window.location.pathname === '/marchand'){
      navRef.current.style.background = "#B7A857";
    }else if(window.location.pathname === '/catalogue'){
      navRef.current.style.background = "#57B793";
    }else if (window.location.pathname === '/ccm'){
      navRef.current.style.background = "#9F2EA1";
    }else if(window.location.pathname === idPath) {
      navRef.current.style.background = "#D9D9D9";
    }else{
      navRef.current.style.background = "#fff";
    }

  });

  return (

    <FirestoreProvider firebase={firebaseApp} firestore={db}>
           
           <BrowserRouter>

              <Box sx={{backgroundColor: '#fff'}}>

                    <Helmet>
                      <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains" />
                    </Helmet>  
    
                     <GlobalStyle/>
                     <NavbarFeed/>

                    <Routes>

                      <Route path='/' exact element={<HomePage/>}/>
                      <Route path='/marchand' exact element={<Commercial/>}/>
                      <Route path='/catalogue' exact element={<Catalogue/>}/>
                      <Route path='/faq' exact element={<FAQ/>}/>
                      <Route path='/privacy' exact element={<PrivatePolicy/>}/>
                      <Route path='/connexion' exact element={<Connexion db={db} firebase={firebase}/>}/>
                      <Route path='/privacy' exact element={<PrivatePolicy/>}/>
                      <Route path='/termes-et-conditions' exact element={<TermesOfUse/>}/>
                      <Route path='/ccm' exact element={<CCM/>}/>
                      <Route path='/catalogue/produits/:id' exact element={<ProductScreen db={db} firebase={firebase}/>}/>
                      <Route path='/catalogue/produit/:id' exact element={<ProductDetail/>}/>
                      <Route path='/catalogue/panier' exact element={<Cart/>}/>
                      <Route path='/catalogue/user/favoris/:userId' exact element={<WishList db={db} firebase={firebase}/>}/>
                      <Route path='/marchand/enregistrement' exact element={<CommercialRegister db={db} firebase={firebase} />}/>
                      <Route path='/catalogue/produits/recherche' exact element={<Search/>}/>

                    </Routes>

                    <ScrollToTop/>
                    <FooterFeed/>
                      
                  </Box>         

            </BrowserRouter>

    </FirestoreProvider>

  )

};

  


export default App;
