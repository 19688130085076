import styled from "styled-components";
import { Container } from '../globalStyles';

export const WriteContainer = styled(Container)`
width: 100%;
display: flex;
flex-direction: row;
margin-top: 174px;
margin-bottom: 140px;

@media screen and (max-width: 960px) {
    margin-top: 25px;
    overflow: hidden !important;
    margin-bottom: 25px;
}

${Container}
`;

export const WriteLeftContentContainer = styled.div`
width:50%;
height: auto;
margin-right: 27px;
display: flex;
flex-direction: column;
@media screen and (max-width: 960px) {
    margin-right: 7.5px;
    width:100%;
}
`;

export const WriteRightContentContainer = styled.div`
width:50%;
height: auto;
margin-left: 27px;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    margin-left: 7.5px;
    width:100%;
    margin-top: 35px;
    display: none;
}
`;

export const WriteRightImage = styled.img`
width: 626px;
overflow: hidden;
height: 800px;
background: rgba(217, 217, 217, 0.5);
border-radius: 50px;
object-fit: cover;

@media screen and (max-width: 960px) {
    width: 150px;
    overflow: hidden;
    height: 300px;
    border-radius: 15px;
    display: none;
}
`;

export const WriteHeadLeftText = styled.h4`
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
color: #000;
width: 662px;
margin-bottom: 40px;
@media screen and (max-width: 960px) {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 17px;
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
    font-style: normal;
font-weight: 900;
font-size: 65px;
line-height: 85px;
color: #000;
width: 662px;
margin-bottom: 40px;
}
`;

export const WriteHeadLeftDesc = styled.p`
font-style: normal;
font-weight: 500;
font-size: 33px;
line-height: 140%;
width: 683px;
color: #000000;
margin-bottom: 40px;

@media screen and (max-width: 960px) {
    font-size: .9rem;
    font-weight: 400;
    line-height: 20px;
    width: 300px;  
    margin-bottom: 17px; 
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
    font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 140%;
width: 600px;
color: #000000;
margin-bottom: 40px;
}
`;

export const WriteMoreInfoText = styled.p`
font-style: normal;
font-weight: 300;
font-size: 25px;
line-height: 140%;
width: 683px;
color: #000000;
margin-bottom: 43px;

@media screen and (max-width: 960px) {
    font-size: .7rem;
    font-weight: 400;
    line-height: 20px; 
    margin-bottom: 19px; 
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
    font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 140%;
width: 683px;
color: #000000;
margin-bottom: 43px;

}
`;

export const WriteInWhatsAppContainer = styled.div`
display: flex;
flex-direction: row;
cursor: pointer;
margin-bottom: 74px;

@media screen and (max-width: 960px) {
    margin-bottom: 0px; 
}
`;

export const WriteWhatsAppText = styled.p`
font-style: normal;
font-weight: 900;
font-size: 22px;
line-height: 140%;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: .5rem;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 19px; 
}
`;

export const IconGo = styled.img`
width: 24.22px;
height: 24.22px;
align-self: center;
margin-left: 30px;


@media screen and (max-width: 960px) {
    
    width: 10px;
    height: 10px;
    margin-left: 10px;
    align-self: start;
    margin-top: 5px;
}
`;

export const WriteLeftImage = styled.img`
width: 326px;
overflow: hidden;
height: 264px;
align-self: end;
background: rgba(217, 217, 217, 0.5);
border-radius: 50px;

@media screen and (max-width: 960px) {
    width: 150px;
    overflow: hidden;
    height: 120px;
    border-radius: 15px;
    align-self: center;
}
`;
