import  React from 'react';
import { Card, Cards, Img} from './Slider.elements';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Slider = () => {

  //const img = require('../images/rect.png');
  const img1 = require('../images/news1.png');
  const img2 = require('../images/news2.png');
  const img3 = require('../images/popup.png');
  const img4 = require('../images/testimonial.png');
  const img5 = require('../images/news5.png');
  //const img6 = require('../images/news6.png');
  
  let [width , setWidth] = React.useState(null);
  
  let [height , setHeight] = React.useState(null);

  React.useEffect(() => {
    if(window.innerWidth > 960){
      setWidth('1000px');
    }else{
      setWidth('300px');
    }

    if(window.innerWidth > 960){
      setHeight('500px');
    }else{
      setHeight('150px');
    }
  } , []);

  const dynamicCustumSlideSize = () => {

    if(window.innerWidth > 960){
      setWidth('1000px');
    }else{
      setWidth('300px');
    }

    if(window.innerWidth > 960){
      setHeight('500px');
    }else{
      setHeight('150px');
    }

  }

  window.addEventListener('resize' , dynamicCustumSlideSize);

  return (
      <div className='container'>
        <Cards className='cards'>

          <Splide aria-label="My Favorite Images" options={{
            fixedWidth: width,
            fixedHeight: height,
            perPage: 3,
            type: 'loop',
            focus: 'center',
            isNavigation: true,
            autoplay: true,
          }}>
              <SplideSlide>
                  <Card>
                    <Img src={img1} alt='logo' effect='blur'/>
                  </Card> 
              </SplideSlide>

              <SplideSlide>
                  <Card>
                    <Img src={img2} alt='logo' effect='blur'/>
                  </Card> 
              </SplideSlide>

              <SplideSlide>
                  <Card>
                    <Img src={img3}  alt='logo' effect='blur'/>
                  </Card> 
              </SplideSlide>

              <SplideSlide>
                  <Card>
                    <Img src={img4}  alt='logo' effect='blur'/>
                  </Card> 
              </SplideSlide>

              <SplideSlide>
                  <Card>
                    <Img src={img5} alt='logo' effect='blur'/>
                  </Card> 
              </SplideSlide>
             
             </Splide>
        </Cards>
      </div>
  );


}

export default Slider;