import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { Container } from '../globalStyles';
import Utility from '../utils/variable';


export const Nav = styled.nav`
background: ${() => {
    if(Utility.bg === 'ccm'){
        return ('#9F2EA1')
    }else if (Utility.bg === 'plm'){
        return ('#B7A857')
    }else if(Utility.bg === 'ct'){
        return ('#57B793')
    }else if(Utility.bg === 'pd'){
        return ('#D9D9D9')
    }else {
        return('#fff')
    }
}};
height: 5rem;
display: flex;
justify-content: center;
align-items: center;
font-size:1rem;
position: sticky;
top: 0;
z-index: 999;
`; 

export const NavDivWrapper = styled.div`
display: flex;
width: 183px;
margin-right: 33rem;
margin-left: 2.5rem;

@media only screen and (min-width: 1440px) and (max-width: 1700px) {
    margin-right: 15rem;
}

@media only screen and (min-width: 1347px) and (max-width: 1439px) {
    margin-right: 10rem;
}
@media only screen and (min-width: 1200px) and (max-width: 1347px) {
    margin-right: 2rem;
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    margin-right: 5rem;
}
`;

export const NavbarContainer = styled(Container)`
display: flex;
justify-content: space-between;
height: 80px;
overflow: hidden;

${Container}
`;

export const NavLogo = styled(Link)`
color: #000000;
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
font-size: 1.5rem;
width: 5rem;
display: flex;
margin-right: 4.5rem;
align-items: center;

@media only screen and (min-width: 992px) and (max-width: 1700px) {
    margin-right: 6rem;
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    width: 2rem;
}
`;

export const NavCCMtext = styled(Link)`
color: #000000;
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
font-size: 1.5rem;
height: 100%;
margin-top: .2rem;
display: flex;
align-items: center;

@media screen and (max-width: 960px) {
    display: none;
    margin: 0;
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    display: none;
    margin: 0;
}
` ;


export const Img = styled.img`
margin-right:0.5em;
height: 1.5rem;
vertical-align: middle;
border: 0;
`;

export const LogoText = styled.h3`
color: #000;
font-size: 1.8rem;
font-weight: 600;
font-family: 'Varela Round';
`;

export const MobileIcons = styled.div`
 display: none;

 @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top:0;
    right: 0;
    transform: translate(-100% , 60%);
    font-size: 1.8rem;
    cursor: pointer;
 }

 @media only screen and (min-width: 989px) and (max-width: 1200px) {
    display: block;
    position: absolute;
    top:0;
    right: 0;
    transform: translate(-100% , 60%);
    font-size: 1.8rem;
    cursor: pointer;
 }

`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;

@media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 100px); 
    position: absolute;
    overflow-y: auto;
    top: 80px;
    left: ${({click}) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease;
    background: #000;
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 100px); 
    position: absolute;
    overflow-y: auto;
    top: 80px;
    left: ${({click}) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease;
    background: #000;
}

`;

export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
margin-right: 20px;


@media screen and (max-width: 960px) {
    width: 100%;

    &:hover{
        border: none;
    }
}


@media only screen and (min-width: 989px) and (max-width: 1200px) {
    width: 100%;

    &:hover{
        border: none;
    }
}
`;

export const NavItems = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
display: none;


@media screen and (max-width: 960px) {
    width: 100%;
    display: flex;

    &:hover{
        border: none;
    }
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    width: 100%;
    display: flex;

    &:hover{
        border: none;
    }
}
`;

export const NavLinks = styled(Link)`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem  1rem;
height: 100%;
font-weight: ${({big}) => (!big ? '500' : '700')};;
font-size: 1rem;
font-family: 'Montserrat';
font-style: normal;
line-height: 20px;

&:hover{
    font-weight:700;
    transition: all 0.3s ease;
}

@media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }

}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }
}
`;

export const NavLinks2 = styled(Link)`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem  1rem;
height: 100%;
font-weight: ${({big}) => (!big ? '500' : '700')};;
font-size: 1rem;
font-family: 'Montserrat';
font-style: normal;
line-height: 20px;

&:hover{
    font-weight:700;
    transition: all 0.3s ease;
}

@media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }

}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }
}
`;

export const NavLinks1 = styled(Link)`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem  1rem;
height: 100%;
font-weight: ${({big}) => (!big ? '500' : '700')};;
font-size: 1rem;
font-family: 'Montserrat';
font-style: normal;
line-height: 20px;

&:hover{
    font-weight:700;
    transition: all 0.3s ease;
}

@media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }

}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }
}

`;

export const NavLinks3 = styled(Link)`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem  1rem;
height: 100%;
font-weight: ${({big}) => (!big ? '500' : '700')};
font-size: 1rem;
font-family: 'Montserrat';
font-style: normal;
line-height: 20px;

&:hover{
    font-weight:700;
    transition: all 0.3s ease;
}

@media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }

}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }
}
`;

export const NavLinks4 = styled(Link)`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem  1rem;
height: 100%;
font-weight: ${({big}) => (!big ? '500' : '700')};;
font-size: 1rem;
font-family: 'Montserrat';
font-style: normal;
line-height: 20px;

&:hover{
    font-weight:700;
    transition: all 0.3s ease;
}

@media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }

}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;

    &:hover{
        transition: all 0.3s ease;
    }
}
`;

export const NavText = styled.h4`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem  0;
font-weight: ${({big}) => (!big ? '500' : '700')};
font-size: 1rem;
font-family: 'Montserrat';
font-style: normal;
line-height: 20px;

&:hover{
    font-weight: 700;
}

`

export const Button  = styled.button`
border-radius: 30px;
border: 1px solid #000000 !important;
background: transparent;
color: #000;
font-family: 'Montserrat';
padding: 7px 16px;
font-size: ${({fontBig}) => (fontBig ? '1.25rem' : '1rem')};
outline: none;
cursor: pointer;

&:hover{
    transition: all 0.3 ease-in-out;
    background: #000;
    color: #fff;
}

@media screen and (max-width: 960px) {
    width: 100%;
    color: #000;
    background: #fff;
    border-radius: 10px;
    padding: 12px 64px;
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    width: 100%;
    color: #000;
    background: #fff;
    border-radius: 10px;
    padding: 12px 64px;
}
`;

export const NavItemBtn = styled.li`
@media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 120px;
}
`;


export const NavBtnLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
padding: .5rem 1rem;
height: 100%;
width: 100%;
border: none;
outline: none;
`;