import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../../globalStyles";

export const ProductScreenContainer = styled.div`
display: flex;
width: 100%;
height: 100%;
background: #D9D9D9 !important;
overflow: hidden;
`;

export const ProductScreenContentContainer = styled(Container)`
display: flex;
flex-direction: column;
margin-top: 60px;

${Container}
`;

export const ProductScreenContentContainerWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;

export const ProductHeadContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
margin-bottom: 35px;
align-items: center;
`;

export const ProductLoadingContainer = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
height: 20rem;
`;

export const ProductCategoryContainer = styled.div`
width: 75%;
display: flex;
flex-direction: row;
`;

export const ProductSearchCartFavorisContainer = styled.div`
width: 23%;
display: flex;
flex-direction: row;
`;

export const ProductSearchContainer = styled.form`
width: 270px;
box-sizing: border-box;
height: 50px;
display: flex;
flex-direction: row;
border: 1px solid #000000 !important;
border-radius: 50px !important;
align-items: center;
padding: 0px 20px;
margin-right: 50px;
`;

export const ProductSearchInput = styled.input`
font-style: normal;
font-weight: 400;
background: #D9D9D9 !important;
width: 90%;
font-size: 14px;
line-height: 17px;
outline: none;
color: #000000;
`;

export const ProductSearchIcon = styled.img`
width: 20px;
height: 20px;
align-self: center;
cursor: pointer;
`;

export const ProductsearchButton = styled.button`
width: 20px;
height: 100%;
cursor: pointer;
display: flex;
background-color: #D9D9D9;
`;

export const ProductGridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit , minmax(250px , 320px));
grid-column-gap: 25px;
grid-row-gap: 20px;
margin-bottom: 150px;
width: 100%;
overflow: hidden;
`;

export const FavorisIcon = styled.img`
width: 30px;
height: 30px;
`

export const FavorisLink = styled(Link)`
display: flex;
cursor: pointer;
margin-right: 50px;
align-self: center;
text-decoration: none;
`;

export const FavorisLinks = styled.div`
display: flex;
cursor: pointer;
margin-right: 50px;
align-self: center;
text-decoration: none;
`;

export const CartLink = styled(Link)`
display: flex;
align-self: center;
cursor: pointer;
text-decoration: none;
`;

export const Divider = styled.hr`
width: 100%;
background: rgba(0, 0, 0, 0.05);
height: 1px;
margin-bottom: 16.51px;
`;

export const BrandDataContainer = styled.p`
font-style: normal;
font-weight: ${({big}) => (!big ? '400' : '600')};
font-size: 15px;
line-height: 18px;
text-transform: capitalize;
min-width: 120px;
color: #000000;
cursor: pointer;
`;

export const TextHeadCategoryProduct = styled.p`
font-family: 'Montserrat';
font-style: normal;
font-weight: ${({big}) => (!big ? '400' : '600')};
font-size: 15px;
line-height: 18px;
cursor: pointer;
margin-right: 30px;
color: #000000;
`;

export const ProductRecommandeContainer = styled.div`
width: 320px;
height: auto;
margin-right: 30px;
`

export const BrandNameContainer = styled.div`
display: flex;
width: 100%;
overflow: hidden;
margin-bottom: 55px;
`;

export const RecommandedProducts = styled.h1`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #000000;
margin-bottom: 44px;
`

export const RecommandedProduct = styled.h1`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #000000;
margin-bottom: 50px;
`