import React from 'react';
import Glider from 'glider-js';
import { RecommandedProductContainer , RecommandedProducts  , ProductContainer  , ProductRecommandeContainer ,} from './ProductRecommanded.element';
import Product from './Product';

const ProductRecommanded = ({productFiltered , db , firebase , handleUpdateDeleteFavorisProductSate , handleAddProductInFavorisState , handleUpdateAddFavorisProductSate , handleDeleteFavorisInFavorisState , handleChangeState}) => {

    const prev = require('../images/prevs.png');
   
    

    React.useEffect(() => {
        new Glider(document.querySelector('.glider'), {
            slidesToScroll: 4,
            slidesToShow: 5,
            draggable: true,
            dots: '.dots',
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
              }
          }); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , []);
   

  return (
    <RecommandedProductContainer>
      
        <RecommandedProducts>Produits recommandés</RecommandedProducts>

        <div className='slider-btns'>
                <button aria-label="Previous" className="glider-prev"><img src={prev} width='18px' height='17.32px' alt=''/></button>
                <button aria-label="Next" className="glider-next"><img src={prev} width='18px' height='17.32px'  alt=''/></button>
        </div>    

        <ProductContainer>
                
                <div className="glider-contain">
                    <div className="glider">
                        {
                            productFiltered.map((item , index) => (
                                <ProductRecommandeContainer key={index}>
                                    <Product product={item} updateAddFavorisState={handleUpdateAddFavorisProductSate} updateDeleteFavorisState={handleUpdateDeleteFavorisProductSate} key={index} changeState={handleChangeState} db={db} firebase={firebase}  addFavoris={handleAddProductInFavorisState} deleteFavoris={handleDeleteFavorisInFavorisState}/>
                                </ProductRecommandeContainer>
                            ))
                        }
                    </div>
                </div>

        </ProductContainer>


    </RecommandedProductContainer>
  );
}

export default ProductRecommanded;
