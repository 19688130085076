import styled from "styled-components";
import { Container } from "../../globalStyles";

export const CCMHeadContainer = styled.div`
width: 100%;
height: 420px;
background: #9F2EA1;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    height: 180px;
}
`

export const CCMContainer = styled.div`

display: flex;
width: 100%;
flex-direction: column;
overflow: hidden;

`;

export const CCMHeadContentContainer = styled(Container)`
width: 100%;
height: 100%;
justify-content: center;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    justify-content: start;
    margin-top: 20px
}

${Container}
`;

export const CCMContainerWrapper = styled(Container)`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

@media screen and (max-width: 960px) {
    
}

${Container}
`

export const CCMHeadText = styled.h4`
width: 953px;
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
margin-bottom: 28px;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    width: 300px;
    color: #000;
    margin-bottom: 15px;
}
`;

export const CCMHeadDesc = styled.p`
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: 12px;
    line-height: 18px;
}
`;

export const FirstCCMSection = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin-top: 128px;
margin-bottom: 75.86px;

@media screen and (max-width: 960px) {
    margin-top: 45px;
    margin-bottom: 20px;
}
`;

export const FirstSectionHeadtext = styled.h4`
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
text-align: center;
color: #EDEDED;
align-self: center;
margin-bottom: 25px;
width: 1132px;

@media screen and (max-width: 960px) {
    font-size: 23px;
    //text-align: center;
    font-weight: 700;
    line-height: 30px;
    width: 350px;
    color: #EDEDED;
}
`;

export const FirstSectionDesc = styled.p`
font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 140%;
align-self: center;
text-align: center;
width: 720px;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: 12px;
    width: 305px;
}
`;

export const CCMImgOverLaySection =  styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items:center;
justify-content:center;
margin-top: 134.01px;
margin-bottom: 150px;

@media screen and (max-width: 960px) {
    margin-top: 30px;
    margin-bottom: 45px;
}
`;

export const CCMBox = styled.div`
width: 350px;
height: 550px;
margin-right: .49rem;
margin-left: .49rem;
transition: 1s;
border: none !important;

&:hover{
    transform: scale(1.4);
    z-index: 2;
}

@media screen and (max-width: 960px) {
    width: 180px;
    height:190px;
    margin-right: .2rem;
    margin-left: .2rem;
}
`;

export const CCMImg = styled.img`
width: 100%;
object-fit: cover;
height: 100%;
display: block;
border-radius: 25px;

@media screen and (max-width: 960px) {
    border-radius: 10px;
}
`;

export const CCMTextBottomHead = styled.p`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
width: 237px;
margin-top: 50px;
text-align: center;
align-self: center;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: 10px;
    margin-top: 20px;
}
`;

export const CCMButtonHelpFaqContainer = styled.div`
width: 376px;
height: 50px;
display: flex;
flex-direction: row;
margin-top: 50px;
border: 1px solid #000000 !important;
border-radius: 30px;
box-sizing: border-box;
cursor: pointer;
margin-bottom: 150px;
align-self: center;
align-items: center;
justify-content: center;

@media screen and (max-width: 960px) {
    margin-bottom: 50px;
    margin-top: 18px;
    width: 180px;
    height: 40px;
}
`;

export const CCMImgHelpCenter = styled.img`
width: 18px;
height: 18px;
margin-left: 20px;

@media screen and (max-width: 960px) {
    width: 10px;
    height: 10px;
    margin-left: 9px;
}
`;

