import React from 'react';
import { FooterContentContainer, FooterContentHeadContainer, FooterGlobalContainer, FooterContentHeadTextContainer, TextHeadOne, TextHeadTwo, FooterContentHeadCountryContainer, FooterContentHeadSelectCountryContainer, ImgFlagFooter, TextCountrySelect, ImgSelectFooter, FooterTextDesc, FooterBottomContainer, FooterBottomLeftContainer, TextBottomFooter, LinkFooter, TextBottomLinkFooter, FooterBottomRightContainer, TextBottomRightFooter, IconFb, IconIn, LinkItem, LinkMail, LinkCCM } from './FooterFeed.element';
import { navRef } from './NavbarFeed';

const link1 = '/privacy';
const link2 = '/termes-et-conditions';

const FooterFeed = () => {

  const flag = require('../images/flag.png');
  const icon = require('../images/more3.png');
  const fb = require('../images/fb.png');
  const instagram = require('../images/ins.png');
  const In = require('../images/linkedin.png');
  const tiktok = require('../images/tk.png');
  const text = `Options de paiement par l’intermédiaire de Momo Technologie ” Momo “. Le taux offert est basé sur la solvabilité et soumis à une vérification d’admissibilité. Tous les achats ne sont pas admissibles au taux  de 0 %. Les options de paiement dépendent du montant de votre achat, et peuvent varier selon le détaillant et peuvent ne pas être disponibles dans toutes les régions / territoires. Les conditions réelles de l’option de paiement seront indiquées au moment du paiement. Un acompte (ou un paiement dû aujourd’hui) minimum, fluctuant entre 35% et 40% du coût du produit, peut être exigé. Momo accepte les cartes de débit, les chèque bancaire et les virements bancaires comme formes de remboursement des options de paiement. Voici un exemple d’option de paiement : un achat de 150 000 FCFA sera réparti en 3 paiements mensuels de 32 500 FCFA à un taux d’intérêt de 7 % et un acompte de 35%, ou en 2 paiements sans intérêt de 48 750 FCFA toutes les 2 semaines. Pour plus d’informations, veuillez consulter.`;

 const email = 'support@gotmomo.co';

 const handleEmailLinkClick = (e) => {
  e.preventDefault();
  window.location.href = `mailto:${email}`;
};

const handleFbClick = () => {
  window.open('https://www.facebook.com/momoscaleup' , '_blank');
};

const handleInClick = () => {
  window.open('https://www.linkedin.com/company/momoscaleup/' , '_blank');
};

const handleInstagramClick = () => {
  window.open('https://www.instagram.com/momoscaleup/' , '_blank');
};

const handleTikTokClick = () => {
  window.open('https://www.tiktok.com/@momoscaleup/' , '_blank');
};

  return (
    <FooterGlobalContainer>
        <FooterContentContainer>
            <FooterContentHeadContainer>
              <FooterContentHeadTextContainer>
                  <TextHeadOne>Get what you can't.</TextHeadOne>
                  <TextHeadTwo>Services d'achat et autres</TextHeadTwo>
                  <LinkMail href='#' onClick={handleEmailLinkClick}>
                    <TextHeadTwo>hello@gotmomo.co</TextHeadTwo>
                  </LinkMail>
              </FooterContentHeadTextContainer>
              <FooterContentHeadCountryContainer>
                <FooterContentHeadSelectCountryContainer>
                    <ImgFlagFooter src={flag} alt='logo'/>
                    <TextCountrySelect>Togo</TextCountrySelect>
                    <ImgSelectFooter src={icon} alt='logo' />
                </FooterContentHeadSelectCountryContainer>
              </FooterContentHeadCountryContainer>
            </FooterContentHeadContainer>
            <FooterTextDesc>
              {text}
              <LinkCCM to='/ccm'> Comment ça marche</LinkCCM>.
            </FooterTextDesc>
            <FooterBottomContainer>
               <FooterBottomLeftContainer>
                  <TextBottomFooter>© 2023 momo. Tous les droits sont réservés</TextBottomFooter>

                  <LinkItem onClick={() => navRef.current.style.background = "#fff"}>
                      <LinkFooter to={link1}>
                        <TextBottomLinkFooter>Politique de confidentialité</TextBottomLinkFooter>
                     </LinkFooter>
                  </LinkItem>

                  <LinkItem onClick={() => navRef.current.style.background = "#fff"}>
                    <LinkFooter to={link2}>
                        <TextBottomLinkFooter>Termes et conditions</TextBottomLinkFooter>
                    </LinkFooter>
                  </LinkItem>
                  
               </FooterBottomLeftContainer>
               <FooterBottomRightContainer>
                  <TextBottomRightFooter>Suivez nous sur</TextBottomRightFooter>
                  <IconFb src={tiktok} alt='logo' onClick={handleTikTokClick}/>
                  <IconFb src={fb} alt='logo' onClick={handleFbClick}/>
                  <IconFb src={instagram} alt='logo' onClick={handleInstagramClick}/>
                  <IconIn src={In} alt='logo' onClick={handleInClick}/>
               </FooterBottomRightContainer>
            </FooterBottomContainer>
        </FooterContentContainer>
    </FooterGlobalContainer>
  );
}

export default FooterFeed;
