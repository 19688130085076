import React from 'react';
import { TermesContainer, TermesDivider, TermesHeadText, TermesText, TermesUpdateContent } from './TermesOfUse.element';
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const TermesOfUse = () => {

  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeNavBarItemClicked('termes'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TermesContainer>
      <TermesHeadText>Termes et Conditions d’utilisation</TermesHeadText>
      <TermesDivider/>
      <TermesUpdateContent>Mis à jour le : 24 Février 2023</TermesUpdateContent>
      <TermesText>1.1. Les présentes Conditions générales constituent un accord juridiquement contraignant entre vous (personnellement ou au nom d'une entité que vous représentez) et Momo, ci-après dénommée " Nous ", en ce qui concerne tous les aspects des Services de Momo et / ou votre utilisation de la Plateforme ou votre accès à notre site Web https://gotmomo.co, ainsi que toute autre forme de média, canal de média, site Web ou application mobile liée à, ou liée à et par votre accès à et votre utilisation de la Plateforme. Vous reconnaissez avoir lu, compris et accepté d'être lié par les présentes conditions générales, telles que modifiées de temps à autre. si vous n'acceptez pas ces conditions générales, vous devez cesser immédiatement d'utiliser ou d'accéder à la plate-forme et si vous utilisez la plate-forme pour le compte d'un tiers, y compris, sans limitation, toute entité commerciale, vous garantissez que vous êtes autorisé et avez le pouvoir d'engager le tiers à ces conditions générales.<br/><br/>

1.2. Le commerçant auprès duquel vous achetez des biens ou des services en utilisant les services de paiement Momo n'est pas partie au présent contrat, et toutes les conditions applicables entre vous et le commerçant sont indépendantes des conditions du présent contrat.<br/><br/>

1.3. Nous nous réservons le droit, à notre entière discrétion, d'apporter des changements ou des modifications aux présentes Conditions générales à tout moment et pour toute raison.<br/><br/>

1.4. Vous reconnaissez qu'il est de votre responsabilité de consulter périodiquement ces conditions pour connaître les derniers développements, et vous renoncez à tout droit de recevoir une notification spécifique de chacune de ces modifications. Votre utilisation continue du site et de la plate-forme après la date de publication des conditions révisées constitue une reconnaissance de votre part que vous avez lu et compris les modifications et accepté l'engagement.<br/><br/>

1.5. Le présent préambule fait partie intégrante de l'accord.<br/><br/>

2- Définitions<br/>
Les références, mots, expressions et termes suivants ont la signification indiquée en face de chacun d'eux dans les présentes conditions générales :<br/><br/>

2.1. Références à "vous" : Les références à " vous ", ou au pronom possessif " votre " ou à d'autres pronoms similaires dans le présent Contrat sont des références au Client concerné mentionné dans les informations d'identification (telles que définies ci-dessous) à fournir avant l'utilisation des Services de paiement de Momo (tels que définis ci-dessous).<br/><br/>

2.2. Références à " nous " : Les références à " nous " ou " nous " au possessif, à l'objet ou à d'autres pronoms similaires dans le présent contrat sont des références à Momo et à ses entités et personnes affiliées.<br/><br/>

2.3. Site web : le site web de Momo https://gotmomo.co.<br/><br/>

2.4. Client : le client du " commerçant ou de la plateforme commerciale " qui crée un compte Momo.<br/><br/>

2.5. Commerçant : désigne toute entité commerciale qui fournit des services de paiement de Momo à ses clients en vertu d'un accord entre elle et Momo, ou entre Momo et la plateforme marchande.<br/><br/>

2.6. Plate-forme du commerçant : signifie les moyens, y compris, mais sans s'y limiter, les sites web et les applications, qui permettent à plusieurs commerçants de proposer des biens et des services aux clients par le biais d'une plate-forme unique.<br/><br/>

2.7. Services : désigne les services fournis par la société Momo.<br/><br/>

2.8. Bon de commande : désigne la demande soumise par le client par l'intermédiaire de la plate-forme, du site web du commerçant, ou de toute autre application ou point de vente pour utiliser le service de paiement Momo afin d'acheter des biens ou des services au commerçant.<br/><br/>

2.9. Service de paiement Momo : Le service que le client sélectionne chez le commerçant ou sur le site web de la plateforme du commerçant ou à tout autre point de vente chez le commerçant ou sur la plateforme du commerçant en vertu duquel le client choisit d'acheter des biens ou des services auprès du commerçant ou de la plateforme du commerçant en utilisant les services Momo soumis au présent contrat.<br/><br/>

2.10. Compte Momo : désigne le compte créé par le Client pour utiliser les Services de paiement Momo, dont l'utilisation et le fonctionnement seront soumis aux conditions du présent Contrat.<br/><br/>

2.11. Processus de paiement : Il s'agit du processus par lequel le Client passe lorsqu'il choisit d'utiliser les services de paiement disponibles chez Momo dans le cadre d'un ordre d'achat spécifique.<br/><br/>

2.12. Remboursement des paiements : Le montant payé par le Client au commerçant par Momo dans le cadre d'une transaction approuvée et ensuite restitué au Client suite au litige.<br/><br/>

2.13. Litige : Toute demande de remboursement, déduction, compensation, demande reconventionnelle ou contestation de quelque nature que ce soit par le Client à l'encontre du commerçant concernant les biens ou services ayant donné lieu à une transaction approuvée.<br/><br/>

2.14. Informations d'identification : les informations que le client doit fournir à Momo afin de créer un compte pour lui auprès de Momo, telles qu'elles sont collectées par Momo ou le commerçant ou la plateforme du commerçant aux fins de la fourniture des services de paiement Momo aux clients.<br/><br/>

2.15. Informations sur les transactions : Désigne toutes les informations relatives au bon de commande.<br/><br/>

2.16. Montant net : désigne le montant total à payer par le Client [y compris la taxe sur la valeur ajoutée et les frais d'expédition] dans le cadre d'un ordre d'achat qui donne lieu à une transaction approuvée, moins :<br/><br/>

(a) Tout retour.<br/>
(b) Les déductions, crédits ou réductions réclamés par le Client et approuvés par le commerçant concerné.<br/>

2.17. Force Majeure : Il s'agit de tout événement fatal, ou événement soudain qui ne peut être prévu et impossible à prévenir, y compris, en général, mais sans s'y limiter, les incendies, les inondations, les tempêtes, les ouragans, les tornades, les activités volcaniques et les tremblements de terre, ainsi que toute action, tout ordre ou toute demande émis par une autorité souveraine, un gouvernement et/ou toute personne censée travailler pour cet organisme. Ainsi que la guerre, qu'elle soit déclarée ou non, et les comportements similaires à la guerre, les révolutions, les émeutes, la désobéissance civile et les désordres civils, ainsi que les grèves, les arrêts de travail et/ou les situations similaires, et toute autre cause similaire indépendante de la volonté ou du contrôle de l'une des parties, sauf si son exécution est la mise en œuvre d'une garantie faisant l'objet d'une renonciation expresse à l'exonération en cas d'événement de force majeure.<br/><br/>

2.18. Lois applicables : Elles désignent la réglementation en vigueur au Togo qui s'applique au contrat.<br/><br/>

3- Eligibilité et validité :<br/>
3.1. Votre utilisation des services de paiement Momo constitue une reconnaissance de votre capacité légale et juridique à conclure le présent contrat conformément aux présentes conditions générales et une déclaration de votre part selon laquelle vous avez plus de dix-huit (18) ans.<br/><br/>

3.2 Si vous vous inscrivez en tant qu'entité commerciale, personne morale ou toute autre entité, il s'agit d'une reconnaissance de votre part que vous avez l'autorité et la capacité nécessaires pour conclure le présent contrat et lier votre entité aux présentes conditions générales, et que vous, ainsi que l'entité commerciale, vous conformerez à toutes les lois et exigences applicables - et auxquelles vous êtes soumis - pour le processus d'utilisation du site web et l'utilisation des services de paiement de Momo.<br/><br/>

3.3. En cliquant pour accepter les termes de cet accord, vous reconnaissez que vous avez conclu cet accord par voie électronique conformément à la réglementation en vigueur  au Togo.<br/><br/>

4- Comptes et enregistrement :<br/>
4.1. Le processus d'enregistrement du compte et de conclusion de l'accord exige que vous fournissiez à Momo certaines informations personnelles aux fins de la connaissance de votre client applicable dans le pays, y compris, en général, mais sans s'y limiter, votre nom complet, votre adresse, votre adresse électronique, votre numéro de téléphone et votre âge, vous reconnaissez et acceptez que les informations vraies, exactes, complètes et même à jour vous concernant sur le compte, et informez rapidement Momo de toute modification de vos informations personnellement identifiables ou de toute autre information ou circonstance qui pourrait affecter votre admissibilité à continuer à utiliser les services de paiement de Momo ou qui est autrement requise conformément aux termes du présent accord.<br/><br/>

4.2. Vous acceptez par les présentes de divulguer toutes vos obligations financières et de vous abstenir de retenir toute information qui pourrait avoir un impact ou être importante pour l'une des parties de l'accord de financement.<br/><br/>

4.3 Vous acceptez par les présentes d'être responsable de toutes les activités effectuées sur votre compte et acceptez de maintenir et de sécuriser en permanence le nom d'utilisateur et le mot de passe de votre compte, à moins que vous n'exprimiez le contraire par écrit.<br/><br/>

4.4. Si votre mot de passe est perdu ou volé, ou si vous pensez qu'il y a un accès non autorisé à votre compte Momo, vous devez changer le mot de passe de votre compte Momo et nous en informer immédiatement. Le client est responsable de toutes les transactions effectuées à l'aide de son compte volé, à moins qu'il ne prenne rapidement l'initiative en nous informant de la suspension du compte et en recevant un message de Momo indiquant que le compte est fermé.<br/><br/>

4.5. Puisqu'il n'est pas permis de transférer ou de céder des comptes d'utilisateur, vous reconnaissez et acceptez donc de restituer à Momo la valeur de toute transaction utilisant votre compte, qui n'est pas valide, autorisée ou processus applicable selon la loi, et qui est survenue par vous ou par toute personne qui a accès au site en ligne, aux services, ou autre, par l'utilisation du nom d'utilisateur ou du mot de passe que vous avez spécifié, et même si vous avez autorisé ou non cet accès.<br/><br/>

4.6. Si Momo a (et à son entière discrétion) des doutes sur le fait que l'une des informations qu'il a fournies est incorrecte, inexacte, incomplète ou non actualisée, sans préjudice des autres droits et recours garantis par Momo, conformément aux présentes conditions générales ou en vertu des lois applicables, nous avons donc le droit d'arrêter nos services et d'interrompre votre accès au site en ligne ou à ses services, ou de limiter ou d'écourter cet accès.<br/><br/><br/><br/>


4.7. Momo peut (à son entière discrétion et même à tout moment) effectuer toutes les enquêtes qu'elle juge nécessaires (que ce soit directement ou par l'intermédiaire d'un tiers), ainsi que vous demander de fournir une réponse et des informations ou documents supplémentaires, et ce sans limiter ce qui a été dit précédemment. Si vous êtes un employeur ou si vous vous inscrivez au nom d'un employeur, ces informations ou documents peuvent inclure une licence d'exploitation et d'autres documents officiels de l'entreprise et/ou des documents qui démontrent l'autorité de toute personne à agir en votre nom. Vous reconnaissez et acceptez de fournir toute information et/ou document à Momo sur demande. Vous reconnaissez et acceptez en outre que si vous n'êtes pas en mesure de le faire, Momo peut, sans aucune responsabilité, limiter, suspendre ou retirer votre accès au site Web. Nous nous réservons également le droit d'annuler les comptes non confirmés ou non vérifiés ou les comptes qui ne sont pas actifs depuis longtemps.<br/><br/>

4.8. En achevant le processus d'enregistrement de votre côté, vous reconnaissez et acceptez avoir lu les présentes conditions générales, les comprendre et accepter d'être lié par elles, ainsi que par la politique de confidentialité et tout ce qui peut être modifié de temps à autre, étant donné que la politique et ses modifications - et en vertu du présent accord - sont considérées comme faisant partie intégrante des présentes conditions générales, mais y sont incluses.<br/><br/>

5- Les services<br/>
5.1 Momo est un marché conçu pour être un lien en tant que centre commercial complet pour de nombreux grands magasins en passant des contrats avec les propriétaires de ces sites ou magasins. Notre objectif est de fournir un guide pour les principaux magasins et de donner au client l'avantage de la possibilité d'acheter maintenant et de payer plus tard avec la possibilité de différer les paiements dans le temps 6 paiements faciles.<br/><br/>

5.2 Le site web Momo présente des produits fournis et détenus par des commerçants indépendants sur leurs propres plateformes qui ont accepté de mettre une liste de ces produits sur le site web. <br/><br/>

5.3 Des frais de service seront appliqués pour le paiement du mois prochain et le paiement en 3 pour certains commerçants. Le montant de ces frais sera clairement affiché lors du passage de la commande. Veuillez vérifier votre montant total avant de finaliser votre achat afin de vous assurer que vous êtes conscient de tous les frais.<br/><br/>

6- Correspondance et communication électronique<br/>
6.1. Lorsque vous créez un compte, vous reconnaissez et acceptez que vous communiquerez avec nous par voie électronique. Par conséquent, vous acceptez de recevoir des messages périodiques de notre part et l'équipe de Momo communiquera avec vous par e-mail ou pourra vous envoyer des informations par SMS, ainsi que la publication d'avis sur le site web dans le cadre de l'administration commerciale habituelle, qui est liée à votre utilisation des Services. Vous reconnaissez et acceptez que votre choix de ne pas appliquer lesdits moyens de communication est une question qui affectera votre utilisation des Services.<br/><br/>

6.2. Afin de nous permettre de communiquer avec vous par voie électronique, vous acceptez de nous informer immédiatement de toute modification de votre adresse électronique ou de toute autre information que vous nous fournissez. Nous vous contacterons en conséquence, y compris pour votre numéro de téléphone, en mettant à jour les informations d'identification et toute autre information relative à votre compte Momo dès que possible et si nécessaire.<br/><br/>

6.3 Vous reconnaissez et acceptez que tous les accords, avis, divulgations et autres correspondances que nous vous fournissons par voie électronique sont des correspondances qui satisfont à toute exigence légale selon laquelle ces correspondances doivent être soumises par écrit.<br/><br/>

6.4. Le site web Momo vous demandera également, au cours de la procédure d'inscription, d'accepter de vous envoyer des e-mails ou des notifications promotionnelles relatives au site web et à ses services, et vous acceptez de recevoir des SMS pour chaque numéro de téléphone que vous fournissez à Momo. Vous reconnaissez être l'utilisateur principal du numéro de téléphone que vous nous avez fourni, et vous reconnaissez être autorisé à recevoir un SMS sur le numéro de téléphone que vous nous avez fourni. Vous acceptez également de nous informer chaque fois que vous cessez d'utiliser un numéro de téléphone particulier et si, à tout moment, vous décidez ou exprimez que vous ne voulez pas recevoir d'e-mails promotionnels, vous pouvez choisir de ne pas les recevoir en cliquant sur le lien figurant au bas de tout message e-mail promotionnel.<br/><br/><br/><br/>


7- Utilisation du site web<br/>
7.1. Il est interdit au client d'utiliser le site web de Momo pour faire la publicité d'un produit pour lui-même ou dans la section des commentaires sur les produits (pour les produits à accès restreint) ou à tout autre endroit sur le site (tel que déterminé par notre choix absolu) tout affichage pour ce qui suit : toute publicité dans le but d'attirer ou toute publicité ou utilisation d'un langage vulgaire, d'obscénités, d'insultes, d'abus culturels ou religieux, tout contenu politique ou critique, ou tout matériel pouvant menacer l'intérêt public ou la sécurité nationale, ou causer une diffamation, une calomnie, un libelle, ou tout autre contenu pouvant être offensant ou obscène ou indécent.<br/><br/>

8- Listes de produits et disponibilité<br/>
8.1. Momo s'efforce toujours de vous offrir les meilleures expériences et même un niveau de service exceptionnel, car Momo fait tout son possible pour s'assurer que les produits du fournisseur sont disponibles. Toutefois, Momo n'assume aucune responsabilité dans le cas où des produits ne sont pas disponibles chez un commerçant et que ces produits sont affichés sur le site Web.<br/><br/>

9- Service après-vente des produits :
9.1. Les produits vendus sur ce site web qui ne portent pas la marque de Momo sont des produits pour lesquels les services sont fournis et pris en charge par les commerçants, leurs fabricants ou leurs agents locaux uniquement, ainsi que leurs fournisseurs, conformément aux conditions associées à chaque produit. Momo ne donne pas non plus de garantie de quelque nature que ce soit pour les produits vendus sur le site Web. Pour cette raison, veuillez contacter les commerçants lorsque vous avez besoin d'une assistance technique, d'un service clientèle ou d'un service après-vente concernant leurs produits.<br/><br/>

10- Les paiements :<br/>
10.1. Une fois que vous avez créé un compte avec Momo conformément au présent Accord, Momo vous accorde la possibilité de demander l'utilisation des Services de paiement Momo lorsque vous choisissez de payer des biens ou des services en utilisant l'option de paiement des Services de paiement Momo chez le commerçant concerné ou sur le site web de la plateforme marchande.<br/><br/>

10.2. Les Services de paiement Momo vous permettent d'acheter des biens ou des services auprès d'un commerçant ou d'une plateforme marchande avec un paiement à une date ultérieure sur la base des conditions de paiement spécifiées au cours du processus de paiement et ces conditions de paiement peuvent inclure des montants en retard comme spécifié dans le processus de paiement en concluant le présent accord. Vous convenez et reconnaissez que vous avez compris et accepté tous les frais associés à votre commande comme spécifié dans le processus de paiement, en ce qui concerne le montant en retard, vous vous engagez à le payer à Momo.<br/><br/>

10.3. Une fois que vous avez choisi le service de paiement de Momo au point de vente du commerçant pendant le processus de paiement, il vous sera demandé de choisir parmi un ensemble de conditions de paiement qui deviendront effectives et contraignantes entre vous et Momo en ce qui concerne cette commande. Momo propose différents modes de paiement sur le site web, notamment le paiement électronique par carte de crédit.<br/><br/>

10.4. Momo se réserve le droit d'apporter tout changement ou modification au mode de paiement ou à sa prestation.<br/><br/>

10.5. Momo fournit des services de paiement électronique par le biais de prestataires de services tiers, sachant que Momo ne stocke aucune donnée relative aux cartes de crédit. Momo ne porte aucune responsabilité pour toute erreur, tout dommage ou toute autre question similaire à laquelle le client pourrait être exposé suite à l'utilisation du mode de paiement électronique. Cette responsabilité incombe au prestataire de services tiers.<br/><br/>

10.6. Le client doit veiller à ce que la couverture financière du compte concerné soit suffisante, afin que les commandes puissent être couvertes, et doit respecter les limites de transaction par carte de crédit.<br/><br/>

10.7. En vertu du présent contrat, vous (le client) acceptez et autorisez Momo à prélever le montant sur votre compte bancaire (le compte client) renseigné à la date prévue pour le paiement. En cas de rejet de la procédure pour manque de fonds couvrant la prime, une tentative sera faite tous les deux jours (jour oui et jour non) jusqu'à ce que le montant total soit payé.<br/><br/>

10.8. Momo peut imposer des frais supplémentaires aux utilisateurs lorsqu'ils effectuent le processus de paiement par voie électronique.<br/><br/>

10.9. Momo peut (à son entière discrétion et même à tout moment) effectuer toutes les enquêtes qu'elle juge nécessaires (soit directement, soit par l'intermédiaire de tiers), et peut également vous demander de lui fournir plus d'informations ou de documents, y compris en général, mais sans s'y limiter, les informations ou documents nécessaires pour vérifier votre identité et/ou votre solvabilité financière. Vous reconnaissez et acceptez de fournir toute information et/ou document à Momo sur demande. Vous reconnaissez et acceptez également que si vous n'êtes pas en mesure de le faire, Momo peut, sans aucune responsabilité à son égard, annuler la transaction ou limiter ou suspendre votre accès au site web et/ou retirer votre adhésion au site web.<br/><br/>

10.10. Vous reconnaissez par les présentes votre capacité financière à remplir vos obligations financières envers Momo, vous acceptez également que Momo ait le droit de coopérer avec le commerçant dans le cas où vous ne remplissez pas vos obligations de paiement ou retardez le paiement ; une telle coopération comprend, sans s'y limiter, (la suspension de l'abonnement/du service, la saisie du produit/des marchandises) jusqu'à ce que les paiements soient effectués.<br/><br/>

10.11. Vous acceptez par la présente que vous comprenez et supportez les conséquences potentielles lorsque vous utilisez le service financier en violation des conditions convenues. Vous acceptez que Momo ait le droit (à sa discrétion absolue) d'informer le Bureau d'information sur crédit ("BIC") en cas de retard ou d'incapacité de paiement de votre part.<br/><br/>

10.12. Vous convenez par la présente que vous êtes le véritable bénéficiaire de cet accord et que vous n'utilisez pas les services de Momo pour un quelconque blanchiment d'argent.<br/><br/>

11- Remboursement des paiements :
11.1. Momo n'est pas responsable envers vous du remboursement des paiements et, bien que Momo puisse jouer un rôle pour faciliter les remboursements et le remboursement des paiements entre vous et le commerçant ou la plate-forme du commerçant à sa seule discrétion, ce rôle n'implique aucune responsabilité en rapport avec le remboursement des paiements.<br/><br/>

12- Confidentialité et vie privée :<br/>
12.1. (Momo) s'engage à appliquer toutes les méthodes professionnelles raisonnables pour préserver la confidentialité des informations et données de crédit en sa possession, et à ne les utiliser ou échanger entre elle et des personnes et parties liées que dans les limites et selon ce qui est stipulé dans le règlement sur les informations de crédit et ses règlements exécutifs.<br/><br/>

12.2 Vous acceptez par les présentes de fournir toute information, y compris des informations d'identification ou des documents raisonnablement requis par Momo pour vérifier votre identité en rapport avec votre compte Momo ou toute utilisation des services de paiement Momo, et vous nous autorisez à effectuer toute enquête que nous jugeons nécessaire pour vérifier votre identité. Momo se réserve le droit de fermer votre compte Momo ou de suspendre ou limiter votre accès aux services de paiement Momo si nous ne sommes pas en mesure d'obtenir ou de vérifier ces informations à notre satisfaction.<br/><br/>

12.3 Par la présente, vous consentez et autorisez Momo à utiliser tout ou partie de vos informations d'identification et de vos données personnelles collectées par l'intermédiaire des sites Web, plateformes ou applications mobiles de Momo ou des commerçants de temps à autre et qui peuvent être utilisées ou divulguées à d'autres personnes. Cela peut inclure le partage de vos informations d'identification et de toute autre information avec des tiers à des fins de vérification de crédit, de lutte contre le blanchiment d'argent ou d'autres contrôles similaires ou à toute autre fin que Momo juge nécessaire afin de permettre à Momo de vous fournir des services de paiement et de se conformer à toutes les lois et réglementations applicables.<br/><br/>

12.4. Par la présente, vous acceptez le présent contrat et lorsque vous accédez ou utilisez le site web de Momo, vous acceptez la politique de confidentialité, sachant que vos informations personnelles sont collectées et comprenant comment elles seront traitées.<br/><br/>

12.5. Si vous n'êtes pas d'accord avec nos politiques et pratiques, vous ne pourrez pas utiliser le site web de Momo et veuillez ne pas continuer à utiliser nos services.<br/><br/>

12.6. Les clients ont le droit de retirer leur consentement au traitement de leurs données personnelles à tout moment, sous réserve de restrictions contractuelles et légales et d'une notification écrite.<br/><br/>

12.7. Momo n'autorise pas le client (contractant avec Momo) à divulguer à des tiers les informations qu'il a reçues dans le cadre du présent contrat ou d'un contrat de service conclu avec Momo. Au contraire, l'accès à toute information liée à l'activité de Momo ne doit pas se faire de manière confidentielle, mais dans les limites qui peuvent affecter négativement l'activité de Momo, et le client sera responsable de l'indemnisation de Momo pour toute perte d'activité ou de réputation, si cela se produit à la suite de tout acte émanant du client.<br/><br/>

13- Limitation de la responsabilité :<br/>
13.1. Momo est une plateforme indépendante, et ne participe pas aux transactions entre clients, commerçants, vendeurs ou autres opérations connexes. Par conséquent, Momo n'est en aucun cas responsable de toute situation liée aux commerçants et n'est pas responsable de toute défaillance liée aux produits des commerçants ou au modèle de produit, à la marque, à la fiabilité, à l'adéquation, à l'originalité, à la disponibilité ou à la légalité.<br/><br/>

13.2. Les commerçants tiers sont considérés comme indépendants de cet accord et sont des prestataires de services indépendants qui ne font que contracter avec Momo, et ils ne peuvent pas être considérés comme des employés de Momo, ou de ses représentants ou agents. En outre, Momo n'exécute pas les tâches elle-même, mais gère plutôt le processus d'exécution des tâches par le biais de sa plate-forme électronique, en fournissant un processus de communication entre le client et le commerçant. Les commerçants sont entièrement responsables de toutes les transactions qui ont lieu entre eux et les clients ou d'autres transactions connexes, et en cas de litige entre l'un des participants à ces transactions, chacun des clients, utilisateurs et commerçants s'engage à dégager Momo de toute responsabilité découlant de ou liée à tout litige entre les parties de quelque manière que ce soit. L'engagement de libérer Momo (et ses agents et employés) de toute réclamation, demande, responsabilité et indemnité de toute sorte ou nature, connue ou inconnue, divulguée ou non divulguée, découlant de ou liée de quelque manière que ce soit à de tels litiges.<br/><br/>

13.3. En acceptant cet accord, vous reconnaissez votre connaissance et votre compréhension de la possibilité de risques découlant de marchands ou d'erreurs de leur part ou de fournisseurs de services tiers. L'utilisation des services est donc de la responsabilité du client et selon sa décision, et Momo ne porte aucune responsabilité à son égard.<br/><br/>

13.4. Momo n'est pas responsable envers vous ou envers toute autre personne des dommages directs, indirects, accessoires, spéciaux, pénaux, punitifs ou consécutifs qui peuvent survenir, même si Momo a été informée de la possibilité de tels dommages, et Momo n'est pas responsable des dommages, obligations ou pertes que vous subissez, découlant de votre utilisation des services ou de la confiance que vous leur accordez, ou résultant de votre incapacité à accéder ou à utiliser les services, ou de toute transaction ou relation survenue entre vous et tout commerçant, et même si Momo a indiqué la possibilité de la survenance de tels dommages. Momo n'est pas non plus responsable d'un retard ou d'un échec de la mise en œuvre, et ne sera pas non plus tenu responsable dans le cas où le commerçant ne disposerait pas d'un permis professionnel ou spécialisé pour opérer.<br/><br/>

13.5. Les services sont fournis tels qu'ils sont proposés et tels qu'ils sont disponibles, et Momo est dispensé et même exclu de toute déclaration et garantie, expresse, implicite ou légale, qui ne sont pas expressément énoncées dans les présentes conditions, y compris les garanties implicites concernant la capacité du vendeur et l'adéquation de ses services à un usage particulier et la non-violation de ceux-ci, Momo ne fait aucune déclaration et ne donne aucune garantie quant à la fiabilité, la qualité, la durabilité, la disponibilité ou le propriétaire d'origine des services ou des biens ou de tout service commandé par le biais de l'utilisation des services de Momo, et Momo ne garantit pas non plus que les services seront ininterrompus ou sans erreur. Dans toute la mesure autorisée par la loi applicable, vous reconnaissez et acceptez par les présentes que vous assumez toute responsabilité découlant de votre utilisation de l'un des services proposés par Momo.<br/><br/>

13.6. Les informations figurant sur le site web sont fournies à des fins générales uniquement. En ce qui concerne les informations fournies par Momo, nous nous efforçons de maintenir les informations à jour et correctes, mais nous ne faisons aucune déclaration ou garantie de quelque nature que ce soit, qu'elle soit orale ou implicite, en termes d'exhaustivité, d'exactitude, de fiabilité, d'adéquation ou de disponibilité du site web, des informations, des services ou des publicités connexes ou des graphiques contenus sur le site web à quelque fin que ce soit, et par conséquent, la confiance que vous accordez à ces informations est entièrement à vos risques et périls.<br/><br/>

13.7. Pour des raisons de sécurité et de performance, tous les comptes enregistrés sur Momo sont considérés comme étant constamment surveillés.<br/><br/>

13.8. Momo ne sera pas responsable de tout retard, interruption ou défaillance dans la fourniture des services, s'ils sont causés par un événement de force majeure ou un événement similaire indépendant de notre volonté ou de notre contrôle qui empêcherait ou entraverait la fourniture du service.<br/><br/>

13.9. Vous reconnaissez et acceptez par les présentes d'indemniser et de dégager Momo et l'un quelconque de ses affiliés, dirigeants, administrateurs, employés et agents de toute réclamation, responsabilité, dommage, perte, dépense ou frais, y compris, mais sans s'y limiter, les frais juridiques et comptables découlant de ou liés de quelque manière que ce soit à ce qui suit :<br/><br/>

(a) Votre accès, votre utilisation ou votre incapacité à utiliser le compte de Momo ou les services de paiement de Momo.<br/>
(b) Votre négligence ou votre mauvaise conduite.<br/>
(c) Votre violation de toute loi applicable.<br/>
(d) Votre manquement à fournir et maintenir des informations vraies, exactes, actuelles et complètes en rapport avec votre compte Momo.

13.10. Si vous n'êtes pas d'accord avec l'une de ces conditions générales dans le contrat, vous ne devez pas accéder au site et ne pas utiliser les services Momo.<br/>

14. Indemnisation :<br/>
14.1. Vous reconnaissez et acceptez d'indemniser Momo, ses entités affiliées, ses sponsors, ses partenaires, sa direction, sa responsabilité, ses employés et de leur rembourser toutes les pertes qu'ils subissent ainsi que la valeur des dommages, des responsabilités, des réclamations, des jugements, des règlements, des amendes, des coûts et des dépenses (y compris toutes les dépenses, les frais juridiques, les frais d'enquête et les honoraires d'avocat). Si Momo subit des dommages causés par vous ou s'ils découlent ou sont liés à votre violation de l'accord, des présentes conditions générales, de la politique de confidentialité ou de votre utilisation des services ou de celle d'un tiers (par vous).<br/><br/>

15- Notifications :<br/>
15.1. Toutes les notifications et autres communications dans le cadre du présent contrat doivent être faites par écrit, par correspondance à l'adresse électronique de Momo indiquée sur le site Web de Momo, dans le cas où vous souhaitez notifier ou communiquer autrement avec Momo, via l'adresse électronique ou le numéro de téléphone que vous avez fourni à Momo conformément aux conditions du présent contrat.<br/><br/>

15.2. Pour que nous puissions communiquer avec vous par voie électronique, vous acceptez de nous informer immédiatement de tout changement de votre adresse électronique ou d'autres informations que vous nous fournissez et nous vous contacterons en conséquence, y compris votre numéro de téléphone, en mettant à jour les informations d'identification et toute autre information relative à votre compte Momo dès que possible pratiquement et si nécessaire.<br/><br/>

16- Lois applicables et juridiction :<br/>
16.1. Les droits et obligations des parties stipulés dans les termes et conditions du présent Accord seront régis et interprétés conformément aux dispositions des lois du Togo.<br/><br/>

16.2 En acceptant le présent Accord, vous vous engagez à vous soumettre sans condition à la juridiction exclusive des tribunaux du Togo pour tout litige découlant de ou lié à l'Accord et à chacune de ces conditions générales, et vous reconnaissez et vous vous engagez également à renoncer à votre droit d'intenter toute action en justice ailleurs.<br/><br/>

17- Coopération dans le cadre d'enquêtes et de demandes de renseignements :<br/>
17.1. Vous acceptez par la présente de coopérer avec Momo dans toute la mesure permise par les lois et règlements applicables au Togo, y compris en acceptant de répondre par écrit à toute question ou de fournir des informations pour aider Momo à mener toute enquête ou investigation interne.<br/><br/>

17.2. En vertu du présent contrat, vous reconnaissez que vous acceptez que Momo divulgue des informations vous concernant et concernant votre compte susmentionné ou tout autre compte au représentant autorisé du Bureau d’information sur le crédit (BIC) par le biais de l'accord d'adhésion conclu et des règles commerciales approuvées pour l'échange d'informations et/ou à toute autre partie approuvée par la Banque Centrale des Etats de l’Afrique de l’Ouest (BCEAO).<br/><br/>

18- Indépendance et séparabilité des termes et conditions :<br/>
18.1. Si un terme ou une disposition du présent contrat est jugé inefficace, inapplicable ou invalide, alors seul ce terme ou cette disposition sera considéré comme invalide sans affecter de quelque manière que ce soit le reste des termes et conditions qui restent en vigueur et ont tous leurs effets.<br/><br/>

19- Validité et résiliation de l'accord :<br/>
19.1. Le présent accord entre en vigueur à la date à laquelle vous cliquez pour indiquer votre acceptation de ses termes et restera en vigueur et produira ses effets pour vos successeurs.<br/><br/>

19.2. Le présent accord peut être résilié à tout moment par l'une ou l'autre des parties pour une raison quelconque ou sans raison.<br/><br/>

19.3. Si Momo décide de résilier le présent accord, elle vous en informera en envoyant une notification à l'adresse électronique que vous avez fournie à Momo. Vous acceptez et reconnaissez par la présente que Momo a le droit absolu de résilier cet accord avec ou sans raison et n'a aucune obligation de vous notifier la raison de la résiliation de l'accord.<br/><br/>

19.4. Vous pouvez résilier le présent Accord en fermant votre compte Momo en suivant les instructions sur le site web de Momo, et pour éviter tout doute, vous resterez responsable de toutes les obligations relatives à votre compte Momo même après sa fermeture et votre résiliation du présent Accord.<br/><br/>

19..5 Vous pouvez demander la fermeture de votre compte Momo à tout moment en suivant les instructions sur le site web de Momo si vous ne devez aucun montant à Momo dans le cadre des Services de paiement Momo et vous restez responsable de toutes les obligations liées au compte Momo même après sa fermeture.<br/><br/>

20- L'intégralité de l'accord :<br/>
20.1. Le contrat et toutes ses conditions générales représentent l'intégralité de l'accord et de ce qui a été convenu et compris entre vous et Momo. Il inclut également toute mise à jour qui pourrait lui être apportée de temps à autre et ce qui est publié à son sujet sur notre site web. Cet accord annule et copie tout ce qui précède d'autres accords de toute nature.</TermesText>
    </TermesContainer>
  );
}

export default TermesOfUse;
