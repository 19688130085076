import styled from "styled-components";

export const RecommandedProductContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
overflow: hidden;
`

export const RecommandedProducts = styled.h1`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #000000;
margin-bottom: 44px;
`

export const ProductContainer = styled.div`
display: flex;
width: 100%;
overflow: hidden;
margin-bottom: 55px;
`;

export const ProductRecommandeContainer = styled.div`
width: 320px;
height: auto;
margin-right: 30px;
`;