import React from 'react';
import ReactWhatsapp from 'react-whatsapp';
import { } from './HomePaymentScetion.element';
import { IconGo, WriteContainer, WriteHeadLeftDesc, WriteHeadLeftText, WriteInWhatsAppContainer, WriteLeftContentContainer, WriteLeftImage, WriteMoreInfoText, WriteRightContentContainer, WriteRightImage} from './WriteWhatsappSection.element';

const WriteWhatsAppSection = () => {
   
    const icon = require('../images/icon2.png');
    const homeRight = require('../images/home-right2.png');
    const homeLeft = require('../images/home-left.png')

  return (
    <WriteContainer>
        
        <WriteLeftContentContainer>

            <WriteHeadLeftText>
            Qu'est-ce que tu attends?
            </WriteHeadLeftText>

            <WriteHeadLeftDesc>
            Nous ne vous laisserons jamais à court d'options de shopping. 
            </WriteHeadLeftDesc>

            <WriteMoreInfoText>
                Contactez nous pour plus d’information
            </WriteMoreInfoText>

            <WriteInWhatsAppContainer>

                <ReactWhatsapp number='+22899853545' message='' className='react__wathsapp'>
                    Ecrire via whatsApp
                </ReactWhatsapp>

                <IconGo src={icon} alt='logo' />

            </WriteInWhatsAppContainer>

            <WriteLeftImage src={homeLeft} alt='logo'/>

        </WriteLeftContentContainer>

        <WriteRightContentContainer>

            <WriteRightImage src={homeRight} alt='logo'/>

        </WriteRightContentContainer>

    </WriteContainer>
  );
}

export default WriteWhatsAppSection;
