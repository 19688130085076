import styled from "styled-components";
import { Container } from "../globalStyles";

export const PercentSectionContainer = styled(Container)`
display: flex;
flex-direction: row;
margin-top: 5.563rem;

@media screen and (max-width: 960px) {
    margin-top: 2.5rem;
    flex-direction: column;
}
`;

export const PercentContentContainer = styled.div`
width: 50%;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    font-size: 1.875rem;
    margin-bottom: 2.5rem;
    line-height: 1.875rem;
    width: 100%;
}
`;

export const PercentHeadText = styled.h4`
font-style: normal;
font-weight: 900;
font-size: 5rem;
line-height: 6.125rem;
width: 14.125rem;
text-align: center;
margin-bottom: 1.938rem;
color: #E9E9E9;

@media screen and (max-width: 960px) {
    font-size: 1.875rem;
    margin-bottom: 1.125rem;
    line-height: 1.875rem;
}
`;

export const PercentSecondHeadText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 1.188rem;
line-height: 1.438rem;
text-align: center;
color: #000000;
width: 18.125rem;
margin-bottom: .938rem;

@media screen and (max-width: 960px) {
    font-size: .75rem;
    margin-bottom: .625rem;
    line-height: 1.25rem;
    font-weight: 600;
    width: 11.25rem;
}
`;

export const PercentSecondHeadDesc= styled.p`
font-style: normal;
font-weight: 500;
font-size: 1.063rem;
line-height: normal;
text-align: center;
width: 18.125rem;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: .5rem;
    line-height: .938rem;
    width: 9.375rem;
}
`;