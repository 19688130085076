import React from 'react';
import { PrivacyContainer, PrivacyDivider, PrivacyHeadText, PrivacyText, PrivacyUpdateContent } from './PrivatePrivacy.element';
import { navRef } from '../NavbarFeed';
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const PrivatePolicy = () => {

  const dispatch = useDispatch();

  React.useEffect(() => {
    navRef.current.style.background = '#fff';
    window.scrollTo(0, 0);
    dispatch(changeNavBarItemClicked('private'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrivacyContainer>
      <PrivacyHeadText>Politique de Confidentialité</PrivacyHeadText>
      <PrivacyDivider/>
      <PrivacyUpdateContent>Mis à jour le : 24 Février 2023</PrivacyUpdateContent>
      <PrivacyText><b>Préambule</b><br/>
Bienvenue sur la page de la politique de confidentialité applicable à la société Momo.<br/><br/>

Momo s'engage à protéger votre vie privée et vos données personnelles. Nous allons donc vous informer de notre politique de confidentialité et des moyens de protéger vos données personnelles lorsque vous visitez notre site web, ainsi que de vos droits en matière de protection de la vie privée et de la manière de vous fournir une protection juridique. En utilisant nos produits, services, fonctionnalités et technologies, y compris l'application mobile Momo, vous acceptez cette politique de confidentialité.

Veuillez noter que cette politique de confidentialité ne s'applique pas aux informations fournies ou collectées par le biais de sites tiers gérés par d'autres sociétés ou organisations. Nous ne sommes pas responsables des procédures et des politiques de confidentialité de ces sites.<br/><br/>

Terminologies :<br/>
Base juridique :<br/><br/>

Intérêt légitime : Il s'agit de l'intérêt lié à notre activité et à sa gestion, grâce auquel nous pouvons vous fournir les meilleurs services, et même les meilleures expériences et les plus sûres pour vous, sachant que nous veillons à prendre en compte tout impact potentiel sur vous (qu'il soit positif ou négatif). Même pour atteindre l'équilibre requis, et prendre en compte vos droits avant d'initier le traitement de vos données personnelles pour atteindre nos intérêts légitimes. Aussi, nous n'utilisons pas vos données personnelles pour des activités dans lesquelles nos intérêts doivent être ignorés en raison de leur impact sur vous (sauf si nous avons votre consentement ou si nous avons une obligation légale ou si nous sommes autorisés par la loi à le faire).Vous pouvez nous contacter pour plus d'informations sur la façon dont nous évaluons nos intérêts légitimes à la lumière des effets potentiels sur vous lors de la réalisation de certaines activités.<br/><br/>

Exécution du contrat : Cela signifie traiter vos données si cela est nécessaire pour exécuter un contrat auquel vous êtes partie ou pour prendre des mesures à votre demande avant de conclure ce contrat.<br/><br/>

L'exécution d'une obligation légale : Cela signifie traiter vos données personnelles si cela est nécessaire pour remplir une obligation légale à laquelle nous sommes soumis.<br/><br/>

Tierces parties:<br/><br/>

Tiers au sein de l'institution : Il s'agit d'autres sociétés au sein de Momo qui agissent en tant qu'observateurs ou processeurs conjoints. Ces sociétés sont basées au Royaume d'Arabie saoudite et fournissent des services de technologie de l'information, de gestion des systèmes et de reporting.<br/><br/>

Tiers extérieurs à l'institution : Ils désignent les prestataires de services qui travaillent dans le domaine du traitement au sein de Momo et fournissent des services de technologie de l'information et d'administration de systèmes.<br/><br/>

Organismes consultatifs compétents : Ceux qui agissent en tant que processeurs de données ou contrôleurs conjoints, y compris les avocats, les banquiers, les contrôleurs de comptes et les fonctionnaires des assurances qui fournissent des services de conseil, bancaires, juridiques, d'assurance et de comptabilité.<br/><br/>

Agences gouvernementales et autres services et institutions : Ceux qui agissent en tant que processeurs de données ou contrôleurs conjoints au Royaume d'Arabie saoudite et demandent des rapports sur les activités de traitement dans certaines circonstances ou situations.<br/><br/>

1- Informations importantes et identification de notre identité.<br/>
1.2. L'objectif de la présente politique de confidentialité :<br/><br/>

La présente politique de confidentialité vise à vous fournir des informations sur la manière dont Momo collecte et traite vos données personnelles reçues par le biais de votre utilisation du site web lorsque vous inscrivez pour accéder à toute plateforme Momo. 
Il est important que vous lisiez cette politique de confidentialité ainsi que nos conditions générales, notamment lorsque nous collectons ou traitons vos données personnelles afin que vous puissiez être pleinement conscient de l'utilisation que nous faisons de vos données . Gardez à l'esprit que cette politique de confidentialité est un complément aux avis et autres politiques de confidentialité, et n'est pas destinée à les annuler ou à les invalider.<br/><br/>

1.3. Qui est responsable de vos données personnelles :<br/><br/>

Momo est considérée comme la partie responsable de vos données personnelles (désignée dans le présent document par Momo ou par les pronoms " nous ", " notre " ou " nos " dans la présente politique de confidentialité) et Momo est également l'autorité de surveillance responsable de ce site web.
Nous avons nommé un délégué à la protection des données, qui est chargé de donner suite aux demandes de renseignements relatives à la présente politique de confidentialité. Si vous avez des questions sur cette politique de confidentialité, y compris sur les pratiques commerciales, veuillez nous contacter.<br/><br/>

2- Les données que nous recueillons à votre sujet :<br/>
Les données personnelles ou les informations vous concernant désignent toute information sur une personne à partir de laquelle cette personne peut être identifiée.<br/><br/>

Nous collectons, utilisons, stockons et transférons également différents types de données personnelles vous concernant, de manière générale mais non limitative, les données suivantes :<br/><br/>

Données d'identité, notamment (prénom, second prénom, nom de famille, date de naissance, sexe, etc.)<br/>
Données de contact, notamment (adresse électronique et numéros de téléphone).<br/>
Les données de localisation géographique et les informations financières sur les comptes que vous fournissez, telles que les numéros de carte de crédit ou de débit.<br/>
Données techniques, y compris l'adresse de protocole Internet (IP), vos données d'accès au site web/à l'application, le type et la version du navigateur, les paramètres d'heure, les types de plug-in et de version du navigateur, le système et la plate-forme d'exploitation, ainsi que d'autres données technologiques sur les appareils que vous utilisez pour accéder au site web.<br/>
Données d'utilisation, y compris l'historique des appels et les informations sur la façon dont vous utilisez notre site web, nos produits et nos services.<br/><br/>

Nous collectons, utilisons et partageons également des données agrégées, telles que des données statistiques et démographiques à des fins diverses. Les données agrégées peuvent être générées à partir de vos données personnelles, mais elles ne sont pas considérées comme des données personnelles au sens de la loi, car ces données ne contiennent aucune information sur votre identité, que ce soit directement ou même indirectement. Directement, par exemple, nous pouvons collecter vos données d'utilisation pour calculer le pourcentage d'utilisateurs qui accèdent à une fonction spécifique du site web, cependant si nous collectons des données agrégées avec vos données personnelles, elles peuvent vous identifier directement ou indirectement, et pour cela nous traitons les données collectées comme des données personnelles, et leur utilisation est soumise aux dispositions de la présente politique de confidentialité.<br/><br/>

Nous ne recueillons pas de types ou de catégories particuliers de données personnelles vous concernant (y compris des détails sur la race, l'origine ethnique, la religion, les croyances philosophiques, les opinions politiques, des informations sur votre état de santé, des données génétiques ou biologiques vous concernant), et nous ne recueillons pas non plus d'informations personnelles vous concernant sur des jugements de condamnation pénale ou des actes criminels.<br/><br/>

3- Comment vos données personnelles sont collectées :<br/>
Nous utilisons différentes méthodes pour collecter des données auprès de vous ou à votre sujet, notamment les suivantes :<br/><br/>

3.1. Transactions directes:<br/>
Vous pouvez nous fournir vos données d'identité, vos données de contact et vos données financières en remplissant des formulaires de données ou en communiquant avec vous par courrier postal, téléphone, e-mail ou autre, y compris vos interactions avec ou l'utilisation des services Momo, votre contact avec notre équipe d'assistance aux clients.<br/><br/>

3.2. Transactions indirectes<br/>
Les tiers, tels que les collecteurs de données, votre banque ou les commerçants avec lesquels vous effectuez des transactions par le biais de nos sites web, utilisent des cookies, des balises web, des plugins d'applications mobiles et des technologies similaires.<br/><br/>

4- Défaut de fournir des données personnelles :<br/>
Si nous devons collecter des données personnelles conformément à la loi ou aux termes d'un contrat que nous avons conclu avec vous, et que vous n'avez pas fourni ces données lorsqu'elles vous ont été demandées, il se peut que nous ne soyons pas en mesure de mettre en œuvre le contrat conclu ou que nous travaillons à conclure avec vous (par exemple, la fourniture de services). Dans ce cas, nous pourrions être amenés à annuler le service dont vous bénéficiez par notre intermédiaire, mais nous vous informerons si l'annulation est effectivement due à cette question, et vous devrez nous informer de toute modification de vos données personnelles.<br/><br/>


5- Comment nous utilisons vos données personnelles :<br/>
Nous n'utiliserons vos données personnelles que lorsque la loi le permet et, en général, nous pouvons utiliser vos données personnelles pour :<br/><br/>

1.Fournir des services Momo et une assistance à la clientèle.<br/>
2.Traiter les transactions et envoyer des notifications concernant vos transactions.<br/>
3.Résoudre les litiges et collecter les paiements et les frais.<br/>
4.Vérifier l'exactitude et l'authentification de vos informations.<br/>
5.Détecter, enquêter et prévenir les activités potentiellement interdites ou illégales, telles que la fraude.<br/>
6.Communiquer sur les comptes ou les transactions et envoyer des informations sur les avantages et les améliorations.<br/>
7.Vous contacter, par exemple par téléphone, par message texte (SMS) ou par courriel.<br/>
8.Transférer les modifications apportées à nos politiques.<br/>
9.Personnaliser le contenu et les expériences, notamment en faisant des recommandations en fonction de vos préférences.<br/>
10.Envoyer des offres ou des promotions pour les services de Momo.<br/>
11.Diffuser des publicités, y compris des publicités basées sur votre utilisation des services de Momo.<br/>
12.Effectuer des analyses statistiques, démographiques et marketing des utilisateurs des services de Momo et de leurs habitudes d'achat.<br/>
13.Mener toute autre activité commerciale légitime qui n'est pas autrement interdite par la loi, lorsque cela est nécessaire à la réalisation de nos intérêts légitimes (ou des intérêts légitimes de tiers). Vos intérêts et vos droits fondamentaux ne prévalent pas sur ces intérêts, ne les annulent pas ou ne les remplacent pas lorsque nous devons remplir une obligation légale ou lorsque nous devons créer et gérer un compte pour vous, lorsque nous devons mener des enquêtes, à et lorsque nous avons besoin de contenu personnel, d'expériences d'utilisateurs ou d'informations commerciales, ou lorsque nous devons répondre à des exigences de contrôle interne.<br/><br/>

En général, nous ne nous appuyons pas sur votre consentement ou ne le considérons pas comme une base juridique pour traiter vos données personnelles, sauf en ce qui concerne le fait que des tiers vous envoient des messages de marketing direct via votre courrier électronique ou vos SMS. Vous aurez le droit de retirer votre consentement auxdits actes de marketing à tout moment, en communiquant avec nous à cet égard.<br/><br/><br/><br/>


6- Que faisons-nous si les informations sont incorrectes ?<br/>
Nous effectuons des contrôles sur les informations que vous nous fournissez afin de repérer toute erreur ou omission et vous avez le droit de demander ce qui suit :<br/><br/>

Corriger les données personnelles qui vous sont associées et que vous considérez comme inexactes.<br/>
Finir ou compléter les informations manquantes, y compris en soumettant toute déclaration complémentaire ou corrective, connue sous le nom de droit de rectification. Si vous n'exercez pas votre droit de correction, nous prendrons des mesures pour examiner les informations et les corriger si nécessaire.<br/><br/>

7- Informations personnelles des personnes âgées de moins de 18 ans :<br/>
Nous n'avons pas l'intention de demander pour collecter des informations personnelles à toute personne âgée de moins de 18 ans. Si Momo soupçonne qu'un utilisateur est âgé de moins de 18 ans, nous demanderons à l'utilisateur de fermer son compte et nous ne l'autoriserons pas à continuer à utiliser les services de Momo. Veuillez nous informer si vous savez que des personnes âgées de moins de 18 ans utilisent les services de Momo afin que nous puissions prendre les mesures appropriées.

8- Les raisons pour lesquelles nous utilisons vos données personnelles :<br/>
Nous traitons vos données personnelles dans la mesure où elles sont nécessaires à l'exécution d'un contrat auquel vous êtes partie ou pour prendre des mesures à votre demande avant de conclure un contrat ou un accord.<br/><br/>

8.1. À cet égard, nous pouvons partager ou transférer vos données personnelles aux entités suivantes :<br/><br/>

Des tiers indépendants, que nous désignons pour nous aider à vous fournir des services, à condition que votre approbation soit délivrée à cet égard.<br/>
Nos consultants spécialisés, s'il nous est nécessaire d'obtenir des conseils ou une assistance de leur part, notamment des avocats, des comptables, des spécialistes des technologies de l'information et des consultants en relations publiques.<br/>
Nos fournisseurs de services de stockage de données.<br/>
Nos filiales et sociétés apparentées.<br/>
Nos partenaires, fournisseurs et prestataires de services qui nous aident dans nos opérations commerciales, notamment en ce qui concerne la prévention des fraudes, la vérification de l'identité, le recouvrement des paiements, le marketing, le service clientèle et les services technologiques.
Nos commerçants, afin qu'ils puissent vous fournir des biens ou des services ou répondre à une plainte de votre part, ou pour les aider à améliorer la qualité et le niveau de service qu'ils vous fournissent.<br/>
Les institutions financières avec lesquelles nous pouvons nous associer pour créer et offrir conjointement un produit.<br/>
Les agences d'évaluation du crédit et de recouvrement, y compris la communication d'informations sur les comptes, comme le permet la loi. Lorsque nous partageons vos informations personnelles avec des agences d'évaluation du crédit, nous les autorisons à utiliser ces informations dans le but de fournir leurs services d'évaluation du crédit. Pour demander un rapport de solvabilité, nous fournirons à l'agence d'évaluation du crédit des informations permettant de vous identifier.<br/>
Les partenaires bancaires, comme l'exigent les règles de la Credit Card Association pour l'inclusion dans la liste des commerçants résiliés.<br/>
Les sociétés avec lesquelles nous prévoyons de fusionner ou d'acquérir, ou dans lesquelles nous pourrions investir.<br/>
Les agences gouvernementales ou les fonctionnaires en vertu d'une citation à comparaître, d'une ordonnance du tribunal ou de toute autre procédure ou exigence légale applicable à Momo, lorsque nous devons le faire pour nous conformer à la loi ou aux règles relatives aux cartes de crédit, ou lorsque nous estimons, à notre seule discrétion, que la divulgation d'informations personnelles est nécessaire pour prévenir tout préjudice physique ou financier, ou pour signaler une activité illégale présumée ou enquêter sur des violations présumées de notre contrat d'utilisation.<br/>
Tout autre tiers, tel que les organismes de médiation, que nous vous présentons, sachant que nous vous informerons, dans la mesure du possible, de leur identité, avant de procéder à leur présentation.<br/>
Toute autre partie avec votre consentement ou vos instructions pour le faire.<br/><br/>

8.2. A cet égard, nous utilisons vos données personnelles aux fins suivantes :<br/><br/>

Dans le but de préparer une proposition pour vous concernant les services que nous vous offrons.<br/>
Dans le but de vous fournir des services conformément à l'étendue de nos services ou comme convenu avec vous de temps à autre.<br/>
Afin de traiter toute plainte ou tout commentaire que vous avez formulé.<br/>
Pour toute autre raison pour laquelle vous nous fournissez vos données personnelles.<br/>
Momo peut demander votre consentement pour utiliser ou divulguer vos données personnelles si elle a besoin d'utiliser vos informations à une fin qui n'est pas liée à celle pour laquelle elles ont été collectées. Si vous ne consentez pas à ce que Momo collecte, utilise ou divulgue vos informations personnelles à ces autres fins, cela peut affecter la capacité de Momo à fournir et à améliorer ses produits et services, ou à faire des affaires avec vous.<br/><br/>

En outre, vous devez savoir que les marchands auprès desquels vous achetez des biens ou des services (même si ces biens ou services ont été achetés en utilisant les services de Momo) ont leurs propres politiques de confidentialité et que Momo n'est pas responsable de leurs actions, y compris de leurs pratiques en matière de protection des informations.<br/><br/>

8.3. intérêts légitimes :<br/><br/>

Nous traitons également les données personnelles vous concernant dans la mesure où cela est nécessaire pour servir nos intérêts légitimes ou parfois nécessaire pour servir les intérêts légitimes d'une autre personne et, à cet égard, nous utilisons les données personnelles aux fins suivantes :<br/><br/>

Aux fins de la gestion et de l'organisation du travail, y compris la réalisation d'analyses archivistiques ou statistiques.<br/>
Ou pour obtenir un avis consultatif sur nos droits et obligations, car nous demandons l'avis de nos conseillers juridiques, et à cet égard, nous partagerons vos données personnelles avec nos conseillers ou agents si cela est nécessaire pour obtenir leur avis ou leur soutien, et avec des tiers et leurs conseillers si ce tiers possède ou est réputé posséder une partie de notre activité.<br/><br/>

8.4. Obligations légales :<br/><br/>

Nous traitons également vos données personnelles dans le but de remplir une obligation légale à laquelle nous devons nous conformer. À cet égard, nous utiliserons vos données personnelles aux fins suivantes :<br/><br/>

Pour remplir nos obligations réglementaires et légales.<br/>
Pour répondre aux exigences des autorités fiscales ou de tout tribunal ou organisme officiel compétent, et à cet égard, nous partagerons vos données personnelles avec les entités suivantes :<br/>
Nos conseillers juridiques, si nécessaire, pour obtenir leurs conseils ou leur soutien.<br/>
Ou nos contrôleurs financiers, si ce partage d'informations est considéré comme faisant partie de leurs fonctions de surveillance.<br/>
Ou des tiers qui nous aident à effectuer des vérifications d'identité.<br/>
Les autorités réglementaires ou les organismes chargés de l'application de la loi compétents, si nous sommes tenus de le faire.<br/><br/><br/><br/>


9- Conditions d'utilisation des SMS :<br/>
En entrant votre numéro de téléphone mobile, vous acceptez de recevoir des messages texte pour les notifications de service . Momo n'est pas responsable des messages en retard ou non livrés.<br/><br/>

10- Marketing :<br/>
Nous vous enverrons des messages de marketing sur les services que nous fournissons et qui peuvent vous intéresser, et nous vous enverrons d'autres informations sous forme d'alertes, de bulletins d'information, de remises ou de tâches qui, selon nous, peuvent vous intéresser ou dans le but de vous fournir de nouvelles informations qui, selon nous, peuvent vous concerner. Nous pouvons utiliser vos informations personnelles pour faire des déductions et fournir un contenu plus pertinent, comme vos habitudes d'achat et de dépense. Nous vous les enverrons de différentes manières, notamment par  téléphone, par courrier électronique ou par d'autres canaux de communication numérique.<br/><br/>

Si vous vous inscrivez pour recevoir des notifications push (y compris des notifications marketing en magasin), nous vous enverrons des recommandations dont nous pensons qu'elles vous intéresseront, notamment des informations basées sur votre situation géographique et votre historique d'achat. Vous pouvez refuser à tout moment de recevoir des notifications marketing, y compris celles basées sur votre emplacement géographique, en utilisant les paramètres de votre application, vous pouvez également mettre à jour vos préférences en matière de notification de paiement à tout moment dans les paramètres de profil de l'application. Si vous refusez, nous pouvons toujours collecter et traiter votre emplacement géographique à d'autres fins.<br/><br/>

10- Promotions et publicités de notre part :<br/>
Nous pouvons utiliser vos données d'identité, vos données de contact, vos données techniques, vos données d'utilisation ou vos données de dossier avec nous dans le but de nous faire une idée de ce dont vous avez besoin ou de ce qui vous intéresse. Grâce à cela, nous pouvons déterminer les services appropriés qui vous concernent (en termes de marketing).<br/><br/>

Nous travaillons parfois avec des fournisseurs de publicité en ligne pour vous proposer des publicités pertinentes et utiles. Il peut s'agir de publicités affichées sur ou via nos sites web, mais aussi de publicités affichées sur les sites web d'autres sociétés. Ces publicités peuvent être basées sur des informations recueillies par nous ou par des tiers ; par exemple, votre mail peut être utilisé pour cibler une publicité sur les personnes de votre région. Ces publicités peuvent également être basées sur vos activités sur nos propres sites web ou sur des sites web de tiers.<br/><br/><br/><br/>


11- La possibilité de s'arrêter :<br/>
Si vous souhaitez ne plus recevoir de messages promotionnels, ce choix ne s'appliquera pas aux données personnelles que vous nous avez fournies dans le cadre de la prestation d'un service ou lors de l'enregistrement d'une garantie, ou pour des expériences de connaissance du service ou d'autres transactions.<br/><br/>

12- Cookies :<br/>
Nous utilisons des cookies et suivons les adresses IP sur nos sites web afin de pouvoir améliorer nos services sur nos sites web et améliorer votre expérience utilisateur. Lorsque vous accédez à nos sites web ou utilisez les services de Momo, nous (y compris nos partenaires et les entreprises avec lesquelles nous travaillons) pouvons placer de petits fichiers de données sur votre ordinateur ou autre appareil. Ces fichiers de données peuvent être des cookies, des balises pixel, des "témoins" ou tout autre stockage local fourni par votre navigateur ou les applications associées (collectivement, "cookies"). Nous utilisons les cookies pour vérifier quelles pages web sont visitées et à quelle fréquence, pour rendre nos sites web plus conviviaux, pour vous offrir une meilleure expérience lorsque vous revenez sur le site web et pour vous cibler des publicités dont nous pensons qu'elles pourraient vous intéresser ; par exemple, les cookies nous permettent de sauvegarder votre mot de passe afin que vous n'ayez pas à le saisir à chaque fois que vous visitez notre site.<br/><br/>

La plupart des navigateurs web acceptent automatiquement les cookies, et vous pouvez trouver des informations spécifiques à votre navigateur dans le menu Aide. Vous êtes libre de refuser nos cookies si votre navigateur ou son module complémentaire le permet, à moins que nos cookies ne soient nécessaires pour prévenir la fraude ou assurer la sécurité des sites web que nous contrôlons. Toutefois, le rejet de nos cookies peut interférer avec votre utilisation de nos sites web.<br/><br/>

Vous pouvez paramétrer votre système de navigation de manière à rejeter tous les cookies ou certains d'entre eux ou à vous alerter lorsque les sites web définissent ou saisissent des cookies pour vous, mais si vous désactivez ou refusez les cookies, nous tenons à vous informer que vous ne pourrez peut-être pas accéder à certaines parties de ce site web électronique ou que ces parties ne fonctionneront pas correctement.<br/><br/><br/><br/>


13- Changement de finalité :<br/>
Nous utilisons vos données personnelles uniquement aux fins pour lesquelles nous collectons ces données, à moins qu'il ne soit nécessaire d'utiliser ces données pour une autre finalité, et que cette finalité soit cohérente et n'entre pas en conflit avec la finalité première de leur collecte. Si vous voulez savoir comment le traitement est compatible pour servir la nouvelle finalité avec la finalité initiale des données, veuillez nous contacter. Si nous souhaitons utiliser vos données personnelles pour une finalité qui n'est pas liée à la finalité initiale, nous vous en informerons et nous vous expliquerons la base juridique qui nous permet de le faire. Veuillez noter que nous pouvons traiter vos données personnelles à votre insu, même sans votre consentement, conformément aux règles susmentionnées, si cette question est requise par la loi ou si nous y sommes autorisés.<br/><br/>

14- Modification de la politique de confidentialité :<br/>
Momo se réserve le droit, et dans son intégralité, de modifier les présentes conditions d'utilisation à tout moment, à condition que cette modification entre en vigueur dès que les conditions modifiées sont affichées sur le site Web.<br/>
Il incombe à l'utilisateur de vérifier périodiquement sur le site Web les modifications qui peuvent être apportées aux conditions d'utilisation.<br/><br/>

15- Divulgation de vos données personnelles :<br/>
Nous nous engageons à respecter la sécurité de vos données personnelles et à les traiter conformément aux dispositions de la loi. Nous n'autoriserons pas nos prestataires de services tiers à utiliser vos données personnelles pour servir leurs propres objectifs, mais nous ne les autoriserons à traiter vos données personnelles qu'à des fins spécifiques et conformément aux instructions que nous avons données à cet égard.<br/><br/>

16- Sécurité des données :<br/>
Nous avons mis en place les procédures et moyens de sécurité appropriés pour éviter toute perte accidentelle de vos données personnelles, leur utilisation ou leur accès non autorisé, leur modification ou leur divulgation de quelque manière que ce soit. En outre, nous n'accordons pas le droit d'accéder aux données personnelles, sauf dans la mesure où des employés, des agents ou des tiers ont besoin de connaître ces données pour servir nos objectifs commerciaux. Ils traiteront donc les données personnelles pour vous sur la base de nos instructions et seront tenus d'en préserver la confidentialité.<br/><br/>

Nous avons également établi des procédures pour traiter toute violation possible de la sécurité des données personnelles que nous soupçonnons, et nous vous en informerons, ainsi que toute autorité de surveillance compétente, si nous sommes tenus de le faire.<br/><br/>

Nous révisons également périodiquement nos procédures de sécurité afin de prendre en compte les nouvelles technologies et les méthodes actualisées appropriées. Seules les personnes dûment autorisées qui ont besoin d'accéder aux informations personnelles pour effectuer leur travail pourront voir ou utiliser ces informations. Toutefois, malgré nos efforts raisonnables, il n'existe aucune mesure de sécurité parfaite ou impénétrable.<br/><br/><br/><br/>


17- Liens vers des tiers :<br/>
Cette plateforme (site web, application pour appareils intelligents, portail client et tableau de bord) peut inclure des liens vers des sites web tiers, des composants électroniques supplémentaires et d'autres applications. Par conséquent, le fait de cliquer sur ces liens ou d'autoriser leur activation permettra à des tiers de collecter ou de partager des données vous concernant. Nous n'avons aucun contrôle sur les applications ou sites web de tiers, et nous ne sommes même pas responsables de leurs déclarations de confidentialité. Par conséquent, lorsque vous quittez notre site web, nous vous recommandons de consulter la politique de confidentialité applicable dans chaque application ou site web que vous visitez.<br/><br/><br/><br/>


18- Sauvegarde des données :<br/>
18.1. La période pendant laquelle vos données personnelles sont sauvegardées.<br/><br/>

Nous sauvegardons vos données personnelles pour la période nécessaire à la réalisation des objectifs pour lesquels nous avons collecté les données, y compris les objectifs liés au respect de toute exigence légale, réglementaire, fiscale, comptable ou de reporting. Nous pouvons conserver vos données personnelles pendant une période plus longue en cas de plainte ou si nous constatons qu'il existe une possibilité de litige concernant la relation avec vous.<br/><br/>

Pour déterminer la période appropriée de sauvegarde des données à caractère personnel, nous tenons compte de la quantité, de la nature et de la sensibilité des données à caractère personnel, du risque de préjudice découlant de l'utilisation ou de la divulgation non autorisée de vos données à caractère personnel. Les finalités, pour lesquelles vos données personnelles sont traitées, et si nous pouvons atteindre ces finalités par d'autres moyens ou non, et les exigences légales, réglementaires, fiscales, comptables ou autres qui sont en vigueur à cet égard.<br/><br/>

S'il n'est plus nécessaire pour nous de sauvegarder vos données personnelles, nous les annulerons (En ce qui concerne les détails des périodes de sauvegarde des données personnelles en vigueur sur différents sujets, l'énoncé de ces périodes s'applique dans notre politique de sauvegarde, politique que vous pouvez nous demander en communiquant avec nous à cet égard).<br/><br/>

19- Que pouvons-nous attendre de vous ?<br/>
Nous pouvons vous demander certaines informations pour nous aider à vous identifier et nous assurer que vous avez accès à vos données personnelles (ou que vous exercez l'un de vos droits). Il s'agit d'une mesure de sécurité visant à garantir que vos données personnelles ne sont pas divulguées à quiconque n'a pas le droit de les consulter ou de les obtenir. Nous pouvons également vous contacter pour vous demander plus d'informations liées à votre demande afin de répondre rapidement à un sujet de notre part.<br/><br/>

20- Durée nécessaire pour répondre :<br/>
Momo s'efforce de répondre à toutes vos demandes légitimes dans un délai d'un mois, mais il se peut que cela nous prenne plus d'un mois, si la demande reçue de votre part est une demande complexe ou si un certain nombre de demandes sont émises par vous. Dans ce cas, nous vous en informerons et  nous resterons en suivi avec vous.<br/><br/>

21- Vos droits légaux :<br/>
Vous avez le droit de :<br/><br/>

Demander à accéder à vos données personnelles, ce qui est une demande généralement connue sous le nom de "demande d'accès aux données". Cette demande vous permet d'obtenir une copie de vos données personnelles, et même de vérifier que nous les avons traitées légalement.<br/>
Demande de correction des données personnelles que nous détenons à votre sujet : Il s'agit d'une demande par laquelle vous pouvez corriger les données que nous détenons à votre sujet, notamment si elles sont incomplètes ou inexactes, mais nous devrons alors nous assurer de l'exactitude des nouvelles données que vous nous fournirez.<br/>
Demande d'effacement de vos données personnelles "Droit d'omission" : Il s'agit d'une demande par laquelle vous pouvez demander l'annulation ou la suppression de données personnelles s'il n'y a pas de raisons justifiables de continuer à les traiter. Vous avez également le droit de nous demander d'annuler ou de supprimer vos données personnelles si vous exercez avec succès votre droit d'opposition à leur traitement (voir ci-dessous). Si nous traitons vos informations en contradiction avec les dispositions de la loi ou si nous sommes tenus d'effacer vos données personnelles pour nous conformer aux dispositions du droit local. Il se peut que nous ne soyons pas en mesure de répondre à votre demande d'effacement des données s'il existe certaines raisons légales de le faire, que nous vous notifierons (si cela est nécessaire pour être applicable) lorsque vous en faites la demande.<br/>
Opposition au processus de traitement de vos données personnelles : si nous le faisons sur la base d'un intérêt légitime (l'intérêt de tiers), et s'il existe une commande liée à votre situation. Cette question vous permet de vous opposer au traitement au motif que vous estimez qu'il a un impact sur vos droits personnels et votre liberté. Vous avez également le droit de vous opposer si nous traitons vos données personnelles pour des raisons de marketing direct. Toutefois, dans certains cas, nous sommes tenus de préciser notre obligation - en fonction de motifs légaux ou législatifs - de traiter vos informations, et que cette obligation doit primer ou annuler vos droits et libertés mentionnés.
Demande de restriction du traitement de vos données personnelles : Avec cette demande, vous pouvez nous demander d'arrêter (temporairement) le traitement de vos données personnelles, dans les cas suivants :<br/>
Si vous voulez que nous vérifions l'exactitude des données.<br/>
Si notre utilisation des données est incompatible avec les dispositions de la loi, mais vous ne voulez pas que nous effacions les données.<br/>
Si vous vous opposez à notre utilisation de vos données, et que nous voulons vérifier qu'il existe au moins des motifs légitimes pour que nous les utilisions.<br/>
Demande de transfert de vos données personnelles à vous ou à un tiers : Nous vous fournirons, à vous ou au tiers que vous avez choisi, vos données personnelles d'une manière organisée et couramment utilisée et d'une manière permettant d'accéder automatiquement aux données. Veuillez noter que ce droit s'applique aux informations activées pour lesquelles vous nous avez donné votre consentement initial d'utilisation, et que nous avons utilisé ces informations dans le but d'exécuter un contrat avec vous.<br/>
Retirer à tout moment le consentement sur lequel nous nous appuyons pour le traitement des données, mais cette procédure n'affectera pas la légalité de tout traitement qui a eu lieu avant le retrait dudit consentement. Si vous retirez votre consentement, nous pourrions ne pas être en mesure de vous fournir certains produits ou services, et nous vous en informerons lorsque vous retirerez votre consentement.<br/>
Vous n'êtes pas tenu de payer des frais pour la saisie de vos données personnelles (ou pour l'exercice de tout autre droit), mais si votre demande n'est pas fondée ou s'il s'agit d'une demande répétitive injustifiée ou d'une demande excessive, nous pouvons (au lieu de ces frais) refuser de donner suite à votre demande, selon le cas.<br/><br/>

23- Comment traiter le "droit d'omission" ?<br/>
Vous avez le droit de demander l'effacement des données personnelles que nous détenons à votre sujet, dans certains cas, par exemple, si les informations n'ont pas été acquises dans un but légal ou s'il n'est plus nécessaire - pour des raisons légales - de posséder ces données. Ce droit est connu sous le nom de droit d'omission. Si vous nous avez demandé d'effacer vos données, nous le faisons généralement si ces données ne sont plus connues ou accessibles au public ou si nous n'avons plus besoin de les utiliser</PrivacyText>
    </PrivacyContainer>
  );
}

export default PrivatePolicy;
