
export const data  = [

    {
        id: 1,
        imge: 'rect.png',
        desc: "Always true"
    },
    {
        id: 2,
        imge: 'rect.png',
        desc: "Always true"
    },
    {
        id: 3,
        imge: 'rect.png',
        desc: "Always true"
    },
    {
        id: 4,
        imge: 'rect.png',
        desc: "Always true"
    },
    {
        id: 5,
        imge: 'rect.png',
        desc: "Always true"
    },

];
 
const dataCollection = [
    {
        question: "Qu'est-ce que Momo ?",
        answer: "Momo est une start-up spécialisée dans le domaine du BNPL (Buy Now, Pay Later). Notre mission est de fournir un service innovant qui permet aux entreprises et à leur personnel d'acquérir instantanément une vaste gamme de biens et de services. Nous sommes déterminés à simplifier le processus d'achat en offrant la possibilité à nos clients de régler leurs acquisitions ultérieurement grâce à notre système de paiements échelonnés judicieusement planifiés. Notre objectif principal est de faciliter les transactions commerciales tout en préservant la trésorerie de nos clients, ce qui leur permet de gérer plus efficacement leurs dépenses et de développer leur activité en toute confiance. Chez Momo, nous croyons en l'accessibilité et en la flexibilité, et nous mettons tout en œuvre pour rendre l'achat de biens et de services aussi simple et transparent que possible pour nos clients.",
    },

    {
        question: "Qui peut bénéficier du service BNPL proposé par Momo ?",
        answer: "Le service BNPL de Momo est bénéfique à un large éventail de clients, qu'il s'agisse d'entreprises cherchant à optimiser leur trésorerie ou de particuliers désireux de rendre leurs achats plus accessibles grâce à des paiements échelonnés.",
    },

    {
        question: "Est-ce que tous les produits sont éligibles au service BNPL de Momo ?",
        answer: "Non, tous les produits ne sont pas nécessairement éligibles au service BNPL de Momo. L'éligibilité dépend des politiques et des partenariats de Momo avec les vendeurs ou les entreprises.",
    },
    
    {
        question: "Les prix des produits pratiqués chez Momo sont-ils différents de ceux affichés en boutique ?",
        answer: "Non, les prix des produits chez Momo ne sont généralement pas différents de ceux affichés en boutique. Momo vise généralement à offrir aux clients des produits au même prix que ceux qu'ils trouveraient dans les magasins physiques, sans apporter de changements significatifs aux tarifs standard. Le service BNPL de Momo se concentre principalement sur la flexibilité de paiement.",
    },
    
    {
        question: "Quelle est la durée maximale de remboursement pour un achat en service BNPL chez Momo ?",
        answer: "La durée maximale de remboursement pour un achat en service BNPL chez Momo est généralement de 3 mois.",
    },
    {
        question: "Des frais d'intérêt sont-ils facturés lors de l’utilisation du service BNPL chez Momo ?",
        answer: "Oui, des frais d'intérêt de 7% peuvent être facturés lors de l'utilisation du service BNPL chez Momo",
    },
    {
        question: "Est-il nécessaire de verser un acompte avant de recevoir un produit via le service BNPL ?",
        answer: "Oui, il est généralement nécessaire de verser un acompte avant de recevoir un produit via le service BNPL chez Momo.",
    },
    {
        question: "Quel est le taux de l'acompte ?",
        answer: "Le taux de l’acompte est d'au moins 35 à 40% du prix du produit .",
    },
    {
        question: "Comment s'effectuent les paiements mensuels lors de l’utilisation du service BNPL ?",
        answer: "Les paiements mensuels lors de l'utilisation du service BNPL sont généralement effectués via une autorisation irrévocable de prélèvement automatique sur le salaire par son employeur, couvrant l'intégralité de l'échéancier",
    },
    {
        question: "Que se passe-t-il en cas de difficulté de paiement ?",
        answer: "Les paiements mensuels lors de l'utilisation du service BNPL sont généralement effectués via une autorisation irrévocable de prélèvement automatique sur le salaire par son employeur, couvrant l'intégralité de l'échéancier.",
    },
    {
        question: "Qui s'occupe de la garantie des produits achetés via le service BNPL de Momo ?",
        answer: "La garantie des produits achetés via le service BNPL de Momo est généralement prise en charge par le magasin dans lequel vous avez effectué l'achat.",
    },
    {
        question: "Qui contacter en cas de problème avec un produit défectueux ?",
        answer: "En cas de problème avec un produit défectueux, vous devriez contacter le magasin dans lequel l'achat a été effectué.",
    },
    
];

export default dataCollection;

export const tabData = [
    {
        name: 'Tout afficher',
        id: 'tout'
    },
    {
        name: 'Électroménagers',
        id: 'electromenagers'
    },
    {
        name: 'Électroniques',
        id: 'electroniques'
    },
    {
        name: 'Fournitures de bureaux',
        id: 'fournitures de bureaux'
    },
    {
        name: 'Maison',
        id: 'maison'
    },
    
    {
        name: 'Autres',
        id: 'autres'
    },
];

export const brandAllData = [

    {
        id: 'apple',
        name: 'Apple',
        imgLogo: 'logo_apple.png',
        imgBackGround: 'apple_font.png',
        categoryType: 'electroniques'
    },
    {   
        id: 'binatone',
        name: 'Binatone',
        imgLogo: 'logo_binatone.png',
        imgBackGround: 'binatone_font.png',
        categoryType: 'maison'
    },
    {
        id: 'google',
        name: 'Google',
        imgLogo: 'logo_google.png',
        imgBackGround: 'google_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'hisense',
        name: 'Hisense',
        imgLogo: 'logo_hisense1.png',
        imgBackGround: 'hisense_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'infinx',
        name: 'INFINIX',
        imgLogo: 'logo_infinix.png',
        imgBackGround: 'infinix_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'jbl',
        name: 'JBL',
        imgLogo: 'logo_jbl.png',
        imgBackGround: 'jbl_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'lg',
        name: 'LG',
        imgLogo: 'logo_lg.png',
        imgBackGround: 'lg_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'moulinex',
        name: 'Moulinex',
        imgLogo: 'logo_moulinex.png',
        imgBackGround: 'moulinex_font.png',
        categoryType: 'maison'
    },
    {
        id: 'nasco',
        name: 'Nasco',
        imgLogo: 'logo_nasco.png',
        imgBackGround: 'nasco_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'oraimo',
        name: 'ORAIMO',
        imgLogo: 'logo_oraimo.png',
        imgBackGround: 'oraimo_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'philips',
        name: 'PHILIPS',
        imgLogo: 'logo_philips.png',
        imgBackGround: 'philips_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'roch',
        name: 'Roch',
        imgLogo: 'logo_roch.png',
        imgBackGround: 'roch_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'samsung',
        name: 'SAMSUNG',
        imgLogo: 'logo_samsung.png',
        imgBackGround: 'samsung_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'sharp',
        name: 'SHARP',
        imgLogo: 'logo_sharp.png',
        imgBackGround: 'sharp_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'sony',
        name: 'SONY',
        imgLogo: 'logo_sony.png',
        imgBackGround: 'sony_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'tecno',
        name: 'TECNO',
        imgLogo: 'logo_tecno.png',
        imgBackGround: 'tecno_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'tcl',
        name: 'TCL',
        imgLogo: 'logo_tcl.png',
        imgBackGround: 'tcl_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'winnig-star',
        name: 'Winning Star',
        imgLogo: 'logo_winning-star.png',
        imgBackGround: 'winning-star_font.png',
        categoryType: 'maison'
    },
    {
        id: 'xiaomi',
        name: 'Xiaomi',
        imgLogo: 'logo_xiaomi.png',
        imgBackGround: 'xiaomi_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'autres',
        name: 'Autres',
        imgLogo: 'logo_white.png',
        imgBackGround: 'autres_font.png',
        categoryType: 'autres'
    },

];


export const brandRecommandedData = [

    {
        id: 'hisense',
        name: 'Hisense',
        imgLogo: 'logo_hisense1.png',
        imgBackGround: 'hisense_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'moulinex',
        name: 'Moulinex',
        imgLogo: 'logo_moulinex.png',
        imgBackGround: 'moulinex_font.png',
        categoryType: 'maison'
    },
    {
        id: 'nasco',
        name: 'Nasco',
        imgLogo: 'logo_nasco.png',
        imgBackGround: 'nasco_font.png',
        categoryType: 'electromenagers'
    },
    {
        id: 'tecno',
        name: 'TECNO',
        imgLogo: 'logo_tecno.png',
        imgBackGround: 'tecno_font.png',
        categoryType: 'electroniques'
    },
    {
        id: 'sony',
        name: 'SONY',
        imgLogo: 'logo_sony.png',
        imgBackGround: 'sony_font.png',
        categoryType: 'electroniques'
    },
    

];

export const ProductData = {

    name: 'Machine a laver  hisense 6 kg inverter wfxe6010',
    uid: '79brYia8I0Cr3FWKW1WL',
    finance: true,
    productDesc: '',
    minPrice: '190000',
    maxPrice: '200000',
    img: [
        'vvvvvvvvvvv',
        '',
        '',
        '',
        ''
    ],
    userWishList: [
        //'t08IX1o893TLzOdBv635vxj7KqC3',
        '',
        '',
        '',
        ''
    ],
    descFinance: '',
};

export const ProductDatas = {

    name: 'Réfrigérateur  hisense 6 kg inverter wfxe6010',
    uid: '79brYia8I0Cr3FWKW1WLs',
    productDesc: '',
    minPrice: '580000',
    maxPrice: '700000',
    img: [
        'vvvvvvvvvvv',
        '',
        '',
        '',
        ''
    ],
    userWishList: [
        't08IX1o893TLzOdBv635vxj7KqC3',
        '',
        '',
        '',
        ''
    ],
    descFinance: '',
};


