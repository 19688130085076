import Glider from 'glider-js';
import React , {useEffect} from 'react';
import { BRBContainer, BRContainer } from './BrandRecommanded.element';
import { brandRecommandedData } from '../data/data';
import Brand from './Brand';

const BrandRecommanded = () => {

  const prev = require('../images/prevs.png');
    
    useEffect(() => {
        new Glider(document.querySelector('.glider'), {
            slidesToScroll: 1,
            slidesToShow: 4,
            draggable: true,
            dots: '.dots',
            arrows: {
              prev: '.glider-prev',
              next: '.glider-next'
              }
          }); 
    }, []);
    
  return (
    <BRContainer>

          <div className='slider-btn'>
                <button aria-label="Previous" className="glider-prev"><img src={prev} width='21.88px' height='21.32px' alt=''/></button>
                <button aria-label="Next" className="glider-next"><img src={prev} width='21.88px' height='21.32px'  alt=''/></button>
          </div>  

      <div className="glider-contain">
        <div className="glider">
            {
                brandRecommandedData.map((item , index) => (
                    <BRBContainer key={index}>
                      <Brand name={item.name} brandNameImg={item.imgLogo} bgImg={item.imgBackGround} id={item.id} />
                    </BRBContainer>
                ))
            }
        </div>
    </div>
    </BRContainer>
  );
}

export default BrandRecommanded;
