import styled from "styled-components";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Card = styled.div`
display: flex;
flex-direction: column;
gap: .5rem;
margin: 14px;

@media screen and (max-width: 960px) {
    margin: 5px;
    gap: .2rem;
}
`

export const Img = styled.img`
border-radius: 3.125rem;
`;

export const Cards = styled.div`
width: 100%;
`;




