import styled from "styled-components";
import { Container } from '../globalStyles';

export const HomePayContainer = styled(Container)`
width: 100%;
display: flex;
flex-direction: row;
margin-top: 9.375rem;

@media screen and (max-width: 960px) {
    margin-top: .625rem;
    flex-direction: column;
}

${Container}
`;

export const HomePayLeftContentContainer = styled.div`
width:50%;
height: auto;
margin-right: 1.688rem;
display: flex;
flex-direction: column;
@media screen and (max-width: 960px) {
    margin-right: .469rem;
    width:100%;
}
`;

export const HomePayRightContentContainer = styled.div`
width:50%;
height: auto;
margin-left: 1.688rem;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    margin-left: .469rem;
    width:100%;
    margin-top: 2.188rem;
}
`;

export const TopImg = styled.img`
width: 100%;
height: 27.75rem;
border-radius:0px 0px 6.69rem 0px;
object-fit: cover;

@media screen and (max-width: 960px) {
    height: 9.375rem;
    border-radius:0px 1.25rem 0px 0px;
}
`;

export const HomePayRightBottomContainer = styled.div`
margin-top: 1.063rem;
display: flex;
flex-direction:row;
width: 100% !important;

@media screen and (max-width: 960px) {
    margin-top: .5rem;
}
`;

export const OneImg = styled.img`
width: 50%;
height: 25.25rem;
margin-right:.5remp;
border-radius:0px 0px 6.69rem 0px;
overflow: hidden !important;
object-fit: cover;

@media screen and (max-width: 960px) {
    margin-right: .25rem;
    height: 8.75rem;
}
`;

export const SecondImg = styled.img`
width: 50%;
height: 25.25rem;
margin-left: .5rem;
border-radius: 0px 0px 6.69rem 0px;
object-fit: cover;
overflow: hidden !important;

@media screen and (max-width: 960px) {
    margin-left: .25rem;
    height: 8.75rem;
    border-radius: 0px 0px 0px 1.25rem;
}
`;

export const RightHeadTextContainer = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: row;
margin-bottom: 1.25rem;

@media screen and (max-width: 960px) {
    margin-bottom: 0.625rem;
}
`;

export const RightTopHeadText = styled.h4`
width: 29.688rem;
height: auto;
font-size: 2.813rem;
color: rgba(0, 0, 0, 0.3);
font-style: normal;
font-weight: 700;
line-height: 3.875rem;

@media screen and (max-width: 960px) {
    width: 90%;
    height: auto;
    font-size: 1.438rem;
    color: rgba(0, 0, 0, 0.3);
    font-style: normal;
    font-weight: 700;
    line-height: 1.563rem;
}
`;

export const IconPay = styled.img`
width: 1.875rem;
height: 1.834rem;
margin-top: 1.063rem;

@media screen and (max-width: 960px) {
    width: .938rem;
    height: .938rem;
    margin-top: .188rem;
}
`;

export const HomePayRightTrueContentContainer = styled.div`
width:100%;
display: flex;
flex-direction: column;
margin-bottom: 3.125rem;

@media screen and (max-width: 960px) {
    margin-bottom: .938rem;
}
`;

export const HomePayRightTrueContentContainers = styled.div`
width:100%;
height: 15.75rem;
display: flex;
flex-direction: column;
margin-bottom: 3.125rem;

@media screen and (max-width: 960px) {
    margin-bottom: .938rem;
    height: auto;
}
`;

export const BottomHeadText = styled.p`
width: 38.5rem;
height: 4.5rem;
font-weight: 500;
font-size: 1.563rem;
line-height: 2.25rem;
font-style: normal;
color: #000000;

@media screen and (max-width: 960px) {
    width: 80%;
    height: auto;
    font-weight: 200px;
    font-size: .75rem;
    line-height: 1rem;
    font-style: normal;
}
`;