import React from 'react';
import { Containers, Content, ContentText, Header, HeaderIcon, Inner, ItemName } from './Accordion.element';

const AccordionContent = ({onClick, itemName, itemContent , isActive}) => {

    const more2 = require('../images/more2.png');

  return (
    <Containers>
      <Header onClick={onClick}>
            <ItemName>{itemName}</ItemName>
            <HeaderIcon  isActive={isActive} src={more2}/>
      </Header>

      <Content itemName={itemName} isActive={isActive}>
         <Inner id={itemName}><ContentText>{itemContent}</ContentText></Inner>
      </Content>
    </Containers>
  );
}

export default AccordionContent;
