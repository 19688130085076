import styled from "styled-components"

export const RegisterInputGroup = styled.div`
display: flex;
flex-direction: column;
gap: 1.2rem;
margin-bottom: 50px;
`

export const RegisterLabel = styled.label`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
color: #000000;
`

export const RegisterInputs = styled.input`
width: 400px;
height: 50px;
left: 664px;
top: 517px;
background: #FFFFFF;
padding: 0 1rem;
font-size: .9rem;
outline: none;
border: 1px solid #000000 !important;
border-radius: 10px;
box-sizing: border-box;
`;

export const ErrorMessage = styled.span`
color: red;
font-size: .8rem;
`