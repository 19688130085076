import styled from "styled-components";
import { Container } from '../../globalStyles';


export const InfoSec = styled.div`
background: #fff;
width: 100%;
align-items: center;
justify-content: center;
padding: 50px 0px;
margin-bottom: 50px;

@media screen and (max-width: 960px) {
    padding: 15px 0px;
    height: 25vh;
    margin-bottom:0px
}
`

export const SecondSectionContainer = styled(Container)`
width: 100%;

${Container}
`;

export const SecondTextContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin: 150px 0px;

@media screen and  (max-width: 960px) {
  margin: 50px 0px 30px 0px;
}

@media only screen and (min-width: 1347px) and (max-width: 1439px) {
  margin-bottom: 70px;
}
`;


export const FirstSecondSectionText = styled.h4`
font-weight: 900;
color: #000;
max-width: 1091px;
font-size: 74px;
align-content: center;
height: 180px;
margin-bottom: 75px;
line-height: 90px;
font-style: normal;

@media screen and (max-width: 960px) {
    font-size: 23px;
    //text-align: center;
    font-weight: 700;
    line-height: 30px;
    height: 50px;
    margin-bottom: 17px;
    color: #000;
    margin-bottom: 35px;
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
  font-weight: 900;
  color: #000;
  max-width: 1091px;
  font-size: 60px;
  align-content: center;
  height: 180px;
  margin-bottom: 30px;
  line-height: 80px;
  font-style: normal;
}

`;

export const SecondSectionSeconndText = styled.h4`
font-weight: 500;
color: #000;
width: 812px;
font-size: 28px;
align-content: center;
height: 68px;
line-height: 34px;
font-style: normal;

@media screen and (max-width: 960px) {
  font-size: .9rem;
  font-weight: 400;
  line-height: 20px;
  width: 300px;
}
`;

export const HeadContainer = styled(Container)`
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
  height: 5rem;
}

${Container}
`;

export const HeaderText = styled.h4`
font-weight: 900;
color: #000;
width: 100%;
font-size: 100px;
align-content: center;
height: 366px;
line-height: 122px;
margin-bottom: 28px;
font-style: normal;

@media screen and (max-width: 960px) {
    font-size: 30px;
    //text-align: center;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
    color: #000;
}

@media only screen and (min-width: 1440px) and (max-width: 1700px) {
    margin-right: 15rem;
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
  font-weight: 900;
color: #000;
width: 100%;
font-size: 80px;
align-content: center;
height: 266px;
line-height: 100px;
margin-bottom: 3.5rem;
font-style: normal;
}
@media only screen and (min-width: 1200px) and (max-width: 1347px) {
    font-size: 30px;
    //text-align: center;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
    color: #000;
}

@media only screen and (min-width: 989px) and (max-width: 1200px) {
    font-size: 30px;
    //text-align: center;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
    color: #000;
}
`;

export const SecondText = styled.h4`
font-size: 20px;
color: #AFAFAF;
line-height: 24px;
font-weight: 500;

@media screen and (max-width: 960px) {
  font-size: .9rem;
  //text-align: center;
  font-weight: 400;
  line-height: 20px;
  //display: none;
}
`;