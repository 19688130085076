import React , {useEffect} from 'react';
import CommercialFirstSection from '../CommercialFirstSection';
import CommercialPercentSection from '../CommercialPercentSection';
import CommercialRightorLeftSection from '../CommercialRightorLeftSection';
import { CommercialAdvantageText, CommercialHeadContainer  , CommercialHeadContentContainer, CommercialHeadDesc, CommercialHeadText , CommercialAdvantageContainer, DivPass} from './Commercial.element';
import { Helmet } from 'react-helmet';
import { navRef } from '../NavbarFeed';
import ModelBottomTopFooter from '../ModelBottomTopFooter';
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const Commercial = () => {

  const link = '/marchand/enregistrement';
  const texts = "S'inscrire";
  const secondTexts = "Vous envisagez de devenir l'un de nos partenaires ?";
  const plm3 = require('../../images/plm3.jpeg');
  const plm4 = require('../../images/plm4.jpeg');
  const plm5 = require('../../images/plm5.jpeg');
  const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'momo | Marchands | Votre succès est notre succès';
        window.scrollTo(0, 0);
        navRef.current.style.background = '#B7A857';
        dispatch(changeNavBarItemClicked('plm'));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      

  return (
    <>

        <Helmet>
            <meta charSet='utf-8'/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta name='keywords' content='Augmentez vos ventes, fidélisation, Les avantages de momo, Faites revenir votre clientèle, Accélérez l’acquisition de clientèle'/>
            <meta property="og:image" itemProp="image" content='../../images/logo.png'/>
            <meta property="og:site_name" content='Momo'/>
            <meta property="og:url" content='www.gotmomo.co/marchand/'/>
            <meta property="og:type" content='product'/>
            <meta property="og:title" content='Votre succès est notre succès'/>
            <meta property="og:description" content="Augmentez vos ventes et vos taux de fidélisation dès aujourd'hui."/>
            <meta name='description' content="Augmentez vos ventes et vos taux de fidélisation dès aujourd'hui."/>
        </Helmet>

      <CommercialHeadContainer>
          
          <CommercialHeadContentContainer>

              <CommercialHeadText>
                Votre succès<br/> est notre succès
              </CommercialHeadText>

              <CommercialHeadDesc>
                Augmentez vos ventes et vos taux de fidélisation dès aujourd'hui.
              </CommercialHeadDesc>

          </CommercialHeadContentContainer>

      </CommercialHeadContainer>

      <CommercialFirstSection/>

      <CommercialAdvantageContainer>

        <CommercialAdvantageText>
          Les avantages de momo
        </CommercialAdvantageText>

      </CommercialAdvantageContainer>

      <CommercialPercentSection/>

      <CommercialRightorLeftSection image={plm3} position={true} text1='Atteignez rapidement vos objectifs de revenus grâce à un programme adapté'  text2="Assurez-vous de ne pas manquer aucune opportunité de vente en proposant à vos clients les offres adaptées au moment et à l'endroit opportun. Des options de paiement sur plusieurs mois, allant de 1 à 6 mois, sont disponibles pour les achats." />

      <CommercialRightorLeftSection image={plm4} position={false} text1='Faites revenir votre clientèle'  text2="En proposant des options de paiement flexibles grâce à Momo, vous offrez un avantage qui peut inciter vos clients à acheter à nouveau auprès de votre entreprise et à renforcer leur fidélisation. Faites en sorte que vos clients reviennent en leur proposant des solutions de paiement adaptées à leurs besoins et possibilités financières." />

      <CommercialRightorLeftSection image={plm5} position={true} text1='Accélérez l’acquisition de clientèle'  text2="Profitez de notre réseau de premier plan pour promouvoir votre entreprise auprès d'une clientèle intéressée grâce à notre site Web, à nos campagnes de marketing, à nos réseaux sociaux et à d'autres moyens de diffusion. Faites confiance à notre expertise pour vous aider à atteindre vos objectifs." />

      <DivPass/>

      <ModelBottomTopFooter link={link} text={texts} secondText={secondTexts}/>

    </>
  );
}

export default Commercial;