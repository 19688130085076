import React from 'react';
import { BrandBgContainer, BrandImgBg, BrandImgNameContainer, BrandNameImg, BrandNameText , BrandContainer, BrandLinkWrappers } from './Brand.element';


const Brand = ({name , bgImg , brandNameImg , id}) => {
    
    const bgImgs = require('../images/marques/'+bgImg);
    const bandName = require('../images/marques/'+brandNameImg);
    //const ids = '/catalogue/produits/'+id;
    //to={{/*pathname: ids , search: `?q=${id}&filter=tout`}*/}} onClick={() => {/*navRef.current.style.background = "#D9D9D9"*/}}
    

    return(
        <BrandLinkWrappers>
            <BrandContainer translate='no' className='brand__container'>
                <BrandBgContainer>
                <BrandImgBg src={bgImgs} loading='lazy' alt='brand bg' />
                    <BrandImgNameContainer>
                        <BrandNameImg src={bandName} loading="lazy" alt='brand name' />
                    </BrandImgNameContainer>
                 </BrandBgContainer>
                <BrandNameText>{name}</BrandNameText>
            </BrandContainer>
            
            </BrandLinkWrappers>   
    )

}

export default Brand;
