import React from 'react';
import { AddCartContainer, AddIcon , AddWishListContainer, OverLayProduct, ProductContainer, ProductFinance, ProductHeadContainer, ProductImg, ProductNamePrice, ProductNameText } from './Product.element';
import { useDispatch , useSelector } from 'react-redux';
import { addToCart } from '../store/shopping-cart/cartSlice';
import { addProduct , removeProduct } from '../store/wish-list/wishListSlice';
import { toast } from 'react-hot-toast';
import CircularIndeterminate from './CircularIndeterminate';

const Product = ({product , db , firebase  , changeState , updateAddFavorisState , updateDeleteFavorisState , addFavoris , deleteFavoris}) => {

    const finance = product.finance && <ProductFinance>Financé</ProductFinance>;

    const [favoris , setFavoris] = React.useState(false);
    const [loading , setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const cartItems = useSelector(state => state.cart.cartItems);

    const cartIcon = require('../images/cart.png');
    const favorisIcon = require('../images/favoris.png'); 
    const favorisIcons = require('../images/heart.png'); 

    const favorisFiled = favoris ? favorisIcons : favorisIcon;
    

    const favorisIconRef = React.useRef(null);

    const user = JSON.parse(localStorage.getItem('user'));
    

    React.useEffect(() => {

      if(user !== null){
        if(product.userWishList.includes(user.uid)){
          setFavoris(true);
        }else{
          setFavoris(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [product])

    const currencyFormatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' , currencyDisplay: 'symbol'})

    const fieldName = 'userWishList';

    const deleteSellerInWishList = async element => {await db.collection('Togo').doc('Products').collection('ProductList').doc(product.uid).update({[fieldName]: firebase.firestore.FieldValue.arrayRemove(element)})};

    const addSellerWishList  = async element => {await db.collection('Togo').doc('Products').collection('ProductList').doc(product.uid).update({[fieldName]: firebase.firestore.FieldValue.arrayUnion(element)})};

    const insideCart = cartItems.some(p => p.uid === product.uid);

    const handleCartContainerClick = event => {

      if(insideCart){
        toast.success('Déja dans le panier')
      }else{
        toast.success('Produit ajouté au panier!')
      }

      const {uid , name , productDesc , minPrice , maxPrice , img} = product;

      const image = img[0];

      const totalPrice = maxPrice;

      dispatch(addToCart({uid , name , productDesc , minPrice , maxPrice , image , totalPrice}))

      event.stopPropagation();
      event.preventDefault();
    };

    const handleAddToWishList = () => {
      dispatch(addProduct(product));
    };
  
    const handleRemoveFromWishList = () => {
      dispatch(removeProduct(product.uid));
    };
  

    const handleWishListContainerClick = event => {

      setLoading(true);
      
        if(product.userWishList.includes(user.uid)){
           const index = product.userWishList.indexOf(user.uid);
            if(index !== -1){
              product.userWishList.splice(index, 1);
              deleteSellerInWishList(user.uid)
                .then(() => {
                  setFavoris(false);
                  updateDeleteFavorisState(product.uid , product.userWishList , user.uid);
                  handleRemoveFromWishList();
                  deleteFavoris(product.uid);
                  setLoading(false);
                  toast.success('Produit Retiré de votre favoris');
                })
                .catch(error => {
                  console.log(error);
                  setLoading(false);
                  toast.error("Une erreur c'est produite");
                })
            }
        }else{
            addSellerWishList(user.uid)
              .then(() => {
                setFavoris(true);
                updateAddFavorisState(product.uid , product.userWishList , user.uid);
                handleAddToWishList();
                addFavoris(product);
                setLoading(false);
                toast.success('Produit Ajouté à votre favoris.');
              })
              .catch(error => {
                console.log(error);
                setLoading(false);
                toast.error("Une erreur c'est produite");
              })
        }
      
    

      event.stopPropagation();
      event.preventDefault();
        
    }

    const handleUnLoggedUserWishClick = event => {

      toast.error("Vous devez vous connecté d'abord pour pouvoir ajoutée un produit à votre liste de favoris");

      event.stopPropagation();
      event.preventDefault();

    }
    
    
  return (
    <ProductContainer onClick={() => changeState(product)}>
      <ProductHeadContainer translate='no'>
        {
            finance
        }
        <ProductImg loading='lazy' src={product.img[0]}/>
        <OverLayProduct>
            {
              user !== null ? (
                <AddWishListContainer  onClick={handleWishListContainerClick}>
                   {!loading ? <AddIcon ref={favorisIconRef} src={favorisFiled}/> : <CircularIndeterminate size={15}/>}
                </AddWishListContainer>
              ) : (
                <AddWishListContainer  onClick={handleUnLoggedUserWishClick}>
                  <AddIcon src={favorisIcon}/> 
                </AddWishListContainer>
              )
            }
            <AddCartContainer  onClick={handleCartContainerClick}>
                <AddIcon src={cartIcon}/>
            </AddCartContainer>
        </OverLayProduct>
      </ProductHeadContainer>
      <ProductNameText>{product.name}</ProductNameText>
      <ProductNamePrice>{currencyFormatter.format(product.minPrice)} - {currencyFormatter.format(product.maxPrice)}</ProductNamePrice>
    </ProductContainer>
  );
}

export default Product;
