import React, {useEffect} from 'react';
import HomePaymenentSection from '../HomePaymenentSection';
import ModelBottomTopFooter from '../ModelBottomTopFooter';
import Slider from '../Slider';
import { Helmet } from 'react-helmet';
import WriteWhatsAppSection from '../WriteWhatsAppSection';
import { FirstSecondSectionText, HeadContainer, HeaderText, InfoSec, SecondSectionContainer, SecondSectionSeconndText, SecondText, SecondTextContainer } from './HomePage.elements';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const HomePage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeNavBarItemClicked('home'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  
  const text = (window.innerWidth <= 960) ? <SecondText>Le shopping devrait être un plaisir, sans stress 
Avec Momo vous pouvez diviser vos paiements   -   faible taux intérêt.</SecondText> : <SecondText>Le shopping devrait être un plaisir, sans stress<br/> 
Avec Momo vous pouvez diviser vos paiements   -   faible taux intérêt.</SecondText>;

const HeadText = (window.innerWidth <= 960) ? <HeaderText>Nous changeons<br/>
la façon dont vous faites vos achats</HeaderText> : <HeaderText> Nous changeons<br/>la façon dont vous faites<br/>vos achats</HeaderText>;

const secondText = (window.innerWidth <= 960) ? <SecondSectionSeconndText>Nous nous efforçons de trouver de nouvelles et meilleures façons d'aider les clients à s’épanouir.</SecondSectionSeconndText> : <SecondSectionSeconndText>Nous nous efforçons de trouver de nouvelles et<br/>meilleures façons d'aider les clients à s’épanouir.</SecondSectionSeconndText>;

const link = '/catalogue';
const texts = 'Catalogue';
const secondTexts = `Vous l'appelez opportunité... nous l'appelons Momo`;
  
  return (
    <>
        <Helmet>
            <meta charSet='utf-8'/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <title>Momo - Nous changeons la façcon dont vous faites vos achats</title>
            <meta name='keywords' content='Momo, React Js, momo, e-commerce, finace, vente en ligne togo, gotmomo, achat au Togo, e-commerce Togo, au Togo, site de vente en ligne, site e commerce Togo, shopping, faible taux intérêt, fractionnez vos paiements, pas de tracas, pas de stress, Vente au détail, Développement informatique, Paiement en ligne, Analyse des données, Expansion'/>
            <meta property="og:image" itemProp="image" content='../../images/logo.png'/>
            <meta property="og:site_name" content='Momo'/>
            <meta property="og:url" content='www.gotmomo.co'/>
            <meta property="og:type" content='product'/>
            <meta property="og:title" content='Momo - Nous changeons la façcon dont vous faites vos achats'/>
            <meta property="og:description" content='Le shopping devrait être un plaisir, sans stress
Avec Momo vous pouvez diviser vos paiements - faible taux intérêt.'/>
            <meta name='description' content='Le shopping devrait être un plaisir, sans stress
Avec Momo vous pouvez diviser vos paiements - faible taux intérêt.'/>
        </Helmet>

        <Toaster toastOptions={{duration: 4000}}/>

      <InfoSec>
      
        <HeadContainer>

          {HeadText}

          {text}

        </HeadContainer>
        
      </InfoSec>
      
      <Slider/>

      <SecondSectionContainer>

          <SecondTextContainer>

              <FirstSecondSectionText>
                Aider les gens à accéder aux besoins quotidiens.
              </FirstSecondSectionText>

              {secondText}

          </SecondTextContainer>

      </SecondSectionContainer>

      <HomePaymenentSection/>

      <WriteWhatsAppSection/>

      <ModelBottomTopFooter link={link} text={texts} secondText={secondTexts}/>

    </>
  );
}

export default HomePage;
