import React from 'react';
import CircularProgress, { circularProgressClasses, } from '@mui/material/CircularProgress';
  import {  Box } from '@mui/material';

const CircularProgressBar = () => {
  return (
    <Box  sx={{ position: 'relative' }}>

        <CircularProgress
            variant="determinate"
            sx={{color: (theme) =>theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],}}
            size={40}
            thickness={4}
            value={100}/>

        <CircularProgress    
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? '#000' : '#000'),
              animationDuration: '550ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]:
                  {
                    strokeLinecap: 'round',
                  },
              }}      
              size={40}
              thickness={4}/>
    </Box>

  );
}

export default CircularProgressBar;
