import styled from "styled-components";
import { Container} from "../../globalStyles";

export const FAQContainer = styled(Container) `
display: flex;
flex-direction: column;

${Container}
`

export const FAQHeadText = styled.h4`
width: 953px;
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
text-align: center;
color: #000000;
align-self: center;
margin: 57.5px;
`;

export const FAQDivider = styled.div`
width: 1200px;
height: 2px;
background-color: #F7F7F7;
align-self: center;
margin-bottom: 69.5px;
`;

export const FaqQuestionContainer = styled.div`
align-items: center;
align-items: center;
border: none !important;
margin-bottom: 50px;
width: 100%;
`

export const FaqQuestion = styled.h4`
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

export const AccordionStyle = styled.div`
width: 100%;
display: grid;
place-items: center;
`;

export const TextBottomHead = styled.p`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
width: 237px;
text-align: center;
align-self: center;
color: #000000;
`;

export const ButtonHelpFaqContainer = styled.div`
width: 376px;
height: 50px;
display: flex;
flex-direction: row;
margin-top: 50px;
border: 1px solid #000000 !important;
border-radius: 30px;
box-sizing: border-box;
cursor: pointer;
margin-bottom: 150px;
align-self: center;
align-items: center;
justify-content: center;
`;


export const ImgHelpCenter = styled.img`
width: 18px;
height: 18px;
margin-left: 20px;
`;

