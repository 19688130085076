import { createSlice } from "@reduxjs/toolkit";

const items = localStorage.getItem('products') !== null ? JSON.parse(localStorage.getItem('products')) : [];

const setItemFunc = (item) => {

  localStorage.setItem('products' , JSON.stringify(item));

}

export const wishListSlice = createSlice({
    name: 'wishList',
    initialState: {
      products: items,
    },
    reducers: {
      addProduct: (state, action) => {

        const newItem = action.payload
        const existingItem = state.products[0] !== null && state.products.find(item => item.uid === newItem.uid);

        if(!existingItem){
          state.products.unshift(action.payload);
        }
        
        setItemFunc(state.products.filter(item => item !== null));

      },
      removeProduct: (state, action) => {
        const index = state.products.findIndex(p => p.uid === action.payload);
        if (index !== -1) {
          state.products.splice(index, 1);
        }
        setItemFunc(state.products.filter(item => item !== null));
      },
    },
  });
  
  export const { addProduct, removeProduct } = wishListSlice.actions;
  export default wishListSlice.reducer;