import { Link } from "react-router-dom";
import styled from "styled-components";
import {CgSpinner} from 'react-icons/cg'

export const ConnexionContainer = styled.div`
width: '100vw';
height: '100vh';
display: flex;
flex-direction: row;
overflow: hidden;
background-color: #fff;
z-index: 8000;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
`;


export const LeftConnexionContainer = styled.div`
width: 58vw;
height: 100vh;
`;

export const LeftConnexionBackground = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
`;

export const RightConnexionContainer = styled.div`
display: flex;
flex-direction: column;
width: 40vw;
height: 100vh;
`;

export const ConnexionProcess = styled.div`
display: flex;
flex-direction: column;
align-self: center;
justify-content: center;
margin-top: 280px;
`;

export const CNTextHead = styled.h4`
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 39px;
color: #000000;
align-self: center;
justify-content: center;
display: flex;
width: 396px;
margin-bottom: 5px;
`;

export const CNTextDesc = styled.p`
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
width: 396px;
color: #535353;
margin-bottom: 30px;
`;

export const CPhoneNumberSection = styled.div`
width: 396px;
height: 50px;
display: flex;
flex-direction: row;
`;

export const CCountryFlagIndexSection = styled.div`
height: 100%;
width: 140px;
margin-right: 8px;
border: 1px solid #DBDBDB !important;
border-radius: 10px 0px 0px 10px !important;
display: flex;
flex-direction: row;
`;

export const FlagCImg = styled.img`
width: 22px;
height: 15.12px;
align-self: center;
margin-left: 20px;
margin-right: 4px;
`;

export const CCountryIndexText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
align-self: center;

color: #000000;
`;

export const CCountrySelect = styled.img`
width: 15px;
height: 9.69px;
display: flex;
align-self: center;
margin-left: 25px;
`;

export const PhoneFormsImput = styled.input`
width: 248px;
height: 100%;
border: 1px solid #DBDBDB !important;
border-radius: 0px 10px 10px 0px !important;
padding: 1rem;
font-style: normal;
outline: none;
font-weight: 400;
font-size: 14px;
line-height: 17px;
font-family: 'Inter';
`;

export const PhoneText = styled.div`
width: 248px;
height: 100%;
border: 1px solid #DBDBDB !important;
border-radius: 0px 10px 10px 0px !important;
padding: 1rem;
font-style: normal;
outline: none;
font-weight: 400;
color: #000000;
display: flex;
align-items:center;
font-size: 14px;
line-height: 17px;
font-family: 'Inter';
`;

export const ButtonValidatePhone = styled.button`
width: 100%;
height: 50px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top: 19px;
cursor: pointer;
font-style: normal;
font-family: 'Montserrat';
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #fff;
background: #000;
border-radius: 10px;
margin-bottom: 19px;
`;

export const ButtonValidatePhoneOff = styled.button`
width: 100%;
height: 50px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top: 19px;
cursor: pointer;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #9A9A9A;
background: #EDEDED;
border-radius: 10px;
margin-bottom: 19px;
`;

export const ButtonRegister = styled.button`
width: 100%;
height: 50px;
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #fff;
background: #000;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
color: #FFFFFF;
background: #000000;
border-radius: 10px;
box-sizing: border-box;
`

export const ConnexionSpinner = styled(CgSpinner)`
animation: spin 1s infinite linear;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`

export const ConnexionSpinnerContainer = styled.span`
margin-right: .5rem;
height: 100%;
display: none;
flex-direction: column;
justify-content: center;
`;

export const LogOutButtonOff = styled.p`
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
align-self: center;
text-align: center;
color: #535353;
cursor: pointer;
`;

export const LogOutButtonOn = styled.p`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
align-self: center;
text-align: center;
color: #535353;
cursor: pointer;
`;

export const CLinkSection = styled.div`
display: flex;
flex-direction: row;

width: 100%;
margin-bottom: 52px;
align-items: flex-end;
height: 100%;
justify-content: center;
`;

export const MomoReserve = styled.p`
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
display: flex;
font-size: 10px;
line-height: 12px;
margin-right: 30px;
color: #000000;
`;

export const CLinkOne = styled(Link)`

font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
color: #000000;
margin-right: 30px;
border: none;
outline: none;
text-decoration: none;
cursor: pointer;

&:hover{
    text-decoration: underline;
    text-decoration-color: #000;
}
`;

export const CLinkTwo = styled(Link)`

font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
color: #000000;
border: none;
outline: none;
text-decoration: none;
cursor: pointer;

&:hover{
    text-decoration: underline;
    text-decoration-color: #000;
}
`;



