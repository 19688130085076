import styled from "styled-components";
import { Container } from "../../globalStyles";

export const CTHeadContainer = styled.div`
width: 100%;
height: 420px;
background: #57B793;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    
}
`;

export const CTContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
overflow: hidden;
`;

export const CTHeadContentContainer = styled(Container)`
width: 100%;
height: 100%;
justify-content: center;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    
}

${Container}
`;

export const CTContainerWrapper = styled(Container)`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
overflow: hidden;

@media screen and (max-width: 960px) {
    
}

${Container}
`

export const CTHeadText = styled.h4`
width: 953px;
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
margin-bottom: 28px;
color: #000000;

@media screen and (max-width: 960px) {
    
}
`;

export const CTHeadDesc = styled.text`
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #000000;

@media screen and (max-width: 960px) {
    
}
`;

export const CTText = styled.text`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
margin-top: 100px;
margin-bottom: 50px;
width: 100%;

color: #000000;

@media screen and (max-width: 960px) {
    
}
`;

export const CTText2 = styled.p`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
margin-top: 30px;
width: 100%;
margin-bottom: 63px;

color: #000000;

@media screen and (max-width: 960px) {
    
}
`;

export const CTSearchBar = styled.div`
box-sizing: border-box;
height: 50px;
border: 1px solid #DBDBDB !important;
display: flex;
flex-direction: row;
width: 100%;
margin-bottom: 46px;
`;

export const SearchIcon = styled.img`
width: 20px;
height: 20px;
margin-left: 45px;
align-self: center;
`;

export const SearchInput = styled.input`
width: 100%;
height: 100%;
margin-left: 19px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
outline: none;
font-size: 14px;
line-height: 17px;
color: #000000;
`;

export const CTContainerWrappers = styled.div`
width: 100%;
overflow: hidden;
border-bottom: 1px solid #D9D9D9 !important;
margin-bottom: 81px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
margin: 0;
padding: 0;

@media screen and (max-width: 960px) {
    
}

`;