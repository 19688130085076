import React from 'react';
import EmptyFeed from '../EmptyFeed';
import { WishListEmptyContainer , WishListShoppingContainer  , EnptyBtnLink , EmptyBtnContainer, WishListHeadText  , WishListContentContainer, WishListDivider, WishListTabHeadContainer , WishListHeadContentContainer , WishListContentGlobalContainer , WishListText } from './WishList.element';
import { navRef } from '../NavbarFeed';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { handleBack } from '../../utils/function';
import ModalWishListAdd from '../ModalWishListAdd';

const WishList = ({db , firebase}) => {
  

  const wishListProducts = useSelector(state => state.wishList.products);
  console.log(wishListProducts)
  
 
  React.useEffect(() => {
      window.scrollTo(0, 0);
      navRef.current.style.background = '#fff';
    }, []);
    

  return (
    <>
    <Toaster toastOptions={{duration: 4000}}/>
      {
      wishListProducts.length === 0 ? (
        <WishListEmptyContainer>
          <EmptyFeed text='Pas de produis ajouté au favoris'/>
          <WishListShoppingContainer>
          <EnptyBtnLink onClick={handleBack}>
             <EmptyBtnContainer>Continuer vos achats</EmptyBtnContainer>
          </EnptyBtnLink>
      </WishListShoppingContainer>
        </WishListEmptyContainer>
      ) : (
        <WishListContentGlobalContainer>
          <WishListText>Les favoris</WishListText>
          <WishListContentContainer>
              <WishListDivider/>
              <WishListTabHeadContainer>
                <WishListHeadContentContainer>
                    <WishListHeadText>Nom des produits</WishListHeadText>
                </WishListHeadContentContainer>
                <WishListHeadContentContainer>
                    <WishListHeadText>Prix</WishListHeadText>
                </WishListHeadContentContainer>
              </WishListTabHeadContainer>
              <WishListDivider/>
              {
                wishListProducts.map((item , index) => item !== null && <ModalWishListAdd db={db} firebase={firebase} product={item} key={index}/>)
              }
          </WishListContentContainer>

          <WishListShoppingContainer>
              <EnptyBtnLink onClick={handleBack}>
                  <EmptyBtnContainer>Continuer vos achats</EmptyBtnContainer>
              </EnptyBtnLink>
          </WishListShoppingContainer>
              
        </WishListContentGlobalContainer>
      )
    }
    </>
  );
}

export default WishList;
