import React from 'react';
import { FAQContainer, FAQDivider, FAQHeadText , AccordionStyle, TextBottomHead, ButtonHelpFaqContainer,  ImgHelpCenter } from './Faq.element';
import dataCollection from '../../data/data';
import Accordion from '../Accordion';
import ReactWhatsapp from 'react-whatsapp';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const FAQ = () => {

  const img = require('../../images/help.png');
  const dispatch = useDispatch();
  


  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeNavBarItemClicked('faq'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>

        <Helmet>
            <meta charSet='utf-8'/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <title>Momo - FAQ</title>
            <meta property="og:image" itemProp="image" content='../../images/logo.png'/>
            <meta property="og:site_name" content='Momo'/>
            <meta property="og:url" content='www.gotmomo.co/faq'/>
            <meta property="og:type" content='product'/>
            <meta property="og:title" content='Questions fréquemment posées'/>
            <meta property="og:description" content="Avez-vous vu votre question ?"/>
            <meta name='description' content="Avez-vous vu votre question ?"/>
        </Helmet>

      <FAQContainer>

          <FAQHeadText>
              Questions fréquemment posées
          </FAQHeadText>

          <FAQDivider/>

          <AccordionStyle>
              <Accordion items={dataCollection}/>
          </AccordionStyle>

          <TextBottomHead>
              Avez-vous vu votre question ?
          </TextBottomHead>

          <ButtonHelpFaqContainer>

            <ReactWhatsapp number='+22899853545' message=''      className='react__wathsapps'>
                  Contactez notre centre d’aide
            </ReactWhatsapp>
    

            <ImgHelpCenter src={img} alt='logo'/>

        </ButtonHelpFaqContainer>

      </FAQContainer>
    </>
    
  );
}

export default FAQ;
