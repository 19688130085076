import React from 'react';
import { RegisterAddMoreInputs, RegisterAddMoreLabel, RegisterInputAddMoreGroup } from './RegisterInputAddMore.element';

const RegisterInputAddMore = ({id, label , register}) => {
  return (
    <RegisterInputAddMoreGroup>
      <RegisterAddMoreLabel>{label}</RegisterAddMoreLabel>
      <RegisterAddMoreInputs cols="40" rows="8" id={id} {...register}/>
    </RegisterInputAddMoreGroup>
  );
}

export default RegisterInputAddMore;
