import React from 'react';
import { ConnexionContainer, ConnexionProcess, LeftConnexionBackground, LeftConnexionContainer, RightConnexionContainer , CNTextHead, CNTextDesc, CPhoneNumberSection, CCountryFlagIndexSection, FlagCImg, CCountryIndexText , CCountrySelect, PhoneFormsImput, ButtonValidatePhone, CLinkSection, MomoReserve, CLinkOne , CLinkTwo, ConnexionSpinnerContainer, ConnexionSpinner, PhoneText, LogOutButtonOff, LogOutButtonOn , ButtonValidatePhoneOff} from './Connexion.element';
import { toast, Toaster } from 'react-hot-toast';
import OtpInput from 'react18-input-otp';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';
import {formatNumber} from '../../utils/function'
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const Connexion = ({db , firebase}) => {

  const img = require('../../images/connexion1.jpeg')
  const flag = require('../../images/flag.png')
  const more2 = require('../../images/more2.png');

  const btnText = React.useRef(null);
  const btnSpinner = React.useRef(null);
  const connexionHeadtext = React.useRef(null);
  const connexionTextDesc = React.useRef(null);
  const phoneNumberSection = React.useRef(null);
  const btn = React.useRef(null);

  const dispatch = useDispatch();


  const createUser = async data => {await db.collection('Togo').doc('Users').collection('UserList').doc(data.userUID).set(data)}
  const navigate = useNavigate();

  const [loading , setLoading] = React.useState(false);
  const [user , setUser] = React.useState(null);
  const [showOTP , setShowOTP] = React.useState(false);
  const [otp , setOtp] = React.useState('');
  const [ph , setPh] = React.useState('');

  const onCaptchVerify = () =>  {

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        onSignup();
      }
    });

  };

  function onSignup(){
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = '+228'+ph;

    firebase.auth().signInWithPhoneNumber(formatPh, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      setLoading(false);
      setShowOTP(true);
      toast.success('Sms envoyé avec succès')
    }).catch((error) => {
      console.log(error)
      setLoading(false);
    });
    
  }

  function handlePhoneChange(event){
    const valeur = event.target.value.replace(/[^\d]/g, ''); 
    setPh(valeur);
  }

  const handleSignOut = () => {

    firebase.auth().signOut()
      .then(() => {
        localStorage.setItem('user' , null);
        toast.success('Compte déconnecté avec succès!');
        navigate('/');
      })
      .catch(error => {
        toast.error("Une erreur de connexion s'est produite")
      })

  }

  function onOTPVerify(){

    setLoading(true);
    window.confirmationResult.confirm(otp).then(async(res) => {
      setUser(res.user);

      const data = {
        userUID: res.user.uid,
        userPhone: res.user.phoneNumber,
        dateCreated: firebase.firestore.Timestamp.fromDate(new Date())
      }
        
      createUser(data).then(()=> {

        localStorage.setItem('user' , JSON.stringify(res.user));
        console.log(res.user);
        console.log(user);
        setLoading(false);
        toast.success('Vous êtes à présent connecté(e)')
        navigate('/');

      }).catch(error => {
          console.log(error)
      })
      
    }).catch(error => {
      toast.error('Code saisie incorrect!')
      setLoading(false)
    })
  };

  const handleClick = () => {

   if(ph.length === 8){

     if(!showOTP){
      onSignup()
    }else {
      onOTPVerify();
    }

   }else{
      toast.error('Veuillez saisie un numéro valide!')
   }

  }

  firebase.auth().useDeviceLanguage();

  const handleOTPChange = (enteredOtp , event) => {
    setOtp(enteredOtp)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(changeNavBarItemClicked('login'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    if(loading){
      btnSpinner.current.style.display = 'flex';
      if(!showOTP) {
        btnText.current.textContent = 'Envoie du sms...'
      }else {
        btnText.current.textContent = 'Vérication du code...'
      }
    }else{
      btnSpinner.current.style.display = 'none';
      if(!showOTP){
        btnText.current.textContent = 'Continuer';
      }else {
        connexionHeadtext.current.textContent = 'Vérification du code OTP';
        connexionTextDesc.current.textContent = 'Veuillez saisie le code de vérication envoyé sur vôtre numéro par sms';
        btnText.current.textContent = 'Vérication du code'
      }
      
    }

  } , [loading , showOTP]);


  return (
    <>

        <Helmet>
            <meta charSet='utf-8'/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <title>Momo - Connexion</title>
            <meta property="og:image" itemProp="image" content='../../images/logo.png'/>
            <meta property="og:site_name" content='Momo'/>
            <meta property="og:url" content='www.gotmomo.co/connexion'/>
            <meta property="og:type" content='product'/>
            <meta property="og:title" content='Identifiez-vous ou créez un compte'/>
            <meta property="og:description" content="Entrez votre numéro de mobile pour vous connecter à Momo ou pour créer un nouveau compte."/>
            <meta name='description' content="Entrez votre numéro de mobile pour vous connecter à Momo ou pour créer un nouveau compte."/>
        </Helmet>

      <ConnexionContainer>
      <Toaster toastOptions={{duration: 4000}}/>
        <LeftConnexionContainer>
          <LeftConnexionBackground src={img} loading='lazy' alt='Serge'/>
        </LeftConnexionContainer>

        <RightConnexionContainer>
          <ConnexionProcess>
            {
              JSON.parse(localStorage.getItem('user')) === null  ? <CNTextHead ref={connexionHeadtext}>Identifiez-vous ou créez un compte</CNTextHead> : <CNTextHead ref={connexionHeadtext}>Vous êtes actuellement connecté(e)</CNTextHead>
            }
            {
              JSON.parse(localStorage.getItem('user')) === null   ? <CNTextDesc ref={connexionTextDesc}>Entrez votre numéro de mobile pour vous connecter à Momo ou pour créer un nouveau compte.</CNTextDesc> : <CNTextDesc ref={connexionTextDesc}>Si vous souhaitez vous déconnecter, veuillez cliquer sur le lien de déconnexion</CNTextDesc>
            }
            {
              !showOTP ? (
                <CPhoneNumberSection ref={phoneNumberSection}>
              <CCountryFlagIndexSection>
                  <FlagCImg src={flag} alt='Togo-Flag' />
                  <CCountryIndexText>+228</CCountryIndexText>
                  <CCountrySelect src={more2} allt='logo-selection'/>
              </CCountryFlagIndexSection>
              {
                JSON.parse(localStorage.getItem('user')) === null  ? <PhoneFormsImput placeholder='Votre numero whatsapp' type='tel' value={ph} onChange={handlePhoneChange}/> : <PhoneText>{

                  formatNumber(JSON.parse(localStorage.getItem('user')).phoneNumber.slice(4))

                }</PhoneText>
              }

            </CPhoneNumberSection>
              ) :  (JSON.parse(localStorage.getItem('user')) === null  ? (
                <OtpInput
                    value={otp}
                    onChange={handleOTPChange}
                    numInputs={6}
                    autoFocus
                    isSuccessed={true}
                    ariaLabelOverride={false}
                    focusStyle={true}
                    successStyle="success"
                    separator={<span>-</span>}
                    separateAfter={3}
                    onSubmit={console.log(otp)}
                    className="otp__container"  
                    />) : (
                      <CPhoneNumberSection ref={phoneNumberSection}>
                        <CCountryFlagIndexSection>
                            <FlagCImg src={flag} alt='Togo-Flag' />
                            <CCountryIndexText>+228</CCountryIndexText>
                            <CCountrySelect src={more2} allt='logo-selection'/>
                        </CCountryFlagIndexSection>
                        <PhoneText>{firebase.auth().currentUser.phoneNumber.slice(4)}</PhoneText>
                      </CPhoneNumberSection>
            ))
              
            }

            {
              JSON.parse(localStorage.getItem('user')) === null  ? (
                  <ButtonValidatePhone ref={btn} id='sign-in-button' onClick={handleClick}>
                    <ConnexionSpinnerContainer ref={btnSpinner}>
                      <ConnexionSpinner size={25} />
                    </ConnexionSpinnerContainer>
                    <span ref={btnText}>Continuer</span>
                  </ButtonValidatePhone>
            ) : (
              
                  <ButtonValidatePhoneOff ref={btn}>
                    <ConnexionSpinnerContainer ref={btnSpinner}>
                      <ConnexionSpinner size={25} />
                    </ConnexionSpinnerContainer>
                    <span ref={btnText}>Continuer</span>
                  </ButtonValidatePhoneOff>
                  )
            
            }  

            {
              JSON.parse(localStorage.getItem('user')) === null  ? <LogOutButtonOff onClick={() => {
                console.log(JSON.parse(localStorage.getItem('user')))
              }}>Déconnexion</LogOutButtonOff> : <LogOutButtonOn onClick={handleSignOut}>Déconnexion</LogOutButtonOn>
            }

          </ConnexionProcess>
          <CLinkSection>
            <MomoReserve>© 2023 momo. Tous les droits sont réservés</MomoReserve>
            <CLinkOne to='/privacy'>Politique de confidentialité</CLinkOne>
            <CLinkTwo to='/termes-et-conditions'>Termes et conditions</CLinkTwo>
          </CLinkSection>
        </RightConnexionContainer>
      </ConnexionContainer>
    </>
  );
}

export default Connexion;
