import styled from "styled-components";

export const EmptyProductContainer = styled.div`
margin-top: 100px;
margin-bottom: 150px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
overflow: hidden;
`;


export const EmptyImg = styled.img`
width: 300px;
height: 300px;
margin-bottom: .3rem;
object-fit: cover;
`;


export const EmptyProductText = styled.h1`
width: 500px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 20px;
text-align: center;
line-height: 30px;
color: #000000;
`