import React from 'react';
import { TabContainer, TabHeadTitle, TabSearchBar, TabSearchIcon, TabSearchInput } from './TabFeed.element';
import { brandAllData} from '../data/data';
import { motion } from "framer-motion"
import Brand from './Brand';
import { FirestoreCollection } from 'react-firestore';
import { Skeleton } from '@mui/material';

const TabFeed = () => {

  const icon = require('../images/search.png');
    const [filtered, setFiltered] = React.useState([]);
    const [activeGenre , setActiveGenre] = React.useState('tout');
    const [filterVal , setFilterVal] = React.useState('');
    const [searchApiData , setSearchApiData] = React.useState([]);

    React.useEffect(() => {
      if(activeGenre === 'tout'){
        setFiltered(brandAllData);
        setSearchApiData(brandAllData)
        return;
      }
      const filtered = brandAllData.filter(brand => (
        brand.categoryType.includes(activeGenre)
      ))
      setFiltered(filtered)
      setSearchApiData(filtered)
    }, [activeGenre]);

    const handleFilter = (e) => {
      if(e.target.value === ''){
        setFiltered(searchApiData)
      }else{
        const filterResult = searchApiData.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
        setFiltered(filterResult);
      }

      setFilterVal(e.target.value);
    }

  return (
    <TabContainer>
        
        
        <TabSearchBar>
          <TabSearchIcon src={icon} alt='search icon' />
          <TabSearchInput value={filterVal} placeholder='Recherche' onInput={(e) => handleFilter(e)}/>
        </TabSearchBar>
        

      <TabHeadTitle>

        <FirestoreCollection
          path="Togo/Categories/CategoriesList"
          sort="index:asc"
          render={({isLoading , data}) => {
            return isLoading ? (
              <Skeleton variant='rounded' animation='wave' width='100%' height={16} sx={{mb: '13px'}}/>
            ) : (
              data.map(item => <button className={activeGenre === item.id ? 'active__tabs' : 'tab'} onClick={() => setActiveGenre(item.id)} key={item.id}>{item.name}</button>)
            ) 
          }}
        />

      </TabHeadTitle>

      <motion.div layout className='brand__grid__conatiner'>
            {
                filtered.map((item , index) => <Brand key={index} name={item.name} brandNameImg={item.imgLogo} bgImg={item.imgBackGround} id={item.id}/> )
            }
      </motion.div>

    </TabContainer>
  );
}

export default TabFeed;
