import React from 'react';
import { BtnContainer, BtnIcon, BtnLink, BtnText, HeadtText, ModelBottomContainer , BtnItem} from './ModelBottomTopFooter.element';
//import Utility from '../utils/variable';
import { navRef } from './NavbarFeed';

const ModelBottomTopFooter = ({link , text , secondText}) => {

    const icon = require('../images/icon2.png');

    console.log(navRef);

  return (
    <ModelBottomContainer onClick={() => console.log(navRef.current.style.backgroundColor)}>
      
      <HeadtText>
        {secondText}
      </HeadtText>

      <BtnItem onClick={() => navRef.current.style.background = "#57B793"}>
        <BtnLink to={link}>
          <BtnContainer>
              <BtnText>
                  {text}
              </BtnText>

              <BtnIcon src={icon} alt='icon'/>

          </BtnContainer>
        </BtnLink>
      </BtnItem>


    </ModelBottomContainer>
  );
}

export default ModelBottomTopFooter;
