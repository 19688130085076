import React from 'react';
import { CartContainer, CartDivider, CartEmptyContainer, CartText  , CartTabHeadContainer, CartNameProductContainer, CartHeadText, CartPriceProductContainer, CartQuantityProductContainer, CartTotalProductContainer, CartContentContainer, TotalAmountContainer, CartTotalDivider, CartFlexTotal, FirstSection, Sections, TotalAmountGlobalContainer, CartContactWhatsapp, EnptyBtnLink, EmptyBtnContainer, CartShoppingContainer} from './Cart.element';
import { navRef } from '../NavbarFeed';
import EmptyFeed from '../EmptyFeed';
import { useSelector} from 'react-redux';
import ModalCartAdd from '../ModalCartAdd';
import { handleBack } from '../../utils/function';

const Cart = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
    navRef.current.style.background = '#fff';
  }, []);

  const cartProducts = useSelector(state => state.cart.cartItems);

  const currencyFormatterWithSymbol = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' , currencyDisplay: 'symbol'});

  const totalAmount = useSelector(state => state.cart.totalAmount);

  return (
    <>
      {
        cartProducts.length === 0 ? (
          <CartEmptyContainer>
            <EmptyFeed text='Votre panier est vide'/>
          </CartEmptyContainer>
        ) : (
          <CartContainer>
              <CartText>Votre panier</CartText>
              <CartContentContainer>
              <CartDivider/>
              <CartTabHeadContainer>
                  <CartNameProductContainer>
                      <CartHeadText>Nom des produits</CartHeadText>
                  </CartNameProductContainer>  
                  <CartPriceProductContainer>
                      <CartHeadText>Prix</CartHeadText>
                  </CartPriceProductContainer>
                  <CartQuantityProductContainer><CartHeadText>Quantité</CartHeadText>
                  </CartQuantityProductContainer>
                  <CartTotalProductContainer>
                      <CartHeadText>Total</CartHeadText>
                  </CartTotalProductContainer>
              </CartTabHeadContainer>
              <CartDivider/>
                {
                  cartProducts.map((item , index) => (
                    <ModalCartAdd product={item} key={index}/>
                  ))
                }
                <CartFlexTotal>
                  <FirstSection/>
                  <Sections/>
                  <Sections/>
                  <TotalAmountGlobalContainer>
                  <TotalAmountContainer>{currencyFormatterWithSymbol.format(totalAmount)}</TotalAmountContainer>
                  </TotalAmountGlobalContainer>
                </CartFlexTotal>
                <CartTotalDivider/>
                <CartContactWhatsapp>CONTACTER VIA WHATSAPP</CartContactWhatsapp>
              </CartContentContainer>
          </CartContainer>
        )
      }

      <CartShoppingContainer>
          <EnptyBtnLink onClick={handleBack}>
             <EmptyBtnContainer>Continuer vos achats</EmptyBtnContainer>
          </EnptyBtnLink>
      </CartShoppingContainer>
      
    </>
  );
}

export default Cart;
