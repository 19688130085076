import styled from "styled-components";

export const RegisterInputAddMoreGroup = styled.div`
display: flex;
flex-direction: column;
gap: 1.2rem;
`;

export const RegisterAddMoreLabel = styled.label`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
color: #000000;
`;

export const RegisterAddMoreInputs = styled.textarea`
width: 400px;
height: 174px;
left: 664px;
top: 517px;
background: transparent;
padding: 1rem;
font-size: .9rem;
outline: none;
border: 1px solid #000000 !important;
border-radius: 10px;
box-sizing: border-box;
`;