import React from 'react';
import Glider from 'glider-js';
import { FavorisLink, ProductCategoryContainer, ProductHeadContainer, ProductScreenContainer, ProductScreenContentContainer, ProductSearchCartFavorisContainer, ProductSearchContainer, ProductSearchIcon, ProductSearchInput , FavorisIcon , CartLink, Divider, TextHeadCategoryProduct, BrandDataContainer, BrandNameContainer, RecommandedProduct, ProductsearchButton, ProductScreenContentContainerWrapper,ProductLoadingContainer, ProductGridContainer, FavorisLinks } from './ProductScreen.element';
import { Badge , Skeleton } from '@mui/material';
import { FirestoreCollection } from 'react-firestore';
import Product from '../Product';
import { navRef } from '../NavbarFeed';
import { useSelector , useDispatch } from 'react-redux';
import { addProduct , removeProduct } from '../../store/wish-list/wishListSlice';
import { Toaster, toast } from 'react-hot-toast';
import { brandAllData } from '../../data/data';
import ProductDetail from './ProductDetail';
import CircularProgressBar from '../CircularProgressBar';
import ProductRecommanded from '../ProductRecommanded';
import EmptyProductFeed from '../EmptyProductFeed';

const ProductScreen = ({db , firebase}) => {

  const icons = require('../../images/search.png'); 
  const favorisIcon = require('../../images/favoris.png');
  const cartIcon = require('../../images/cart.png');
  const prev = require('../../images/prevs.png');

  const totalQuantity = useSelector(state => state.cart.totalQuantity);

  const totalFavorisCount = useSelector(state => state.wishList.products.length);

  const favorisProducts = useSelector(state => state.wishList.products);

  const dispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);

  const [activeGenre , setActiveGenre] = React.useState(params.get('filter'));
  const [productClick , setProductClick] = React.useState(false);
  const [brandActive , setBrandActive] = React.useState(params.get('q'));
  const [product , setProduct] = React.useState(null);
  const [search , setSearch] = React.useState(null);
  const [loading , setLoading] = React.useState(false);
  const [productFiltered , setProductFiltered] = React.useState([]);
  const [recommandedProductFiltered , setRecommandedProductFiltered] = React.useState([]); 
  const [favoris , setFavoris] = React.useState([]);
  const [lastDocument , setLastdocument] = React.useState(null);

  const user = JSON.parse(localStorage.getItem('user'));

  const elementToMoveName = brandAllData.find(obj => obj.id === 'autres');

  brandAllData.sort((a , b) => a.name.localeCompare(b.name));

  const indexToRemove = brandAllData.findIndex(obj => obj.id === elementToMoveName.id);

  if(indexToRemove !== -1){
    brandAllData.splice(indexToRemove, 1);
  }

  const handleUpdateAddFavorisProductSate = (id, wishList , userId) => {

    setProductFiltered(productFiltered.map(product => {
      if (product.uid === id) {
        return { ...product, userWishList: wishList.concat(userId) };
      } else {
        return product;
      }
    }));
  };

  const handleUpdateAddFavorisProductOneSate = (id, wishList , userId) => {

    if (product.uid === id) {
      setProduct({ ...product, userWishList: wishList.concat(userId) });
    } else {
      setProduct(product);
    }
   
  };

  const handleDeleteFavorisInFavorisState = (id) => {

    setFavoris(favoris.map(product => {
        if(product.uid === id){
            return favoris.filter(obj => obj.uid !== id)
        }else{
          return product;
        }
    }))

  };

  const handleAddProductInFavorisState = (product) => {
    
    const newFavorisList = [product, ...favoris]; 
    setFavoris(newFavorisList);

  }

  const handleUpdateDeleteFavorisProductSate = (id, wishList , userId)  => {
    console.log(productFiltered)
    setProductFiltered(productFiltered.map(product => {
      if (product.uid === id) {
        return { ...product, userWishList: wishList.filter(uid => uid !== userId)}
      } else {
        return product;
      }
    }));
  };

  const handleUpdateDeleteFavorisOneProductState = (id, wishList , userId)  => {
    if (product.uid === id) {
      setProduct({ ...product, userWishList: wishList.filter(uid => uid !== userId) })
    } else {
      setProduct(product)
    }
  };

  const handleChangeState = data => {
    setProductClick(true);
    setProduct(data)

    console.log('product clicked');
  }

  brandAllData.push(elementToMoveName);

  const handleAddToWishList = (p) => {
    dispatch(addProduct(p));
  };

  const handleRemoveProductInWishList = (uid) => {
    dispatch(removeProduct(uid))
  }

  const handleFavoris = async (uid) => {
    const querySnapshot = await db
    .collection('Togo')
    .doc('Products')
    .collection('ProductList')
    .where('userWishList', 'array-contains', uid)
    .orderBy('dateCreated', 'desc')
    .get();

    const products = querySnapshot.docs.map((doc) => doc.data());

    setFavoris(products);

    if(products){

      const productUids = products.map(product => product.uid);
      const removedProducts = favorisProducts.filter(localProduct => !productUids.includes(localProduct.uid));
      removedProducts.forEach(product => handleRemoveProductInWishList(product.uid));
      products.forEach(product => handleAddToWishList(product));

      
    }

    return products;
  }
 

const handleLoadProductWithoutCategoryFilters = async (filters) => {
     const querySnapshot = await db
    .collection('Togo')
    .doc('Products')
    .collection('ProductList')
    .where('brandName', '==', filters)
    .orderBy('dateCreated', 'desc')
    .limit(35)
    .get();

    const products = querySnapshot.docs.map((doc) => doc.data());

    setLoading(false)

    setProductFiltered(products)

    setLastdocument(products.slice(-1)[0]);

    return products;
};

const handleLoadProductWithCategoryFilter = async (filters , category) => {
  const querySnapshot = await db
    .collection('Togo')
    .doc('Products')
    .collection('ProductList')
    .where('brandName', '==', category)
    .where('category' , '==' , filters)
    .orderBy('dateCreated', 'desc')
    .limit(35)
    .get();

  const products = querySnapshot.docs.map((doc) => doc.data());

  setLoading(false)

  setProductFiltered(products)

  setLastdocument(products.slice(-1)[0]);
  
  return products;
};

  React.useEffect(() => {

    window.scrollTo(0, 0);

    navRef.current.style.background = '#D9D9D9';
  

    new Glider(document.querySelector('.glider'), {
      slidesToScroll: 10,
      slidesToShow: 18,
      draggable: true,
      dots: '.dots',
      arrows: {
          prev: '.glider-prev',
          next: '.glider-next'
        }
    }); 

    if(user !== null) handleFavoris(user.uid);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);
  

  React.useEffect(() => {

    if(activeGenre === 'tout'){
      setLoading(true);
      handleLoadProductWithoutCategoryFilters(brandActive)
    }else{
      setLoading(true);
      handleLoadProductWithCategoryFilter(activeGenre , brandActive);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [activeGenre , brandActive]);

  React.useEffect(() => {
    setRecommandedProductFiltered(productFiltered.filter(product => product.recommanded))
    console.log('Enter');
    console.log(productFiltered.filter(product => product.recommanded));
  } , [productFiltered]);
  

  const handleSearchOnSubmit = event => {

    event.preventDefault();

    const Link = `/catalogue/produits/recherche?rechercher=${encodeURIComponent(search)}`;

    window.location.replace(Link);
     
  }

  const handleChange = event => {
    setSearch(event.target.value);
  }

  const favorisLink = user !== null && `/catalogue/user/favoris/${user.uid}`;

  
  return (
    <ProductScreenContainer>
        <Toaster toastOptions={{duration: 4000}}/>
        <ProductScreenContentContainer>
           <ProductHeadContainer>
             <ProductCategoryContainer>
               <FirestoreCollection
                path='Togo/Categories/CategoriesList'
                sort='index:asc'
                render={({isLoading , data}) => {
                  return isLoading ? (
                    <Skeleton variant='rounded' width={'90%'} height={'1rem'} Animation='wave'/>
                  ) : (
                      data.map(item => <TextHeadCategoryProduct big={activeGenre === item.id ? true : false} onClick={() => {
                          setActiveGenre(item.id);
                          setProductClick(false);
                          params.set('filter' , item.id);
                          const newUrl = window.location.pathname + '?' + params.toString();
                          window.history.pushState({}, '', newUrl);
                        }} key={item.id}>{item.name}</TextHeadCategoryProduct>)
                  )
                }}
               />
             </ProductCategoryContainer>
             <ProductSearchCartFavorisContainer>
               <ProductSearchContainer onSubmit={handleSearchOnSubmit}>
                  <ProductSearchInput value={search} onChange={handleChange} placeholder='Recherche'/>
                  <ProductsearchButton type='submit'>
                      <ProductSearchIcon  src={icons}  alt='Search Icon'/>
                  </ProductsearchButton>
               </ProductSearchContainer>
               {
                user !== null ? (
                  <FavorisLink to={favorisLink}>
                    <Badge  badgeContent={totalFavorisCount} color="error">
                      <FavorisIcon src={favorisIcon}/>
                    </Badge>
                  </FavorisLink>
                ) : (
                  <FavorisLinks onClick={() => toast.error('Connecter - vous pour pouvoir consulté votre liste de favoris!')}>
                    <Badge  badgeContent={0} color="error">
                      <FavorisIcon src={favorisIcon}/>
                    </Badge>
                  </FavorisLinks>
                )
               }
               <CartLink to='/catalogue/panier'>
                  <Badge  badgeContent={totalQuantity} color="error">
                     <FavorisIcon src={cartIcon}/>
                  </Badge>
               </CartLink>
             </ProductSearchCartFavorisContainer>
           </ProductHeadContainer>
           <Divider/>  
                  

          <div className='slider-btns'>
                <button aria-label="Previous" className="glider-prev"><img src={prev} width='18px' height='17.32px' alt=''/></button>
                <button aria-label="Next" className="glider-next"><img src={prev} width='18px' height='17.32px'  alt=''/></button>
          </div>      

           <BrandNameContainer>

              <div className="glider-contain">
                     <div className="glider">
                        {
                          brandAllData.map(item => <BrandDataContainer big={brandActive === item.id ? true : false} key={item.id} onClick={()=> {
                                setBrandActive(item.id);
                                setProductClick(false);
                                params.set('q' , item.id);
                                const newUrl = window.location.pathname + '?' + params.toString();
                                window.history.pushState({}, '', newUrl);
                            }}>{item.name}</BrandDataContainer>)
                       }
                    </div>
                </div>

           </BrandNameContainer>

           {
            !productClick ? (
                    
                     !loading ? (
                        productFiltered.length !== 0 ? (
                          <ProductScreenContentContainerWrapper>

                          {
                            recommandedProductFiltered.length !== 0 && <ProductRecommanded handleUpdateAddFavorisProductSate={handleUpdateAddFavorisProductSate} handleUpdateDeleteFavorisProductSate={handleUpdateDeleteFavorisProductSate} handleChangeState={handleChangeState} db={db} firebase={firebase} handleAddProductInFavorisState={handleAddProductInFavorisState} handleDeleteFavorisInFavorisState={handleDeleteFavorisInFavorisState} productFiltered={recommandedProductFiltered}/>
                          }

                          <RecommandedProduct>Parcourez les produits</RecommandedProduct>

                          <ProductGridContainer>
                              {
                                  productFiltered.map((item , index) => <Product product={item} updateAddFavorisState={handleUpdateAddFavorisProductSate} updateDeleteFavorisState={handleUpdateDeleteFavorisProductSate} key={index} changeState={handleChangeState} db={db} firebase={firebase}  addFavoris={handleAddProductInFavorisState} deleteFavoris={handleDeleteFavorisInFavorisState}/>)
                              }
                       </ProductGridContainer>
                       </ProductScreenContentContainerWrapper>
                        ) : <EmptyProductFeed/>
                     ) : (
                        
                          <ProductScreenContentContainerWrapper>
                             <ProductLoadingContainer>
                                <CircularProgressBar/>
                            </ProductLoadingContainer>
                          </ProductScreenContentContainerWrapper>
                        )

               ) : <ProductDetail product={product} db={db} firebase={firebase} addFavorisState={handleUpdateAddFavorisProductOneSate} deleteFavorisState={handleUpdateDeleteFavorisOneProductState} updateAddFavorisState={handleUpdateAddFavorisProductSate} updateDeleteFavorisState={handleUpdateDeleteFavorisProductSate} deleteFavoris={handleDeleteFavorisInFavorisState} addFavoris={handleAddProductInFavorisState}/>
           }

        </ProductScreenContentContainer>
    </ProductScreenContainer>
  );
}

export default ProductScreen;
