import React from 'react';
import { ProductDetailContainer, ProductDetailGlobalContainer, ProductDetailLeftContainer, ProductHeadLeftPathText, ProductFinanceText, ProductImgContainer, ProductDetailImgSelect, ProductImgsContainer, ProductDetailImgContainer, ProductDetailImg, ProductDetailRightContainer, ProductDetailNameText, ProductDetailPriceText, ProductDetailText, ProductDetailAddCartContainers, ProductDetailQuantityContainer, ProductDetailQuantityOption, ProductDetailQuantyText, ProductDetailFavorisContainer, ProductDetailLikeContainer, ProductDetailFavorisImg, ProductDetailFavorisText , ProductDetailAddCartContainer, ProductDetailProgressContainer, ProductContactWhatContainer } from './ProductDetail.element';
import { useDispatch , useSelector } from 'react-redux';
import { addToCart } from '../../store/shopping-cart/cartSlice';
import { addProduct , removeProduct } from '../../store/wish-list/wishListSlice';
import { toast } from 'react-hot-toast';
import AccordionProductDesc from '../AccordionProductDesc';
import CircularIndeterminate from '../CircularIndeterminate';
import ReactWhatsapp from 'react-whatsapp';

const ProductDetail = ({product , db , firebase , deleteFavoris , addFavoris , updateDeleteFavorisState , updateAddFavorisState , addFavorisState , deleteFavorisState}) => {

  const like = require('../../images/heart.png');
  const hearth = require('../../images/favoris.png');

  const dispatch = useDispatch()

  const cartItems = useSelector(state => state.cart.cartItems);

  const path = `${product.brandName.replace(/\b\w/g, c => c.toUpperCase())} | ${product.category.replace(/\b\w/g, c => c.toUpperCase())} | ${product.name.replace(/\b\w/g, c => c.toUpperCase())}`

  const finance = product.finance && <ProductFinanceText>Financé</ProductFinanceText>

  const [imgUrl , setImgUrl] = React.useState(product.img[0]);
  const [quantity , setQuantity] = React.useState(1);
  const [favoris , setFavoris] = React.useState(false);
  const [loading , setloading] = React.useState(false);

  const likeIcon = favoris ? like : hearth;

  const favorisText = !favoris ? 'Ajouter Au Favoris' : 'Retiré de votre liste de favoris';

  const currencyFormatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' , currencyDisplay: 'symbol'});

  const user = JSON.parse(localStorage.getItem('user'));
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    if(user !== null){
      if(product.userWishList.includes(user.uid)){
        setFavoris(true);
      }else{
        setFavoris(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  

  const fieldName = 'userWishList';

    const deleteSellerInWishList = async element => {await db.collection('Togo').doc('Products').collection('ProductList').doc(product.uid).update({[fieldName]: firebase.firestore.FieldValue.arrayRemove(element)})};

    const addSellerWishList  = async element => {await db.collection('Togo').doc('Products').collection('ProductList').doc(product.uid).update({[fieldName]: firebase.firestore.FieldValue.arrayUnion(element)})};

    const handleAddToWishList = () => {
      dispatch(addProduct(product));
    };
  
    const handleRemoveFromWishList = () => {
      dispatch(removeProduct(product.uid));
    };

    const handleWishListContainerClick = () => {

      setloading(true)
      
      if(product.userWishList.includes(user.uid)){
         const index = product.userWishList.indexOf(user.uid);
          if(index !== -1){
            product.userWishList.splice(index, 1);
            deleteSellerInWishList(user.uid)
              .then(() => {
                updateDeleteFavorisState(product.uid , product.userWishList , user.uid);
                setFavoris(false);
                deleteFavorisState(product.uid , product.userWishList , user.uid)
                handleRemoveFromWishList();
                deleteFavoris(product.uid);
                setloading(false);
                toast.success('Produit Retiré de votre favoris');
              })
              .catch(error => {
                console.log(error);
                setloading(false)
                toast.error("Une erreur c'est produite");
              })
          }
      }else{
          addSellerWishList(user.uid)
            .then(() => {
              setFavoris(true);
              updateAddFavorisState(product.uid , product.userWishList , user.uid);
              handleAddToWishList();
              addFavorisState(product.uid , product.userWishList , user.uid)
              addFavoris(product);
              setloading(false);
              toast.success('Produit Ajouté à votre favoris.');
            })
            .catch(error => {
              console.log(error);
              setloading(false)
              toast.error("Une erreur c'est produite");
            })
      }
    
      
  }

  const handleIncrementQuantity = () => {
    setQuantity(quantity + 1);
  }

  const handleDecrementQuantity = () => {
    if(quantity > 1) setQuantity(quantity - 1);
  }

  const insideCart = cartItems.some(p => p.uid === product.uid);

  const handleAddToCart = () => {
        
    if(insideCart){
      toast.success('Déja dans le panier')
    }else{
      toast.success('Produit ajouté au panier!')
    }


    const {uid , name , productDesc , minPrice , maxPrice , img} = product;

    const image = img[0];

    const totalPrice = maxPrice;

    dispatch(addToCart({uid , name , productDesc , minPrice , maxPrice , image , totalPrice}));

}

  return (
    <ProductDetailGlobalContainer>

      <ProductHeadLeftPathText>{path}</ProductHeadLeftPathText>

      <ProductDetailContainer>

          <ProductDetailLeftContainer>

            <ProductImgContainer>

              {finance}

              <ProductDetailImgSelect src={imgUrl} loading='lazy'/>

              {
                product.img.length > 1 ? (
                  <ProductImgsContainer>
                     {
                       product.img.map((url , index) => <ProductDetailImgContainer key={index} onClick={() => setImgUrl(url)}>
                          <ProductDetailImg src={url} loading='lazy'/>
                       </ProductDetailImgContainer>)
                     }
                  </ProductImgsContainer>
                ) : <ProductImgsContainer/>
              }

            </ProductImgContainer>

          </ProductDetailLeftContainer>

          <ProductDetailRightContainer>

              <ProductDetailNameText>{product.name}</ProductDetailNameText>
              
              <ProductDetailPriceText>{currencyFormatter.format(product.minPrice)} - {currencyFormatter.format(product.maxPrice)}</ProductDetailPriceText>

              <ProductDetailText>Payer en 2 fois sans frais. Disponible pour toute commande supérieure à 40 000 CFA.</ProductDetailText>

              <ProductDetailAddCartContainers>
                <ProductDetailQuantityContainer>
                  <ProductDetailQuantityOption onClick={handleDecrementQuantity}>-</ProductDetailQuantityOption>
                  <ProductDetailQuantyText>{quantity}</ProductDetailQuantyText>
                  <ProductDetailQuantityOption onClick={handleIncrementQuantity}>+</ProductDetailQuantityOption>
                </ProductDetailQuantityContainer>

                <ProductDetailAddCartContainer onClick={handleAddToCart}>Ajouter au panier</ProductDetailAddCartContainer>

              </ProductDetailAddCartContainers>

              <ProductDetailFavorisContainer>

                  {
                    user !== null ? (
                      !loading ? (
                        <ProductDetailLikeContainer onClick={handleWishListContainerClick}>
                            <ProductDetailFavorisImg src={likeIcon}/>
                        </ProductDetailLikeContainer>
                      ) : (
                         <ProductDetailProgressContainer>
                            <CircularIndeterminate size={25}/>
                         </ProductDetailProgressContainer>
                      )
                    ) : (
                            <ProductDetailLikeContainer onClick={() => toast.error('Connecté vous pour ajouté un produit a votre liste de favoris!')}>
                                  <ProductDetailFavorisImg src={likeIcon}/>
                            </ProductDetailLikeContainer>
                    )
                  }

                  <ProductDetailFavorisText>{favorisText}</ProductDetailFavorisText>

              </ProductDetailFavorisContainer>

              <AccordionProductDesc contentText={product.description}/>

              <ProductContactWhatContainer>
                <ReactWhatsapp number='+22892111128' message='' className='react__product__whatsapp'>Contacter via whatsapp</ReactWhatsapp>
              </ProductContactWhatContainer>

          </ProductDetailRightContainer>

      </ProductDetailContainer>
      
    </ProductDetailGlobalContainer>
  );
}

export default ProductDetail;
