import styled from "styled-components";
import { Container } from "../globalStyles";

export const ROLContainer = styled(Container)`
display: flex;
flex-direction: row;
margin-top: 9.375rem;
justify-content: center;

@media screen and (max-width: 960px) {
    width: 100%;
    margin-bottom: 3.125rem;
    margin-top: .938rem;
}
`;

export const ROLLeftContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 40%;

@media screen and (max-width: 960px) {
    width: 46%;
    align-items: start;
}

@media only screen and (min-width: 140px) and (max-width: 1700px) {
    margin-right: ${({position}) => (position ? '5rem' : '')};
}
`;


export const ROLRightContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width:40%;

@media screen and (max-width: 960px) {
    align-items: start;
    width: 46%;
}

@media only screen and (min-width: 140px) and (max-width: 1700px) {
    margin-right: ${({position}) => (!position ? '5rem' : '')};
}
`;

export const ROLImg = styled.img`
border-radius: 3.125rem;
width: 31.063rem;
height: 21.875rem;

@media screen and (max-width: 960px) {
    border-radius: 3.125rem;
    width: 9.375rem;
    height: 6.875rem;
    border-radius: .938rem;
}
`;

export const ROLHeadText = styled.h4`
font-style: normal;
font-weight: 700;
font-size: 1.875rem;
line-height: 138.4%;
width: 34.188rem;
align-self: top;
margin-bottom: 3.125rem;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: .75rem;
    width: 9.688rem;
    margin-bottom: .625rem;
}
`;

export const ROLDesc = styled.p`
font-style: normal;
font-weight: 500;
font-size: 1.125rem;
line-height: 145%;
color: #000000;
width: 34.188rem;

@media screen and (max-width: 960px) {
    width: 9.375rem;
    font-size: .313rem;
    font-weight: 300;
}
`;