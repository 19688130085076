import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./shopping-cart/cartSlice";
import wishListReducer from "./wish-list/wishListSlice";
import navbarSlice from "./navbar-state/navbarSlice";

const store = configureStore({
    reducer: {
        cart: cartSlice.reducer,
        wishList: wishListReducer,
        navbarState: navbarSlice.reducer,
    }
});

export default store;