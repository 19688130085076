import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../globalStyles";

export const FooterGlobalContainer = styled.div`
width: 100%;
height: 45vh;
background: #000;
overflow-x: hidden;

@media screen and (max-width: 960px) {
    height: calc(100vh - 1.063rem); /* ajuster la valeur en fonction de la taille de la barre de défilement */
    overflow-y: auto;
}
`;

export const FooterContentContainer = styled(Container)`
display: flex;
flex-direction: column;

${Container}
`;

export const LinkItem = styled.div`
display: flex;
`

export const FooterContentHeadContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
margin-bottom: 3.25rem;
margin-top: 6.188rem;

@media screen and (max-width: 960px) {
    margin-top: 3.125rem;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.563rem;
}
`;

export const FooterContentHeadTextContainer = styled.div`
width: 50%;
display: flex;
flex-direction: column;


@media screen and (max-width: 960px) {
    margin-top: 3.125rem;
    flex-direction: column;
}
`;

export const FooterContentHeadCountryContainer = styled.div`
width: 50%;
display: flex;
flex-direction: column;
`;

export const FooterContentHeadSelectCountryContainer = styled.div`
display: flex;
flex-direction: row;
height: 3.125rem;
width: 12.5rem;
background: #252525;
border-radius: 1.25rem;
align-self: flex-end;
padding: 0 1.3rem;
cursor: pointer;

@media screen and (max-width: 960px) {
    height: 3.125rem;
    width: 18.75rem;
    align-self: center;
    margin-top: 1.875rem;
}
`;


export const FooterTextDesc = styled.p`
width: 100%;
font-style: normal;
font-weight: 300;
font-size: .75rem;
line-height: 1.25rem;
text-align: justify;
color: #B5B5B5;
margin-bottom: 2.625rem;
`;

export const FooterBottomContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
@media screen and (max-width: 960px) {
    flex-direction: column;
}
`;


export const 
FooterBottomLeftContainer = styled.div`
width: 85%;
flex-direction: row;
display: flex;

@media screen and (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
`;

export const FooterBottomRightContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 25%;

@media screen and (max-width: 960px) {
    width: 100%;
    align-items: center;
    margin-top: 2rem;
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35%;
}
`;

export const ImgFlagFooter = styled.img`
width: 1.125rem;
height: .859rem;
align-self: center;
`;


export const ImgSelectFooter = styled.img`
width: .938rem;
height: .606rem;
color-scheme: #fff;
display: flex;
margin-left: 4.813rem;
align-self: center;

@media screen and (max-width: 960px) {
    margin-left: 11.25rem;
}
`;

export const TextHeadOne = styled.p`
font-style: normal;
font-weight: 300;
font-size: 1.25rem;
margin-bottom: .31rem;
line-height: 1.25rem;
color: #FFFFFF;


@media screen and (max-width: 960px) {
    margin-bottom: 1rem;
}
`;

export const LinkMail = styled.a`
cursor: pointer;
border: none;
outline: none;
text-decoration: none;
`;

export const TextHeadTwo = styled.p`
font-style: normal;
font-weight: 200;
font-size: 0.75rem;
line-height: 0.75rem;
margin-top: .31rem;
margin-bottom: .31rem;
color: #B5B5B5;
text-align: justify;
`;

export const TextCountrySelect =  styled.p`
font-style: normal;
font-weight: 500;
font-size: .875rem;
line-height: 1.25rem;
color: #FFFFFF;
align-self: center;
margin-left: .58rem;
`;

export const LinkFooter = styled(Link)`
border: none;
outline: none;
text-decoration: none;
cursor: pointer;
margin-right: 2.8rem;

&:hover{
    text-decoration: underline;
    text-decoration-color: #fff;
}

@media screen and (max-width: 960px) {
    margin-right: 0px;
    margin-bottom: .4rem;
    font-size: .75rem;
}
`;

export const LinkCCM = styled(Link)`
cursor: pointer;
font-style: normal;
font-weight: 300;
font-size: .75rem;
line-height: 1.25rem;
text-align: justify;
color: #B5B5B5;
text-decoration: underline;
text-decoration-color: #B5B5B5;
`

export const TextBottomFooter = styled.p`
font-style: normal;
font-weight: 400;
font-size: .875rem;
line-height: 1.063rem;
color: #B5B5B5;
margin-right: 2.8rem;

@media screen and (max-width: 960px) {
    margin-right: 0px;
    margin-bottom: .4rem;
    font-size: .75rem;
}
`;

export const TextBottomLinkFooter = styled.p`
font-style: normal;
font-weight: 400;
font-size: .875rem;
line-height: 1.063rem;
color: #B5B5B5;

@media screen and (max-width: 960px) {
    font-size: .75rem;
}
`;


export const TextBottomRightFooter = styled.p`
font-style: normal;
font-weight: 400;
font-size: .875rem;
line-height: 1.063rem;
color: #B5B5B5;
margin-right: 2.4rem;

@media screen and (max-width: 960px) {
    font-size: .75rem;
    margin-right: 1.4rem;
}
`;


export const IconFb = styled.img`
width: 1.368rem;
height: 1.368rem;
margin-right: 2.258rem;
cursor: pointer;

@media screen and (max-width: 960px) {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1.1rem;
}
`;

export const IconIn = styled.img`
width: 1.368rem;
height: 1.368rem;
display: flex;
align-self: flex-end;
cursor: pointer;

@media screen and (max-width: 960px) {
    width: 1.2rem;
    height: 1.2rem;
}
`;



