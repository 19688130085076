import React from 'react';
import { HomePayContainer  , HomePayLeftContentContainer , HomePayRightBottomContainer, HomePayRightContentContainer, OneImg, SecondImg, TopImg , HomePayRightTrueContentContainer , RightHeadTextContainer, RightTopHeadText, IconPay, BottomHeadText ,HomePayRightTrueContentContainers} from './HomePaymentScetion.element';

const HomePaymenentSection = () => {

    const icon = require('../images/icon.png');
    const homePay1 = require('../images/home-pay1.png')
    const homePay2 = require('../images/home-pay2.png');
    const homePay3 = require('../images/home-pay3.png');


  return (
    <HomePayContainer>
        
        <HomePayLeftContentContainer>

            <TopImg src={homePay1} alt='logo'/>

            <HomePayRightBottomContainer>

                <OneImg src={homePay2} alt='logo' />
                <SecondImg src={homePay3} alt='logo' />

            </HomePayRightBottomContainer>

        </HomePayLeftContentContainer>

        <HomePayRightContentContainer>

            <HomePayRightTrueContentContainer>

                <RightHeadTextContainer>

                    <RightTopHeadText>
                    Fractionnez vos paiements
                    </RightTopHeadText>

                    <IconPay src={icon} alt='Icon' />

                </RightHeadTextContainer>

                <BottomHeadText>
                Achetez dans vos magasins préférés, et répartissez vos paiements dans le temps.
                </BottomHeadText>

            </HomePayRightTrueContentContainer>

            <HomePayRightTrueContentContainers>

                <RightHeadTextContainer>

                    <RightTopHeadText>
                    Pas de tracas, pas de stress
                    </RightTopHeadText>

                    <IconPay src={icon} alt='Icon' />

                </RightHeadTextContainer>

                <BottomHeadText>
                Oubliez les fastidieux processus d'approbation ! Inscrivez-vous et recevez vos commandes en seulement 3 jours.
                </BottomHeadText>

            </HomePayRightTrueContentContainers>

            <HomePayRightTrueContentContainer>

                <RightHeadTextContainer>

                    <RightTopHeadText>
                    Pas de frais cachés
                    </RightTopHeadText>

                    <IconPay src={icon} alt='Icon' />

                </RightHeadTextContainer>

                <BottomHeadText>
                 C'est vrai - il n'y a pas de piège. Pas de frais<br/> cachés .
                </BottomHeadText>

            </HomePayRightTrueContentContainer>

        </HomePayRightContentContainer>
      
    </HomePayContainer>
  );
}

export default HomePaymenentSection;
