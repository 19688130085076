import React from 'react';
import { RegisterInputGroup, RegisterLabel  , RegisterInputs, ErrorMessage} from './RegisterInput.element';

const RegisterInput = ({id, label , type , register , errorMessage}) => {

  const registerInput = (id === 'phoneNumber') ? (<RegisterInputs id={id} type={type} placeholder='Ex: +22890101010' {...register}/>) : (<RegisterInputs id={id} type={type} {...register}/>);

  return (
    <RegisterInputGroup>
        <RegisterLabel htmlFor={id}>{label}</RegisterLabel>
        {registerInput}
        <ErrorMessage>{errorMessage}</ErrorMessage>
    </RegisterInputGroup>
  );
}

export default RegisterInput;
