import styled from "styled-components";

export const WishListEmptyContainer = styled.div`
width: 100%;
height: 26.56rem;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;

export const WishListContentGlobalContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center; 
overflow: hidden;
`;

export const WishListText = styled.h4`
width: 440px;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 37px;
text-align: center;
color: #000000;
margin-bottom: 32.5px;
margin-top: 100px;
`;

export const WishListContentContainer = styled.div`
width: 1250px;
display: flex;
flex-direction: column;
margin-bottom: 50px;
`;

export const WishListTabHeadContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
height: 66.5px;
`;

export const WishListHeadContentContainer = styled.div`
height: 100%;
flex: 1;
display: flex;
align-items: center;
`;

export const WishListHeadText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
text-align: center;
color: #000000;
`;

export const WishListDivider = styled.div`
width: 100%;
height: 0px;
border: 1px solid #F7F7F7 !important;
`;

export const WishListShoppingContainer = styled.div`
width: 100%;
display: flex;
background: #fff;
align-items: center;
justify-content: center;
height: auto;
margin-bottom: 150px;
`;

export const EnptyBtnLink = styled.div`
width: 250px;
height: 50px;
text-decoration: none;
cursor: pointer;
`;

export const EmptyBtnContainer = styled.div`
width: 100%;
height: 100%;
border: 1px solid #000000 !important;
display: flex;
align-items: center;
justify-content: center;
border-radius: 30px;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #000000;
`;




