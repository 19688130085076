import React from 'react';
import { PercentContentContainer, PercentHeadText, PercentSecondHeadDesc, PercentSecondHeadText, PercentSectionContainer } from './CommercialPercentSection.element';

const CommercialPercentSection = () => {
  return (
    <PercentSectionContainer>
            
            <PercentContentContainer>

                <PercentHeadText>
                    +20%
                </PercentHeadText>

                <PercentSecondHeadText>
                    Augmentation de la conversion des ventes
                </PercentSecondHeadText>

                <PercentSecondHeadDesc>
                    Inciter davantage de clients à finaliser leur achat,
                </PercentSecondHeadDesc>    

            </PercentContentContainer>

            <PercentContentContainer>

                <PercentHeadText>
                    60%
                </PercentHeadText>

                <PercentSecondHeadText>
                    Augmentation de la fidelisation des clients
                </PercentSecondHeadText>

                <PercentSecondHeadDesc>
                    Créer une relation de confiance avec vos clients et les fidéliser.
                </PercentSecondHeadDesc>    

            </PercentContentContainer>

            <PercentContentContainer>

                <PercentHeadText>
                    +45%
                </PercentHeadText>

                <PercentSecondHeadText>
                    Augmentation de la mise en avant de la marque
                </PercentSecondHeadText>

                <PercentSecondHeadDesc>
                    Permettre de se démarquer de ses concurrents 
                </PercentSecondHeadDesc>    

            </PercentContentContainer>

    </PercentSectionContainer>
  );
}

export default CommercialPercentSection;
