import styled from "styled-components";
import { Container} from "../../globalStyles";

export const TermesContainer = styled(Container) `
display: flex;
flex-direction: column;

${Container}
`

export const TermesHeadText = styled.h4`
width: 953px;
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
text-align: center;
color: #000000;
align-self: center;
margin: 57.5px;
`;

export const TermesDivider = styled.div`
width: 1200px;
height: 2px;
background-color: #F7F7F7;
align-self: center;
margin-bottom: 69.5px;
`;

export const TermesUpdateContent = styled.p`
font-style: italic;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #000000;
width: 1079px;
align-self: center;
margin-bottom: 1.98rem;
`

export const TermesText = styled.p`
align-self: center;
width: 1079px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 140%;
margin-bottom: 9.5rem;

color: #000000;
`;