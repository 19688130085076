import styled from "styled-components";


export const OverLayProduct = styled.div`
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 250px;
flex-direction: row;
position: absolute;
top: 0;
right: 0;
left: 0;
opacity: 0;
transition: opacity 0.4s ease-in-out ;
`;

export const ProductContainer = styled.div`
//width: 320px;
height: auto;
position: relative;
overflow: hidden;
cursor: pointer;
margin-bottom:50px;
display: flex;
flex-direction: column;
text-decoration: none;

&:hover ${OverLayProduct}{
    opacity: 1;
    background: rgba(0, 0, 0, 0.1);  
}
`;

export const ProductHeadContainer = styled.div`
width: 100%;
height: 250px;
display: flex;
justify-content: center;
flex-direction: column;
background-color: #fff;
`;

export const ProductFinance = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 200;
font-size: 9px;
line-height: 11px;
color: rgba(0, 0 , 0 , .5);
position: absolute;
top: 17px;
left: 17px;
z-index: 1;
`;


export const AddWishListContainer = styled.div`
background: #fff;
width:35px;
height: 35px;
border-radius: 100px !important;
display: flex;
align-items: center;
margin-right: 1rem;
justify-content: center;
`;

export const AddCartContainer = styled.div`
background: #fff;
width:35px;
height: 35px;
border-radius: 50px;
display: flex;
align-items: center;
cursor: pointer;
justify-content: center;
`;

export const AddIcon = styled.img`
width: 20px;
height: 20px;
object-fit: cover;
`;

export const AddProgress = styled.div`
width: 20px;
height: 20px;
display: none;
`;

export const ProductImg = styled.img`
max-width: 236.24px;
max-height: 200px;
align-self: center;
object-fit: cover;
border: none;
`;

export const ProductNameText = styled.p`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
     overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 17px;
    color: #000000;
`;

export const ProductNameTextContainer = styled.div`
  max-height: calc(1 * 1.2em); 
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 10px;
`;

export const ProductNamePrice = styled.p`
font-style: normal;
font-weight: 400;
font-size: 14px;
margin-top: 10px;
line-height: 17px;
color: #000000;
`;