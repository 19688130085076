import React from 'react';
import { useState } from 'react';
import { AccordionContainer } from './Accordion.element';
import AccordionContent from './AccordionContent';

const Accordion = ({items}) => {

    const [active , setActive] = useState();

    const handleClick =  (question) => {
        setActive(question === active ? null : question);
    }

  return (
    <AccordionContainer>
      {items.map(item => {
        let isActive = active === item.question;
        return <AccordionContent onClick={() => handleClick(item.question)}
            itemName={item.question}
            itemContent={item.answer}
            isActive={isActive}
        />
      })}
    </AccordionContainer>
  );
}

export default Accordion;
