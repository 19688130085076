import React from 'react';
import { CCMROLContainer, CCMROLDesc, CCMROLHeadText, CCMROLImg, CCMROLStep , CCMROLLeftContentContainer  , CCMROLRightContentContainer} from './CCMRightOrLeftSection.element';

const CCMRightOrLeftSection = ({position , text1 , text2 , text3 , image}) => {

  return (
    <>
      
      {(position === true) ?  (
         <CCMROLContainer>
      <CCMROLLeftContentContainer position>
       <CCMROLImg src={image} alt='logo' />
    </CCMROLLeftContentContainer> 
    
    <CCMROLRightContentContainer >

       <CCMROLStep>
         {text1}
       </CCMROLStep>     

      <CCMROLHeadText>
        {text2}
      </CCMROLHeadText>

      <CCMROLDesc>
        {text3}
      </CCMROLDesc>
    </CCMROLRightContentContainer></CCMROLContainer>) : 

          (<CCMROLContainer>
    <CCMROLRightContentContainer>
            
        <CCMROLStep>
            {text1}
       </CCMROLStep>

       <CCMROLHeadText>
        {text2}
      </CCMROLHeadText>

      <CCMROLDesc>
        {text3}
      </CCMROLDesc>
    </CCMROLRightContentContainer>

    <CCMROLLeftContentContainer>
       <CCMROLImg src={image} alt='logo' />
    </CCMROLLeftContentContainer> 

    </CCMROLContainer>)
        }

    </>
  );
}

export default CCMRightOrLeftSection;
