import styled from "styled-components";
import { Container } from "../globalStyles";

export const BRContainer = styled(Container)`
display: flex;
overflow: hidden;
flex-direction: column;
//margin: 0px 0px 50px 27px;
overflow: hidden;

${Container}
`;

export const PrevNextBtnContainer = styled.div`
display: flex;
justify-content: flex-start;
width: 100%;

`;

export const BRBContainer = styled.div`
display: flex;
margin: 0rem 3.438rem 0rem 0rem;
overflow: hidden;
`;