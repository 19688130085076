import styled from "styled-components";
import { Container } from "../globalStyles";

export const CCMROLContainer = styled(Container)`
display: flex;
flex-direction: row;
justify-content: center;
margin-bottom: 6.25rem;

@media screen and (max-width: 960px) {
    width: 100%;
    margin-bottom: 2.5rem;
}
`;

export const CCMROLLeftContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 40%;

@media screen and (max-width: 960px) {
    width: 46%;
    align-items: start;
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
margin-right: ${({position}) => (position ? '3rem' : '')};
}
`;


export const CCMROLRightContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width:40%;

@media screen and (max-width: 960px) {
    align-items: start;
    width: 46%;
}

@media only screen and (min-width: 1347px) and (max-width: 1449px) {
    margin-right: ${({position}) => (position ? '' : '2rem')};;
}
`;

export const CCMROLImg = styled.img`
border-radius: 3.125rem;
width: 31.063rem;
height: 21.875rem;

@media screen and (max-width: 960px) {
    border-radius: 3.125rem;
    width: 9.375rem;
    height: 6.875rem;
    border-radius: .938rem;
}
`;

export const CCMROLHeadText = styled.h4`
font-style: normal;
font-weight: 700;
font-size: 2.25rem;
line-height: 138.4%;
width: 34.188rem;
align-self: top;
margin-bottom: 1.875rem;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: .75rem;
    width: 9.688rem;
    margin-bottom: .625rem;
}
`;

export const CCMROLStep = styled.h5`
font-style: normal;
font-weight: 600;
font-size: 1.875rem;
line-height: 138.4%;
width: 34.188rem;
color: #000000;
margin-bottom: 1.25rem;

@media screen and (max-width: 960px) {
    font-size: .688rem;
    width: 6.25rem;
    margin-bottom: .438rem;
}
`;

export const CCMROLDesc = styled.p`
font-style: normal;
font-weight: 500;
font-size: 1.125rem;
line-height: 145%;
color: #000000;
width: 34.188rem;

@media screen and (max-width: 960px) {
    width: 9.375rem;
    font-size: .438rem;
    font-weight: 300;
}
`;