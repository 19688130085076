import styled from "styled-components";
import { Link } from 'react-router-dom';

export const ModelBottomContainer = styled.div`
width: 100%;
height: 566px;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
background: rgba(245, 245, 245, 0.5);

@media screen and (max-width: 960px) {
    height: 220px;
    background: rgba(245, 245, 245, 0.8);
}
`;

export const HeadtText  = styled.h4`
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
text-align: center;
color: #000000;
width: 777px;
margin-bottom: 45px;

@media screen  and (max-width: 960px) {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 17px;
    width: 290px;
}
`;

export const BtnContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
height: 100%;
align-items: center;
justify-content: center;

`;

export const BtnText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

text-align: center;

color: #000000;

@media screen and (max-width: 960px) {
    font-size: .6rem;
    font-weight: 400;
    line-height: 10px;
}

`;

export const BtnItem = styled.div`
display: flex;
`;

export const BtnIcon = styled.img`
width: 20px;
height: 20px;
color: #000000;
margin-left: 8px;

@media screen and (max-width: 960px) {
    width: 10px;
    height: 10px;
    margin-left: 4px;
    margin-top: 2px;
}
`;

export const BtnLink = styled(Link)`
display: flex;
text-decoration: none;
height:50px;
width: 170px;
border: none;
outline: none;
border: 1px solid #000000 !important;
border-radius: 30px;
cursor: pointer;

@media screen and (max-width: 960px){
    height:30px;
    width: 90px;
}
`;



