import React,{useState , useEffect} from 'react';
import { Img , Nav, NavbarContainer, NavLogo , MobileIcons, NavMenu, NavItem, NavLinks, NavLinks1 , NavLinks2 , NavLinks3, NavItemBtn, NavBtnLink, Button, NavItems, NavCCMtext, NavText, NavDivWrapper} from './NavbarFeed.elements';
import {BiMenuAltRight} from "react-icons/bi";
import {AiOutlineClose} from "react-icons/ai";
import { IconContext } from 'react-icons/lib';
import { useSelector , useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../store/navbar-state/navbarSlice';


export let navRef;


const NavbarFeed = () => {

  const[click , setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  }

  const [button , setButton] = useState(true);

  const itemClick = useSelector(state => state.navbarState.navBarItemClick)

  const dispatch = useDispatch();

  navRef = React.useRef(null);

  const loginButton = React.useRef();

  const showButton = () => {
    if (window.innerWidth <= 960){
      setButton(false)
    }else{
      setButton(true)
    }
  }

  useEffect(() => {
    showButton();
  }, []);
  

  window.addEventListener('resize' , showButton)

  const img = require('../images/logo.png');
  const alt = 'Logo';
  

  return (
    <>
      <IconContext.Provider value={{color: '#000'}}>
      <Nav ref={navRef}>
        <NavbarContainer onClick={handleClick} >

            <NavLogo  to="/"  onClick={() => {
              navRef.current.style.background = "#fff";
              dispatch(changeNavBarItemClicked('home'));
            }}>
              <Img src={img} alt={alt}/>
              {/*<LogoText>momo</LogoText>*/}
            </NavLogo>

            <NavDivWrapper onClick={() => {
                navRef.current.style.background = "#9F2EA1";
                dispatch(changeNavBarItemClicked('ccm'));
              }}>
            <NavCCMtext to='/ccm'>
               <NavText big={itemClick === 'ccm' ? "big" : undefined}>Comment ça marche</NavText>
            </NavCCMtext>
            </NavDivWrapper>

            <MobileIcons onClick={handleClick}>{click ? <AiOutlineClose/> : <BiMenuAltRight/>}</MobileIcons>

            <NavMenu onClick={handleClick} click={click}>

            <NavItems onClick={() => {
                navRef.current.style.background = "#9F2EA1";
              }}>
              <NavLinks to='/ccm'>Comment ça marche</NavLinks>
            </NavItems>

              <NavItem onClick={() => {
                navRef.current.style.background = "#B7A857"
                dispatch(changeNavBarItemClicked('plm'));
              }}>
                <NavLinks1 big={itemClick === 'plm' ? "big" : undefined} to='/marchand'>Pour les marchands</NavLinks1>
              </NavItem>

              <NavItem onClick={() => {
                navRef.current.style.background = "#57B793"
                dispatch(changeNavBarItemClicked('ct'));;
              }}>
                <NavLinks2 big={itemClick === 'ct' ? "big" : undefined } to='/catalogue'>Catalogue</NavLinks2>
              </NavItem>

              <NavItem onClick={() => {
                navRef.current.style.background = "#fff"
                dispatch(changeNavBarItemClicked('faq'));
              }}>
                <NavLinks3 big={itemClick === 'faq' ? "big" : undefined} to='/faq'>FAQ</NavLinks3>
              </NavItem>

              <NavItemBtn onClick={() => {navRef.current.style.background = "#fff"}}>
                {
                  button ? (
                    <NavBtnLink to="/connexion">
                      <Button ref={loginButton}>Connexion</Button>
                    </NavBtnLink>
                  ) : (
                     <NavBtnLink to='/connexion'>
                        <Button fontBig>
                          Connexion
                        </Button>
                     </NavBtnLink>  
                  )
                }
              </NavItemBtn>

            </NavMenu>

        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  );
}

export default NavbarFeed;
