import { Link } from "react-router-dom";
import styled from "styled-components";

export const BrandContainer = styled.div`
width: 100%;
height: 260px;
box-sizing: border-box;
background: #FFFFFF;
border: 1px solid #F1F1F1 !important;
border-radius: 20px;
position: relative;
overflow: hidden;
display: flex;
overflow: hidden;
flex-direction: column;
`;

/*export const BrandLinkWrapper = styled(Link)`
display: flex;
cursor: pointer;
text-decoration: none;
width: 95%;
display: flex;
align-items: center;
`;*/

export const BrandLinkWrappers = styled.div`
display: flex;
text-decoration: none;
width: 95%;
display: flex;
cursor: default !important;
align-items: center;
`;

export const BrandBgContainer = styled.div`
width: 100%;
height: 200px;
display: flex;
`;

export const BrandImgBg = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 20px 20px 0px 0px;
`;

export const BrandImgNameContainer = styled.div`
background: rgba(0, 0, 0, 0.15);
border-radius: 20px 20px 0px 0px;
position: absolute;
top: 0;
left: 0;
right: 0;
z-index: 5;
width: 100%;
height: 200px;
display: flex;
align-items: center;
justify-content: center;
`;

export const BrandNameImg = styled.img`
max-height: 90px;
width: 221px;
object-position: center;
object-fit: contain;
`;

export const BrandNameText = styled.p`
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
margin-top: 19px;
margin-left: 57px;
margin-bottom: 19px;
`;