import styled from "styled-components";

export const AccordionProductContainer = styled.div`
overflow: hidden;
width: 35.75rem;
color: #000;
`;

export const AccordionProductInner = styled.div`
position: absolute;
`;

export const AccordionProductHeader = styled.button`
display: flex;
justify-content: space-between;
width: 100%;
margin-bottom: 2.5rem;
text-align: left;
appearance: none !important;
border-bottom: .063rem solid rgba(0 , 0 , 0 , .45) !important;
outline: none !important;

background-color: transparent;
cursor: pointer;
`;

export const AccordionProductHeaderIcon = styled.img`
transform: rotate(${props => props.isActive ? -180 : 0}deg);
transition: all .2s;
width: 1.25rem;
height: .808rem;
margin-top: .313rem;
`;

export const AccordionProductContent = styled.div`
position: relative;
overflow: hidden;
height: ${props => {
    const inner = document.getElementById(props.itemName);
    return `${props.isActive && inner ? (inner.clientHeight)/16 : 0}rem`;
}};
transition: height .35s;
`;

export const AccordionProductContainers = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 2rem;
`;

export const AccordionProductItemName = styled.h4`
font-style: normal;
font-weight: 500;
font-size: 1.375rem;
font-family: 'Montserrat';
line-height: 1.688rem;
color: #000000;
margin-bottom: .688rem;
`;


export const AccordionProductContentText = styled.p`
font-style: normal;
font-weight: 400;
font-size: 1.125rem;
line-height: 140%;
width: 37.75rem;
font-family: 'Montserrat';
text-align: justify;
color: #000000;
`;



