import styled from "styled-components";
import { Container } from "../../globalStyles";

export const CommercialHeadContainer = styled.div`
width: 100%;
height: 420px;
background: #B7A857;
overflow: hidden;

@media screen and (max-width: 960px) {
    height: 180px;
}

`;

export const CommercialHeadContentContainer = styled(Container)`
width: 100%;
height: 100%;
justify-content: center;
display: flex;
flex-direction: column;

@media screen and (max-width: 960px) {
    justify-content: start;
    padding-top: 20px;
}

${Container}
`;

export const CommercialHeadText = styled.h4`
width: 953px;
font-style: normal;
font-weight: 900;
font-size: 74px;
line-height: 90px;
margin-bottom: 28px;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    width: 300px;
    color: #000;
    margin-bottom: 15px;
}
`;

export const CommercialHeadDesc = styled.p`
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #000000;

@media screen and (max-width: 960px) {
    font-size: 12px;
    line-height: 18px;
}
`;

export const CommercialAdvantageContainer = styled(Container)`
width: 100%;
margin-top: 150px;
align-items: center;
justify-content: center;
display: flex;
overflow: hidden;

@media screen and (max-width:  960px) {
    margin-top: 15px;
}

${Container}
`;

export const DivPass = styled.div`
width: 100%;
height: 150px;

@media screen and (max-width:  960px) {
    height: 25px;
}
`;

export const CommercialAdvantageText = styled.h4`
font-style: normal;
font-weight: 900;
font-size: 60px;
line-height: 73px;
color: #000000;

@media screen and (max-width: 960px) {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
}
`;

