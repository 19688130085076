import { deburr } from 'lodash';

export const formatText = text => deburr(text.toLowerCase().trim()).replace(/['\u2019]/g, '').replace(/\W+/g, '-');

export const handleBack = () => {
    window.history.back();
};

export const currencyFormatter = amount => new Intl.NumberFormat('fr-FR').format(amount);

export const currencyFormatterWithSymbol = amount => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' , currencyDisplay: 'symbol'}).format(amount);

export const formatNumber = number => number.replace(/\B(?=(\d{2})+(?!\d))/g, " ");