import styled from "styled-components";

export const AccordionContainer = styled.div`
overflow: hidden;
width: 54.091rem;
color: #000;
margin-bottom: 6.25rem;
`;

export const Inner = styled.div`
position: absolute;
padding: 0 4rem;
`;

export const Header = styled.button`
display: flex;
justify-content: space-between;
width: 100%;
margin-bottom: 3.125rem;
text-align: left;
appearance: none !important;
border: none !important;
outline: none !important;
padding: 0 1rem;
background-color: transparent;
cursor: pointer;
`;

export const HeaderIcon = styled.img`
transform: rotate(${props => props.isActive ? -180 : 0}deg);
transition: all .2s;
width: 1.25rem;
height: .808rem;
margin-bottom: .313rem;
`;

export const Content = styled.div`
position: relative;
overflow: hidden;
height: ${props => {
    const inner = document.getElementById(props.itemName);
    return `${props.isActive && inner ? (inner.clientHeight/16) : 0}rem`;
}};
transition: height .35s;
`;

export const Containers = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 3.125rem;
`;

export const ItemName = styled.h4`
font-style: normal;
font-weight: 600;
font-size: 1.5rem;
line-height: 1.813rem;
width: 44.313rem;
color: #000000;
`;


export const ContentText = styled.p`
font-style: normal;
font-weight: 400;
font-size: 1.25rem;
line-height: 140%;
margin-right: 2rem;
width: 46.625rem;
text-align: justify;
color: #000000;
`;



