import React from 'react';
import RegisterInput from '../RegisterInput';
import { ButtonRegister, FormRegisterContainer, LogoRegister, RegisterBottomSpacing, RegisterContainer, RegisterContentContainer, RegisterHeadContainer, RegisterHeadText, RegisterInfoText, RegisterSpinner, RegisterSpinnerContainer, RegisterText } from './CommercialRegister.element';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import RegisterInputAddMore from '../RegisterInputAddMore';

const schema = yup.object({
  name: yup.string().required('Veuillez saisir votre nom!'),
  surname: yup.string().required('Veuillez saisir votre prénom!'),
  post: yup.string().required('Veuillez saisir le poste occupé!'),
  url: yup.string().required("Entrez l'url du magazin!").url('Veuillez saisir un lien valide'),
  email: yup.string().required('Veuillez saisir un addresse email!').email('Veuillez saisir un email valide!'),
  phoneNumber: yup.string().min(8 , 'Vérifié votre numéro saisie!').required('Veuillez saisir vore numéro!').matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,"Veuillez saisir un numéro valide!"),
  category: yup.string().required('Veuillez saisir la catégories du produit!'),
})

const CommercialRegister = ({db , firebase}) => {

  const logo = require('../../images/logo.png');
  const btnText = React.useRef(null);
  const btnSpinner = React.useRef(null);
  const formRef = React.useRef(null);

  const [loading , setLoading] = React.useState(false);

  const { handleSubmit , register  , formState: {errors}} = useForm({
    resolver: yupResolver(schema),
  });

  const addregister = async data => {await db.collection('Togo').doc('CommercialRequest').collection('CommercialRequestList').add(data);}

  const formSubmit = data => {

       setLoading(true);

       const commercial = {
         name: data.name,
         surname: data.surname,
         post: data.post,
         url: data.url,
         email: data.email,
         phoneNumber: data.phoneNumber,
         category: data.category,
         dateCreated: firebase.firestore.Timestamp.fromDate(new Date()),
         addWords: data.add
       };
       

        addregister(commercial).then(() => {
          setLoading(false)
          formRef.current.reset();
          toast.success('Inscription enrégsitré avec succès!');
        }).catch((error) => {
          setLoading(false)
          formRef.current.reset();
          toast.error('Erreur lors de l\'enregistrement des données');
          console.log(error)
        });

  }

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if(loading){
        btnSpinner.current.style.display = 'flex';
        btnText.current.textContent = 'Enrégistrement...';
    }else{
        btnSpinner.current.style.display = 'none';
        btnText.current.textContent = 'Enrégistrer';
    }

  }, [loading]);

  return (
    <RegisterContainer>
       <Toaster toastOptions={{duration: 4000}}/>
       <RegisterContentContainer>
          <RegisterHeadContainer>
            <LogoRegister src={logo}/>
            <RegisterHeadText>Partenaires</RegisterHeadText>
          </RegisterHeadContainer>
          <RegisterText>Inscription des partenaires</RegisterText>
          <RegisterInfoText>Vos informations</RegisterInfoText>
          <FormRegisterContainer ref={formRef} onSubmit={handleSubmit(formSubmit)}>
            <RegisterInput id='name' label='Nom de famille*' type='text' register={{...register('name')}} errorMessage={errors.name?.message}/>
            <RegisterInput id='surname' label='Prénom*' type='text' register={{...register('surname')}} errorMessage={errors.surname?.message}/>
            <RegisterInput id='post' label='Poste occupé*' type='text' register={{...register('post')}} errorMessage={errors.post?.message}/>
            <RegisterInput id='url' label='URL du magasin' type='text' register={{...register('url')}} errorMessage={errors.url?.message}/>
            <RegisterInput id='email' label='E-mail' type='email' register={{...register('email')}} errorMessage={errors.email?.message}/>
            <RegisterInput id='phoneNumber' label='Numero de téléphone*' type='text' register={{...register('phoneNumber')}} errorMessage={errors.phoneNumber?.message}/>
            <RegisterInput id='category' label='Catégorie de produit*' type='text' register={{...register('category')}} errorMessage={errors.category?.message}/>
            <RegisterInputAddMore id='add' label='Ajouter un mot' register={{...register('add')}}/>
            <ButtonRegister>
                <RegisterSpinnerContainer ref={btnSpinner}>
                    <RegisterSpinner size={25} />
                </RegisterSpinnerContainer>
               <span ref={btnText}>Enrégistrer</span>
            </ButtonRegister>
          </FormRegisterContainer>
          <RegisterBottomSpacing/>
       </RegisterContentContainer>
    </RegisterContainer>
  );
  
}

export default CommercialRegister;
