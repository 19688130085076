import React from 'react';
import { ROLHeadText, ROLImg, ROLLeftContentContainer, ROLRightContentContainer , ROLDesc , ROLContainer} from './CommercialRighOrLeftSection.element';

const CommercialRightorLeftSection = ({position , text1 , text2 , image}) => {

  return (

    <>

        {
          (position === true) ?  (<ROLContainer>
      <ROLLeftContentContainer position>
       <ROLImg src={image} alt='logo' />
    </ROLLeftContentContainer> 
    
    <ROLRightContentContainer>
      <ROLHeadText>
        {text1}
      </ROLHeadText>

      <ROLDesc>
        {text2}
      </ROLDesc>
    </ROLRightContentContainer></ROLContainer>) : 

          (<ROLContainer>
    <ROLRightContentContainer>
      <ROLHeadText>
        {text1}
      </ROLHeadText>

      <ROLDesc>
        {text2}
      </ROLDesc>
    </ROLRightContentContainer>

    <ROLLeftContentContainer>
       <ROLImg src={image} alt='logo' />
    </ROLLeftContentContainer> 

    </ROLContainer>)
        }

    </>
    
  );
}

export default CommercialRightorLeftSection;
