import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircularIndeterminate = ({size}) => {
  return (
    <Box sx={{ display: 'flex'}}>
      <CircularProgress color="inherit" style={{ color: 'black' }} size={size} />
    </Box>
  );
}

export default CircularIndeterminate;
