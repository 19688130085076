import styled from "styled-components";


export const WishListGlobalContainer = styled.div`
height: auto;
width: 100%;
display: flex;
flex-direction: column;
`

export const WishListContainer = styled.div`
width: 100%;
height: 200px;
display: flex;
overflow: hidden;
flex-direction: row;
justify-content: center;
`;

export const WishListLeftContainer = styled.div`
width: 50%;
flex: 1;
height: 100%;
display: flex;
flex-direction: row;
align-items: center;
overflow: hidden;
`;

export const WishListRightContainer = styled.div`
width: 50%;
flex: 1;
height: 100%;
display: flex;
flex-direction: row;
align-items: center;
overflow: hidden;
`;

export const WishDeleteBtnProduct= styled.img`
width: 20px;
height: 20px;
cursor: pointer;
margin-right: 27.57px;
`;

export const WishProgressContainer = styled.div`
margin-right: 27.57px;
`;


export const WishProductImg = styled.img`
width: 150px;
height: 150px;
margin-right: 41px;
`;

export const WishProductNameContainer = styled.div`
 max-height: calc(2 * 1.2em);
 overflow: hidden;
 width: 250px;
`;

export const WishproductName = styled.p`
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
overflow: hidden;
text-overflow: ellipsis;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-transform: uppercase;
color: #000000;
`;

export const WishProductPrice = styled.p`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-transform: uppercase;
color: #000000;
margin-right: 156px;
`;

export const WishAddCartContainer = styled.div`
width: 230px;
height: 50px;
left: 1209px;
top: 437px;
cursor: pointer;
border: 1px solid #000000 !important;
display: flex;
justify-content: center;
align-items: center;
border-radius: 30px;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #000000;
`;

export const WishBottomDivider = styled.div`
width: 100%;
height: 0px;
border: 1px solid #F7F7F7 !important;
`;