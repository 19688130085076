import React from 'react';
import BrandRecommanded from '../BrandRecommanded';
import TabFeed from '../TabFeed';
import { navRef } from '../NavbarFeed';
import { Helmet } from 'react-helmet';
import { CTHeadContainer, CTHeadContentContainer , CTHeadText, CTHeadDesc, CTContainerWrapper, CTText, CTText2, CTContainer} from './Catalogue.element'; 
import { useDispatch } from 'react-redux';
import { changeNavBarItemClicked } from '../../store/navbar-state/navbarSlice';

const Catalogue = () => {

  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    navRef.current.style.background = '#57B793';
    dispatch(changeNavBarItemClicked('ct'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <>
        
        <Helmet>
            <meta charSet='utf-8'/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <title>Momo | Catalogue - Payez en 2 fois. Sans frais.</title>
            <meta name='keywords' content='Catalogue momo, Payez en 2 fois. Sans frais., Explorez les magasins et répartissez vos paiements dans vos magasins préférés., Magasins momo'/>
            <meta property="og:image" itemProp="image" content='../../images/logo.png'/>
            <meta property="og:site_name" content='Momo'/>
            <meta property="og:url" content='www.gotmomo.co/catalogue'/>
            <meta property="og:type" content='product'/>
            <meta property="og:title" content='Momo | Catalogue - Payez en 2 fois. Sans frais'/>
            <meta property="og:description" content='Explorez les magasins et répartissez vos paiements dans vos magasins préférés.'/>
            <meta name='description' content='Explorez les magasins et répartissez vos paiements dans vos magasins préférés.'/>
        </Helmet>

      <CTContainer>
        <CTHeadContainer>
        <CTHeadContentContainer>
           <CTHeadText>Payez en 2 fois. Sans frais.</CTHeadText>
           <CTHeadDesc>Explorez les magasins et répartissez vos paiements dans vos magasins préférés.</CTHeadDesc>
        </CTHeadContentContainer>
        </CTHeadContainer>

        <CTContainerWrapper>
         <CTText>Marques recommandées</CTText>
        </CTContainerWrapper>
      
        <BrandRecommanded/>

        <CTContainerWrapper>
        <CTText2>Parcourez par catégorie</CTText2>
        </CTContainerWrapper>
        
      </CTContainer>

      <TabFeed/>
    </>
  );
}
  

export default Catalogue;
