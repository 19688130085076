import styled from "styled-components";
import {CgSpinner} from 'react-icons/cg'

export const RegisterContainer = styled.div`
width: '100vw';
height: '100vh';
display: flex;
flex-direction: row;
overflow-x: hidden;
justify-content: center;
background-color: #D9D9D9;
z-index: 8001;
overflow-y: auto;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
`;

export const RegisterContentContainer = styled.div`
display: inline-block;
height: auto;
margin-top: 200px;
flex-direction: column;
margin-bottom: 55px;
`;

export const RegisterText = styled.h4`
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
margin-bottom: 50px;
color: #000000;
`

export const RegisterHeadContainer = styled.div`
display: flex;
flex-direction: row;
margin-bottom: 65px;
`

export const LogoRegister = styled.img`
width: 175px;
height: 26px;
margin-right: 46px;
`;


export const RegisterHeadText = styled.p`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
align-self: center;
color: #000000;
`

export const RegisterInfoText = styled.p`
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;

color: #000000;
`;

export const FormRegisterContainer = styled.form`
margin-top: 100px;
`

export const ButtonRegister = styled.button`
width: 400px;
height: 50px;
margin-top: 70px;
left: 664px;
top: 1761px;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 29px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
color: #FFFFFF;
background: #000000;
border: 1px solid #000000 !important;
border-radius: 10px;
box-sizing: border-box;
`

export const RegisterSpinner = styled(CgSpinner)`
animation: spin 1s infinite linear;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`

export const RegisterSpinnerContainer = styled.span`
margin-right: .5rem;
height: 100%;
display: none;
flex-direction: column;
justify-content: center;
`;

export const RegisterBottomSpacing = styled.div`
width: 100%;
height: 200px;
`
